import { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import get from 'lodash/get'

import { Avatar } from 'common/avatar'
import { Context } from 'Context'
import { ButtonLogout, LogoutButton } from '../styles'
import { navigate } from '@reach/router'

export const Logout = ({ isSidebar }) => {
  const { userData, dispatchUser, dispatchAuth, roleUser } = useContext(Context)
  const { t } = useTranslation()

  const handleLogout = () => {
    dispatchUser({ type: 'remove' })
    dispatchAuth({ type: 'logout' })
    navigate('/login')
  }
  return (
    <LogoutButton inSidebar={isSidebar}>
      <Avatar size='small' image={get(userData, `user.${roleUser()}.image`, null)} />
      <ButtonLogout onClick={handleLogout}>{t('Cerrar sesión')}</ButtonLogout>
    </LogoutButton>
  )
}
