import { forwardRef, useImperativeHandle, useState } from 'react'
import { ContainerSnack, TextSnack } from './styles'

export const Snackbar = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const [message, setMessage] = useState('')

  useImperativeHandle(ref, () => ({
    show: data => handleShow(data)
  }))

  const handleShow = data => {
    setIsVisible(true)
    setMessage(data.message)
    setTimeout(() => {
      setIsVisible(false)
    }, data.timeout)
  }
  return (
    <ContainerSnack ref={ref} className={isVisible ? 'active' : null}>
      <TextSnack>{message}</TextSnack>
    </ContainerSnack>
  )
})
