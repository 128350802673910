import React from 'react'

// 18 Error
export const ErrorIcon = ({ size = 141, ...props }) => (
  <svg viewBox='0 0 140.16 140.16' width={size} height={size} {...props}>
    <title>Icono de error</title>
    <path d='M140.16 70.07A70.08 70.08 0 1170.08 0a70.09 70.09 0 0170.08 70.07z' fill='#de0009' />
    <path fill='#fff' transform='rotate(-45.01 70.07 70.079)' d='M63.06 18.97H77.1V121.2H63.06z' />
    <path fill='#fff' transform='rotate(-44.99 70.088 70.086)' d='M18.97 63.06h102.22V77.1H18.97z' />
  </svg>
)
