export const SearchIcon = ({ size = 32, color = '#fff', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 33.656 33.731'
    {...props}
  >
    <path
      d='M32.659 27.883l-3.807-3.808-2.7-2.7-.86-.859-.013-.015-.014-.013-4-4a11.215 11.215 0 001.281-5.216A11.289 11.289 0 0011.271-.004 11.29 11.29 0 00-.007 11.272a11.236 11.236 0 003.034 7.688.828.828 0 00.133.141 11.248 11.248 0 008.111 3.451 11.2 11.2 0 005.213-1.281l3.994 4 .014.016.015.013 6.492 6.5 1.845 1.591a3.018 3.018 0 001.39.345 3.352 3.352 0 002.34-1.011 3.358 3.358 0 00.085-4.842zM3.516 16.896a9.734 9.734 0 01-.374-.56l-.008-.013-.032-.049a5.007 5.007 0 01-.574-1.089v-.008a9.508 9.508 0 01-.836-3.9 9.594 9.594 0 019.583-9.582 9.593 9.593 0 019.581 9.582 9.526 9.526 0 01-1.071 4.393l-.01.025a7.678 7.678 0 01-2.6 3.126h-.005a9.514 9.514 0 01-.857.608.837.837 0 00-.234.141 9.517 9.517 0 01-4.8 1.294 9.532 9.532 0 01-2.891-.449 4.232 4.232 0 01-1.4-.569h-.006a9.615 9.615 0 01-2.265-1.585m13.249 2.1a11.456 11.456 0 001.2-1.028.857.857 0 00.154-.154 11.192 11.192 0 001.027-1.2l3.135 3.135-2.386 2.387zM31.384 31.53a1.518 1.518 0 01-2.31-.056l-6.776-6.78 2.385-2.387 2.707 2.708.033.033 4.038 4.038a1.69 1.69 0 01-.078 2.435z'
      fill={color}
    />
  </svg>
)
