import { FlexCenter, resetButton } from 'assets/styles/general'
import styled, { css } from 'styled-components'
import { Colors, Device } from 'styles/variables'

export const MainContainer = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: ${({ isClient }) => isClient ? 'auto auto' : '70px auto'};
  grid-template-areas: "header"
                       "main";
  padding-bottom: 1rem;
  
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(114,175,255,1) 100%);
    z-index: 0;
  }

  @media (min-width: ${Device.tableL + 1}px) {
    grid-template-columns: 150px 1fr;
    grid-template-rows: 70px auto;
    grid-template-areas: "header header"
                         "sidebar main";
  }
`

export const Header = styled.header`
  grid-area: header;
  height: ${({ isClient }) => isClient ? 'auto' : '70px'};
  z-index: 1;
  
  & nav {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 70px;
    position: relative;
    padding: 0 24px;

    @media (min-width: ${Device.tableL}px) {
      grid-template-columns: 20% 60% 20%;
      padding: 0 1rem 0 2rem;
    }
  }
`

// Menu
export const LogoContainer = styled.div`
  display: flex;
  justify-content: ${({ align }) => align || 'center'};
  align-items: center;
  height: ${({ height }) => height || '100%'};
  width: 100%;
`

export const Logo = styled.span`
  display: block;
  width: ${({ width }) => width || '60px'};
  height: ${({ height }) => height || '60px'};
  border-radius: 50%;
  background-color: #000;
`

export const Menu = styled.div`
  position: absolute;
  top: 1rem;
  left: 24px;
`

// Search Bar
export const SearchBarContainer = styled.div`
  border: unset;
  width: 100%;
  z-index: 1;

  .field-form  {
    width: 100%;
    margin-bottom: 0;
  
    input {
      border: none;
      border-radius: 16px;
      box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
  }
`

export const SearchbarInNav = styled.div`
  display: flex;
  align-items: center;
`

// Sidebar
export const Sidebar = styled.section`
  grid-area: sidebar;
  height: 100%;
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 0 1px rgba(0,0,0,.5);
  z-index: 10000;
  transform: translateX(-300px);

  transition: ease all .3s;
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;

  &[state='open'] {
    transform: translateX(0);
  }

  @media (min-width: ${Device.tableL + 1}px) {
    position: relative;
    width: 100%;
    background-color: transparent;
    box-shadow: unset;
    transform: inherit;
  }
`

export const Items = styled.ul`
  display: flex;
  ${({ isMobile }) => !isMobile
    ? css`
      flex-flow: column wrap;
    `
    : css`
      flex-flow: row;
      justify-content: center;
      margin-bottom: 1rem;

      li a {
        flex-direction: column;
        align-items: center;
        width: 100px;

        span {
          margin-right: 0;
          margin-bottom: .5rem;
        }
      }
    `}
`

export const Item = styled.li`
  a {
    display: flex;
    align-items: center;
    padding: .7rem 0;

    span {
      margin-right: .5rem;
      width: 48px;
      height: 48px;
      ${FlexCenter};
      border-radius: 50%;
      background-color: white;
      border-radius: 50%;
      margin-bottom: .5rem;
      box-shadow: 0 0 3px rgba(0,0,0,.3);
    }

    &.active {
      span {
        background-color: ${Colors.purple};

        svg {
          g {
            fill: white;
          }
          path {
            fill: white;
          }
        }
      }
    }

    @media (min-width: ${Device.tableM}px) {
      flex-flow: column wrap;
      justify-content: center;
      text-align: center;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        background-color: white;
        border-radius: 50%;
        margin-bottom: .5rem;
        box-shadow: 0 0 3px rgba(0,0,0,.3);
      }

      svg {
        margin-right: unset;
      }
    }
  }
`

export const LogoutButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ inSidebar }) => inSidebar
    ? css`
      position: absolute;
      bottom: 30px;
      left: 1rem;
    `
    : css`
      justify-self: flex-end;
    `
  }
`

export const PhotoUser = styled.span`
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  box-shadow: 0 0 3px rgba(0,0,0,.5);
  margin-right: 10px;
`

// Main
export const Container = styled.main`
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr .35fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  z-index: 1;

  @media (max-width: ${Device.tableS}px) {
    padding: 0 12px !important;
    grid-row-gap: 16px;
  }

  @media (min-width: ${Device.tableS + 1}px) and (max-width: ${Device.tableM}px) {
    padding: 0 32px !important;
    grid-row-gap: 16px;
  }

  @media (min-width: ${Device.tableM + 1}px) and (max-width: ${Device.tableL}px) {
    padding: 0 32px !important;
    grid-row-gap: 16px;
  }

  @media (max-width: ${Device.laptop}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
    padding-right: 32px;
  }
`

// Aside
export const Aside = styled.aside`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-right: 32px;
  > {
    &:first-child {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${Device.tableS}px) {
    padding: 0;
  }
  
  @media (max-width: ${Device.laptop}px) {
    width: 100%;
  }
`

export const ButtonLogout = styled.button`
  ${resetButton};
`
