import { SearchIcon } from 'assets/icons/searchIcon'
import { DotsSpinner } from 'common/loadings/dots'
import { ButtonSearch, ContainerSearchBar, InputSearch } from './styles/searchbar'

export const SearchBar = ({ search, handleGet = () => { }, loading = false }) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleGet()
    }
  }

  return (
    <ContainerSearchBar>
      <InputSearch
        onKeyPress={handleKeyPress}
        {...search}
      />
      <ButtonSearch onClick={handleGet}>
        {
          loading ? <DotsSpinner size='20px' /> : <SearchIcon />
        }
      </ButtonSearch>
    </ContainerSearchBar>
  )
}
