import { useTranslation } from 'react-i18next'
import { Alert as Container } from 'common/modal/alert'
import { TextAlert } from '../styles'

export const Alert = ({ open = false, onAccept = () => {}, onCancel = () => {}, disabled = false }) => {
  const { t } = useTranslation()

  return (
    <Container
      open={open}
      okButton={onAccept}
      cancelButton={onCancel}
      disabled={disabled}
    >
      <TextAlert>{t('¿Deseas enviar el correo?')}</TextAlert>
    </Container>
  )
}
