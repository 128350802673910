import React from 'react'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { ErrorMessage, FieldPhone, Label } from './styles'
import { ErrorIcon } from 'assets/icons/errorIcon'

export const InputPhone = ({ label = '', value = '', placeholder = '', onChange = () => {}, error = '', onChangeDialCode = () => {}, isRequired = false }) => {
  const handleOnChange = (value, country, e, formattedValue) => {
    onChange({ target: { value: formattedValue } })
    onChangeDialCode(country.dialCode)
  }
  return (
    <FieldPhone className='field-form'>
      {label && (
        <Label required={isRequired}>
          {isRequired && <span className='input-required' />}
          {label}
        </Label>
      )}
      <PhoneInput
        country='us'
        onlyCountries={['us']}
        value={value}
        onChange={handleOnChange}
        placeholder={placeholder}
      />
      {error && <ErrorIcon size={20} className='error-icon input-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </FieldPhone>
  )
}
