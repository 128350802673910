import React from 'react'

import { Field, Label, InputComponent, ErrorMessage, InfoMessage } from 'formStyle'
import { ErrorIcon } from 'icons/errorIcon'

export const Input = ({ label = '', type = 'text', name = 'common', value = '', placeholder = '', onChange = () => {}, maxLength = 120, error = '', info = '', width = '100%', max = null, min = 0, isRequired, readOnly = false, autoComplete = 'on', tabindex = undefined }) => {
  const handleChange = (event) => {
    if (type === 'number') {
      let value = event.target.value
      if (max && !isNaN(value) && parseInt(value) > max) {
        value = max
      }
      if (min && !isNaN(value) && parseInt(value) < min) {
        value = min
      }
      onChange({ target: { value } })
      return
    }

    onChange(event)
  }

  return (
    <Field className='field-form' width={width}>
      {label && (
        // required={isRequired}
        <Label>
          {/* {isRequired && <span className='input-required' />} */}
          {label}
        </Label>
      )}
      <InputComponent
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
        max={max}
        min={min}
        readOnly={readOnly}
        autoComplete={autoComplete}
        tabIndex={tabindex}
      />
      {info && <InfoMessage>{info}</InfoMessage>}
      {error && <ErrorIcon size={20} className='error-icon input-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  )
}
