import { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Modal } from 'common/modal'
import { ButtonContainer, CancelButton } from 'common/modal/styles'
import { Snackbar } from 'common/snackbar'
import { HeaderRequests, ListRequests } from '../styles/requests'
import { RequestItem } from './requestItem'
import { useRequest } from 'hooks/useRequest'
import { SearchBar } from 'common/forms/searchBar'
import { useInputValue } from 'hooks/useInputValue'
import { Context } from 'Context'

const ModalSearchAgencies = forwardRef(({ ...props }, ref) => {
  const { userData } = useContext(Context)

  const { t } = useTranslation()
  // References
  const snackbar = useRef()
  // States
  const [isOpen, setIsOpen] = useState(false)
  const [agencies, setAgencies] = useState([])

  const search = useInputValue('buscar', '')

  const [getAgencies, { loading: loadingAgencies }] = useRequest(`linkeds/agent/${userData?.user?.agent.id}`, 'GET')
  const [sendRequest, { loading: loadingRequest }] = useRequest('linkeds/send-request', 'POST')

  useImperativeHandle(ref, () => ({
    handleOpen: () => handleOnOpen()
  }))

  const handleOnOpen = () => {
    setIsOpen(true)
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const handleGetAgents = () => {
    if (!search.value) return
    getAgencies({ body: { search: search.value } }).then(res => {
      setAgencies(res)
    }).catch(err => {
      console.log('>>> err', err)
    })
  }

  const handleLink = (id) => {
    sendRequest({
      body: {
        agency_id: id,
        agent_id: userData?.user?.agent.id,
        from: 'agent'
      }
    })
      .then(res => {
        snackbar.current.show({
          message: t('Solicitud Enviada exitosamente'),
          timeout: 5000
        })
        setAgencies([])
        handleOnClose()
      }).catch(err => {
        snackbar.current.show({
          message: t('Disculpa ocurrió un error, intentalo mas tarde'),
          timeout: 5000
        })
        console.error('>>> err', err)
      })
  }

  return (
    <>
      <Modal
        onClose={handleOnClose}
        open={isOpen}
        ref={ref}
        padding='2rem'
        width='50rem'
        height='max-content'
      >
        <HeaderRequests>
          <h2>{t('Buscar agencias')}</h2>
        </HeaderRequests>
        <SearchBar
          search={search}
          loading={loadingAgencies || loadingRequest}
          handleGet={handleGetAgents}
        />
        <br />
        <ListRequests>
          {
          agencies.map((agency) => (
            <RequestItem key={agency?.id} agency={agency} handleAction={handleLink} isLink />
          ))
        }
        </ListRequests>
        <ButtonContainer>
          <CancelButton
            onClick={handleOnClose}
            disabled={loadingAgencies || loadingRequest}
          >
            {t('Cerrar')}
          </CancelButton>
        </ButtonContainer>
      </Modal>
      <Snackbar ref={snackbar} />
    </>
  )
})

export default ModalSearchAgencies
