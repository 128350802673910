import { useState, useImperativeHandle, forwardRef } from 'react'
import { NoImage } from 'icons/noImage'
import { DeleteIcon } from 'icons/deleteIcon'
import { DropZoneContainer, DropZoneIcon, FilesPreview, FileItem } from './styles'

const ICON_PROPS = {
  size: 15,
  color: 'white'
}

export const DropZone = forwardRef(({ files: externalFiles = [], fileNameKey = 'name', multiple = true, onChange = () => {}, onRemove = () => {}, className = '' }, ref) => {
  const [files, setFiles] = useState([])
  const [preview, setPreview] = useState([])

  useImperativeHandle(
    ref,
    () => ({
      reset: () => {
        setFiles([])
        setPreview([])
      }
    }),
    [files, preview]
  )

  const printFileByType = (file) => {
    const filename = fileNameKey ? file[fileNameKey] : file
    const extension = filename.split('.').pop()

    switch (extension) {
      case 'jpg':
        return <img src={filename} />
      case 'jpeg':
        return <img src={filename} />
      case 'png':
        return <img src={filename} />
      case 'webp':
        return <img src={filename} />
      default:
        return <NoImage size={60} />
    }
  }

  const previewFileByType = async (file) => {
    switch (file.type) {
      case 'image/jpg': {
        const data = await previewFile(file)
        return <img src={data} />
      }
      case 'image/jpeg': {
        const data = await previewFile(file)
        return <img src={data} />
      }
      case 'image/png': {
        const data = await previewFile(file)
        return <img src={data} />
      }
      case 'image/webp': {
        const data = await previewFile(file)
        return <img src={data} />
      }
      default:
        return <NoImage size={60} />
    }
  }

  const previewFile = (file) => {
    return new window.Promise((solved, rejected) => {
      const reader = new window.FileReader()
      reader.onloadend = () => {
        solved(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const renderExternalFiles = () => {
    return externalFiles.map((file, index) => (
      <FileItem key={index}>
        <span onClick={() => onRemove(file, index)}><DeleteIcon {...ICON_PROPS} /></span>
        {printFileByType(file)}
      </FileItem>
    ))
  }

  const renderFiles = () => {
    return preview.map((file, index) => {
      return (
        <FileItem key={index}>
          <span onClick={() => handleRemoveFile(index)}>
            <DeleteIcon {...ICON_PROPS} />
          </span>
          {file}
        </FileItem>
      )
    })
  }

  const handleChange = async (e) => {
    const filesInput = e.target.files

    if (multiple) {
      const newFiles = [...files]
      const newPreview = [...preview]

      for (let index = 0; index < filesInput.length; index++) {
        newFiles.push(filesInput[index])
        const previewData = await previewFileByType(filesInput[index])
        newPreview.push(previewData)
      }

      setPreview(newPreview)
      setFiles(newFiles)

      onChange(newFiles)
    } else {
      onChange(filesInput[0])
    }
  }

  const handleRemoveFile = (index) => {
    const newFiles = [...files]
    const newPreview = [...preview]

    newFiles.splice(index, 1)
    newPreview.splice(index, 1)

    setPreview(newPreview)
    setFiles(newFiles)

    onChange(newFiles)
  }

  return (
    <DropZoneContainer className={className}>
      <input type='file' multiple={multiple} onChange={handleChange} />
      <DropZoneIcon>
        <NoImage size={90} />
      </DropZoneIcon>
      <FilesPreview>
        {renderExternalFiles()}
        {renderFiles()}
      </FilesPreview>
    </DropZoneContainer>
  )
})
