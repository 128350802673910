import { useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {String} name
 * @param {String|Number} initValue
 * @param {String} validations
 * @returns {{ value: String, onChange: Function, onReset: Function, validate: Function, error: String, isRequired: Boolean}}
 */
export const useInputValue = (name = '', initValue = '', validations = '') => {
  const { t } = useTranslation()
  const [value, setValue] = useState(initValue)
  const [error, setError] = useState('')
  const [isRequired] = useState(validations.includes('required'))

  const onChange = (e) => {
    const v = (typeof e?.target !== 'undefined') ? e.target.value : e
    setValue(v)
    setError('')
  }

  const onReset = () => {
    setValue('')
    setError('')
  }

  const validate = () => {
    const _validations = validations.split('|')
    let count = 0
    for (let index = 0; index < _validations.length; index++) {
      const validation = _validations[index]
      let string
      let characters = 0
      try {
        const split = validation.split(':')
        string = split[0]
        characters = parseInt(split[1])
      } catch (error) {
        string = validation
      }
      switch (string) {
        case 'required': {
          if (value == null || value.length === 0) {
            count++
            setError(`${t('The')} ${name} ${t('field is required')}`)
            return true
          } else {
            count--
            setError('')
          }
          break
        }
        case 'email': {
          if (!value.match(/^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)) {
            count++
            setError(t('Please enter a valid email address'))
            return true
          } else {
            count--
            setError('')
          }
          break
        }
        case 'min': {
          if (value.length < characters) {
            count++
            setError(`${t('The')} ${name} ${t('field must be at least')} ${characters} ${t('characters long')}`)
            return true
          } else {
            count--
            setError('')
          }
          break
        }
        case 'max': {
          if (value.length > characters) {
            count++
            setError(`${t('The')} ${name} ${t('field must have a maximum of')} ${characters} ${t('characters')}`)
            return true
          } else {
            count--
            setError('')
          }
          break
        }
        default:
          console.warn(string)
          throw new Error('undefined validation type')
      }
    }
    return count > 0
  }

  return { value, onChange, onReset, validate, error, isRequired }
}
