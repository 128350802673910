import React, { useState } from 'react'

import DatePicker from 'react-datepicker'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'

import { ErrorMessage, Field, InputComponent, Label } from './styles'
import { ErrorIcon } from 'icons/errorIcon'

export const InputDatePicker = ({ label = '', error = false, onChange = () => {}, placeholder = '', width = '100%', value, minDate = null, maxDate = null, isRequired, tabindex = undefined }) => {
  const [startDate, setStartDate] = useState(new Date())
  const range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))
  const currentYear = (new Date()).getFullYear()
  const years = range(currentYear, currentYear - 80, -1)
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return (
    <Field width={width} className='field-form'>
      {label && (
        <Label required={isRequired}>
          {isRequired && <span className='input-required' />}
          {label}
        </Label>
      )}
      <>
        <DatePicker
          customInput={<InputComponent tabIndex={tabindex} readOnly />}
          placeholderText={placeholder}
          minDate={minDate}
          maxDate={maxDate}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {'<'}
              </button>
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))}
              >
                {months.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {'>'}
              </button>
            </div>
          )}
          selected={value}
          onChange={date => onChange(date)}
        />
      </>
      {error && <ErrorIcon size={20} className='error-icon input-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  )
}
