import { useState, useContext, useEffect } from 'react'
import { useRequest } from './useRequest'
import { Context } from 'Context'

export const useGetAddressedClients = () => {
  const { userData } = useContext(Context)
  const [addressee, setAddressee] = useState([])

  const [getList, { loading }] = useRequest('clients/addressee', 'POST')

  useEffect(() => {
    getList({
      body: {
        agentId: userData?.user?.agent?.id
      }
    })
      .then(res => {
        const clients = res?.clients || []
        if (clients) {
          const emails = clients.map(client => ({
            value: client?.user?.id,
            name: `${client?.person?.firstname} ${client?.person?.lastname}`
          }))
          setAddressee(emails)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  return { addressee, loading }
}
