import styled from 'styled-components'

import { FlexCenter, resetButton } from 'assets/styles/general'
import { Colors } from 'assets/styles/variables'

export const ContainerSearchBar = styled.div`
  width: 100%;
  position: relative;
`

export const InputSearch = styled.input`
  width: 100%;
  height: 3.75rem;
  border-radius: 2rem;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  position: relative;
  border: 1px solid #E1E1E1;
  padding: .75rem 4rem .75rem 2rem;
  outline: none;
  font-size: 18px;
`

export const ButtonSearch = styled.button`
  ${resetButton};
  ${FlexCenter};
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 3.75rem;
  background-color: ${Colors.purple};
  position: absolute;
  right: 0;
  top: 0;
`
