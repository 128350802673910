export const AgentsImage = ({ ...props }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 356 321' {...props}>
    <defs>
      <linearGradient
        id='prefix__b'
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits='objectBoundingBox'
      >
        <stop offset={0} stopColor='#edb38e' />
        <stop offset={1} stopColor='#e8966c' />
      </linearGradient>
      <clipPath id='prefix__a'>
        <path
          data-name='Rect\xE1ngulo 32'
          transform='translate(0 -.226)'
          fill='none'
          d='M0 0h356v321H0z'
        />
      </clipPath>
    </defs>
    <g data-name='Grupo 23'>
      <g
        data-name='Grupo 22'
        transform='translate(0 .226)'
        clipPath='url(#prefix__a)'
      >
        <g data-name='Grupo 21'>
          <g data-name='Grupo 19'>
            <path
              data-name='Trazado 51'
              d='M43.634 315.901a9.645 9.645 0 00-3.364 3.793 10.039 10.039 0 00-1.3 3.891h90.147z'
              fill='#050e1e'
            />
            <path
              data-name='Trazado 52'
              d='M-2480.363 1660.616s.649 20.1.649 22.05 11.673 20.754 22.05 20.105 27.887-23.348 27.887-23.348-1.3-16.862-1.3-21.4v-8.431s-21.723 20.428-49.286 11.024z'
              transform='translate(2569.274 -1562.076)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 53'
              d='M-2490.264 1535.607s24-2.776 27.563-20.844 2.271-15.474 2.271-15.474 1.945 3.243 3.891 1.946 2.594-4.54 2.594-6.485 1.3-5.837 1.945-9.08a14.646 14.646 0 000-5.837s-5.837-1.945-7.782-5.188-2.595-24-9.729-25.293-32.427 2.594-36.967 1.946-11.674-.649-12.97 3.243-1.946 14.268-2.595 20.1a76.79 76.79 0 000 12.971s-2.594 0-3.243.648 2.594 7.783 3.243 10.377 0 7.134 2.595 7.782 1.945-1.3 1.945-1.3 1.946 32.433 27.239 30.488z'
              transform='translate(2600.901 -1421.177)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 54'
              d='M108.043 8.716s-1.3-1.3-5.188 0-7.134.649-9.079 1.3-6.486 5.188-9.729 7.134-5.837 5.837-7.134 9.08-2.594 9.728-3.891 12.971-1.946 4.54-1.3 7.783 1.3 4.54 1.3 7.782a20.013 20.013 0 00.648 6.486c.649 2.594 1.86 6.81 1.86 6.81a4.381 4.381 0 012.68.324c1.3.649 1.946 4.54 2.594 5.188s0-4.54 0-6.485V55.416c0-3.891.648-4.54.648-5.837a59.9 59.9 0 01.648-9.728c.649-3.243 1.3-6.486 6.486-6.486s5.836 1.946 11.673 1.3 5.188 0 8.431-1.3 2.594-1.3 7.134-1.3a26.859 26.859 0 009.08-1.3c1.946-.648 3.891 0 5.837 1.3s3.243 4.54 4.54 7.134 3.243 12.323 4.54 15.565 1.946 15.565 1.3 16.862-.648 1.946 0 1.946 1.946-1.946 1.946-4.54 0-5.837 1.945-7.134 2.594-1.3 3.243 0 .648 1.3 1.3.649a7.112 7.112 0 001.3-5.837 32.863 32.863 0 01-1.3-8.431 63.905 63.905 0 00-1.3-11.025 60.074 60.074 0 00-5.188-12.971 8.719 8.719 0 00-4.54-3.891c-1.946-.649-1.946-2.594-4.54-6.486s-7.134-5.837-9.728-6.485-5.188-1.3-7.782-.649-7.137 2.592-8.434 1.944z'
              fill='#603813'
            />
            <path
              data-name='Trazado 55'
              d='M88.911 120.591s-20.754 15.565-28.536 20.1-13.62 8.431-15.565 8.431.648 20.753 3.892 34.373 6.485 22.051 7.134 27.888 3.243 22.7 2.594 29.185-11.674 57.721-14.268 63.557a18.5 18.5 0 00-1.3 11.025s9.08 11.025 44.75 8.431c22.7 0 41.663-.324 41.663-.324s1.79-155.975 12.815-161.164 35.67-7.743 37.616-11.006-1.946-10.4-1.946-10.4-21.4-16.862-24.645-18.159a60.706 60.706 0 00-7.458-2.27l-26.536 17.348a13.38 13.38 0 01-9 3.046 11.634 11.634 0 01-7.428-3.331l-12.81-17.054s-.647-.649-.972.324z'
              fill='#f2f2f2'
            />
            <path
              data-name='Trazado 56'
              d='M138.737 106.672s3.679 1.92 3.679 2.568a17.231 17.231 0 01-2.918 8.107c-.325-3.567-.761-10.675-.761-10.675z'
              fill='none'
              stroke='#603813'
              strokeMiterlimit={10}
            />
            <path
              data-name='Trazado 57'
              d='M98.691 323.637s22.372-6.438 22.347-13.46-2.655-123.139-1.988-124.746-5.17-13.93-5.819-18.47-1.3-4.54-1.3-4.54l-8.431-3.242-4.812 4.19s-1.024 7.484-2.322 10.727a100.053 100.053 0 00-7.782 29.833c-1.3 14.917-10.377 105.064-10.377 107.01s8.538 11.777 20.484 12.698z'
              fill='#3e5594'
            />
            <path
              data-name='Trazado 58'
              d='M109.661 140.695l11.025 12.322s-4.54 5.188-6.485 7.134-1.946 2.594-1.946 2.594-9.728-2.594-12.971 1.3c-3.243-2.594-.6-14.285-.6-14.285s9.683-10.362 10.977-9.065z'
              fill='#2e467a'
            />
            <path
              data-name='Trazado 59'
              d='M109.276 140.436s-10.96 11.609-14.852 10.96-20.985-19.495-20.985-19.495 15.763-12.37 16.1-12.327 12.015 14.96 13.96 16.257 5.711 4.67 5.777 4.605z'
              fill='#e6e6e6'
            />
            <path
              data-name='Trazado 60'
              d='M121.662 154.639a6.805 6.805 0 006.485-1.3c3.243-2.594 18.159-17.511 20.753-21.4a84.591 84.591 0 005.016-8.992s-10.853-13.707-11.5-13.707-1.3 7.134-4.54 9.728-28.536 22.051-28.536 22.051 10.377 13.62 12.322 13.62z'
              fill='#e6e6e6'
            />
            <path
              data-name='Trazado 61'
              d='M-2577.6 2160.9s-13.679-3.458-18.867-7.349-10.939-7.834-10.939-7.834-2.68 9.132-3.977 13.023-.649 9.728 7.782 14.917a62.786 62.786 0 0017.511 7.134s2.71-15.791 8.49-19.891z'
              transform='translate(2661.709 -1910.709)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 62'
              d='M45.492 149.017s-9.437 1.731-17.868 16.647-6.486 26.59-9.08 33.076S6.87 251.921 6.87 261.649s2.594 35.021 13.619 36.967 31.779 3.243 39.562 5.837 29.185 8.431 35.67 10.377 9.728 1.945 14.268 1.3 12.971.648 12.971.648v-31.129s-64.265-23.7-68.127-26.12-3.213-10.2-1.268-19.927 2.595-29.833 1.946-36.319-10.019-54.266-10.019-54.266z'
              fill='#e6e6e6'
            />
            <path
              data-name='Trazado 63'
              d='M78.21 256.461s7.782-20.1 12.322-18.159 16.214 6.486 18.808 9.08 1.946 2.594 1.946 2.594l12.323 2.594v40.859s-5.837-1.946-12.323-3.891-14.268-7.783-18.159-9.728-18.808-6.486-19.456-8.431 4.539-14.918 4.539-14.918z'
              fill='#e6e6e6'
            />
          </g>
          <g data-name='Grupo 20'>
            <path
              data-name='Trazado 64'
              d='M174.964 151.114s-29.953-1.015-44.87 10.659c10.377 12.971 24.645 43.453 26.59 52.532s11.025 53.181 11.674 66.152 1.3 42.8 1.3 42.8h135.546s1.3-46.7-3.891-56.424-6.486-30.481-5.837-40.21 9.728-63.558 9.728-63.558l-34.373-11.674-61.612 5.837z'
              fill='#d0e0f4'
            />
            <path
              data-name='Trazado 65'
              d='M267.586 100.161s-2.594 12.971 0 24 5.188 20.1 6.486 23.347-5.188-3.891-5.837-6.485 3.243 9.728 5.837 11.674.649 10.377-3.891 4.54-10.377-9.08-12.323-12.323-4.54-.649-5.837-1.3a13.635 13.635 0 00-3.579-.914s-5.874-9.178-6.984-17.752 4.078-33.867 4.078-33.867 6.278.649 7.354-7.458 14.696 16.538 14.696 16.538z'
              fill='#141613'
            />
            <path
              data-name='Trazado 66'
              d='M188.839 21.894s-12.051 3.035-15.294 27.031-.648 31.779-1.946 46.047-1.3 24 0 32.427 2.594 12.322.649 14.268a38.416 38.416 0 01-10.377 7.783c-5.188 2.594 16.214 3.243 18.159 0s10.753-11.8 10.753-11.8l1.141-23.427-.22-82.805 4.54-6.485z'
              fill='#141613'
            />
            <path
              data-name='Trazado 67'
              d='M-2083.735 1674.521c.113.7-4.427 24.043-3.13 31.826s7.783 17.51 7.783 17.51 3.243 3.891-1.946 13.62-16.213 42.8-18.16 42.8-25.293-27.887-28.536-33.076-9.729-16.214-9.729-16.214-.648-14.917 0-17.511.869-19.665.869-19.665 29.613 3.451 36.1-1.737 16.749-17.553 16.749-17.553z'
              transform='translate(2328.509 -1579.595)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 68'
              d='M-2161.8 1439.442s-11.025 1.3-13.619 24.645.648 20.753-1.3 22.7-2.594 1.946-2.594 4.54.649 6.486.649 9.728 3.891 5.837 3.891 5.837.649 26.59 24.645 27.887 36.967-15.565 37.616-29.185c5.188-.648 7.782-3.243 7.782-9.728s-14.592-26.266-18.484-33.4-8.429-25.943-38.586-23.024z'
              transform='translate(2357.721 -1414.188)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 69'
              d='M197.543 16.499s-9.08 3.891-9.728 7.134 16.862 3.891 22.7 9.728 24.645 26.59 31.13 39.561 19.457 22.051 22.051 27.239 6.485 5.188 6.485 7.782-2.594-43.453-11.674-64.206-25.943-38.263-60.964-27.238z'
              fill='#141613'
            />
            <path
              data-name='Trazado 70'
              d='M131.769 160.558s-17.241 7.052-18.538 62.827 9.079 99.876 9.079 99.876h68.458s8.719-18.159 8.719-20.754-36.318-16.862-34.373-24.645a38.547 38.547 0 001.217-13.192s-5.757-41.934-7.053-51.014-19.348-49.124-27.509-53.098z'
              fill='#dfedf8'
            />
            <path
              data-name='Trazado 71'
              d='M-2146.325 2395.433l94.934.272-6.485-6.485-44.836-14.2-32.341-.072z'
              transform='translate(2334.542 -2071.796)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 72'
              d='M303.819 162.601s13.056 3.712 20.19 26.411 24 71.34 27.239 84.96 3.243 32.427 0 40.21a15.111 15.111 0 01-10.377 9.08l-57.941.376-6.265-6.213s20.1-3.891 20.754-8.431-2.594-11.674-5.189-15.565-15.565-13.619-15.565-13.619l28.536-1.946s-8.068-26.9-8.574-40.686 1.868-32.73 1.868-32.73z'
              fill='#dfedf8'
            />
            <path
              data-name='Trazado 73'
              d='M-2117.081 2318.505s-47.992-14.917-50.587-16.214-14.268 4.54-26.59 1.3-36.319-16.214-36.319-21.4a11.145 11.145 0 012.857-7.866s9.465 3.326 14 1.38 18.159.649 21.4 1.3 23.348 5.837 35.67 4.54 39.561-.648 39.561-.648 20.753 9.079 22.051 28.536c.007 7.127-22.043 9.072-22.043 9.072z'
              transform='translate(2393.746 -2001.081)'
              fill='url(#prefix__b)'
            />
            <path
              data-name='Trazado 74'
              d='M273.977 158.955c-1.714.749-1.528.548-4.346-2.406s-4.965-4.828-5.355-5.385-4.15-3.334-5.863-5.452c-1.713-2.766-2.178-2.422-4.1-2.351a2.421 2.421 0 01-2.128.315s-1.139-.386-1.759-.609a12.043 12.043 0 00-1.986-.366l-1.285 5.777v5.188c0 3.243-1.946 1.946-4.54 6.486s-14.268 35.67-14.268 35.67a.617.617 0 00.137-.078c-.361 1.632-1 5.267.511 5.267 1.946 0 16.879-28.365 16.879-28.365 4.361-3.909 7.583-7.191 8.739-8.926 1.3-1.946 1.946-.649 4.54-.649s2.594-2.594 3.243-3.891 1.3-2.594 5.188 0c6.486 3.241 7.135.647 6.393-.225z'
              fill='#dfedf8'
            />
            <path
              data-name='Trazado 75'
              d='M228.997 197.119c-.1-.2-.475-.774-1.031-1.591l.707.618s-16.862-24.645-19.457-27.887-14.268-11.025-15.564-16.862-2.417-18.652-2.417-18.652-2.123 3.087-5.365 6.979-5.188 9.079-8.431 10.377-12.322 5.188-14.268 5.837-1.946 9.08.649 8.431 10.377-2.594 15.565-6.486a54.81 54.81 0 003.1-2.519c.26-.226.509-.445.741-.65 1.183-.915 3.623-2.3 6.178-.817.545.441 1.137.943 1.729 1.472.985 1.139 2.422 3 4.036 5.18 1.282 3.061 7.819 13.744 10.154 15.5.36.27 1.08.861 2.036 1.664l21.636 23.305s.65-2.605.002-3.899z'
              fill='#dfedf8'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
