import { AgentsImage } from 'assets/images/agents'
import { ContainerBanner, Title, SubTitle, ContainerText } from './styles'

export const Banner = ({ title = '', subtitle = '', isDisabled = false }) => (
  <ContainerBanner>
    {!isDisabled && (
      <AgentsImage className='agents-image' />
    )}
    <ContainerText isDisabled={isDisabled}>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </ContainerText>
  </ContainerBanner>
)
