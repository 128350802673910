import { useRef, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'common/forms/input'
import { SelectSearch } from 'common/forms/selectSearch'
import { SendEmail } from 'assets/images/email'
import { useInputValue } from 'hooks/useInputValue'
import { Button } from 'common/buttons'
import { Editor } from '@tinymce/tinymce-react'
import { ErrorContainer } from 'forms/styles'

import { ModalEmail, Form, Header, Container, Buttons, DropZone } from './styles'
import { Colors, FontFamily } from 'assets/styles/variables'
import { useSelectValue } from 'hooks/useSelectValue'
import { Alert } from './ui/alert'
import { useRequest } from 'hooks/useRequest'
import { Context } from 'Context'
import { SuccessContainer } from 'components/agentList/styles'

export const EmailForm = ({ open = false, addressee = [], onlyUser = null, onClose = () => {} }) => {
  // hook
  const { userData } = useContext(Context)
  const { t } = useTranslation()
  const subject = useSelectValue('asunto', '', 'required')
  const to = useSelectValue('enviar a', [], 'required')
  const message = useInputValue('mensaje', '', 'required')
  // State
  const [files, setFiles] = useState([])
  const [openAlert, setOpenAlert] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  // ref
  const editorRef = useRef(null)
  // hook request
  const [sendEmail, { loading }] = useRequest('email/send', 'POST')

  const insertDefaultValue = () => {
    // addressee.unshift({ value: 'all', name: 'Todos' })
    return addressee
  }

  useEffect(() => {
    if (onlyUser) {
      to.onChange([onlyUser])
    }
  }, [onlyUser])

  const handleOpenAlert = () => {
    setOpenAlert(true)
  }

  const handleClose = () => {
    setOpenAlert(false)
  }

  const handleSend = () => {
    setError('')
    if (subject.validate()) {
      return
    }

    const formData = new window.FormData()
    formData.append('subject', subject.value)

    if (Array.isArray(to.value) && to.value.length > 0) {
      formData.append('to', JSON.stringify(to.value))
    } else {
      const usersId = addressee.map(user => user.value)
      formData.append('to', JSON.stringify(usersId))
    }

    files.forEach(file => {
      formData.append('attachment', file)
    })

    formData.append('message', message.value)
    formData.append('userId', userData?.user?.id)

    sendEmail({ body: formData, isFormData: true })
      .then(res => {
        const { result } = res
        if (result) {
          setSuccess(t('Se envío su correo electrónico'))
          handleClose()

          setTimeout(() => {
            setSuccess('')
            onClose()
          }, 3000)
        }
      })
      .catch(err => {
        console.log(err)
        setError(t('Ocurrio un problema con el envio del correo electrónico'))
      })
  }

  const handleFile = (files) => {
    setFiles(files)
  }

  const isForOnlyUser = () => {
    return !!onlyUser
  }

  return (
    <ModalEmail
      open={open}
      onClose={onClose}
    >
      <Form>
        <Container>
          {
            error && (
              <ErrorContainer className='email__error'>{error}</ErrorContainer>
            )
          }
          {
            success && (
              <SuccessContainer className='email__success'>{success}</SuccessContainer>
            )
          }
          <Header>
            <SendEmail width='80px' />
            <div className='input-container'>
              <Input label={`${t('Asunto')}:`} {...subject} />

              {
                !isForOnlyUser() && (
                  <SelectSearch
                    label={`${t('Enviar a')}:`}
                    options={insertDefaultValue()}
                    placeholder={t('Todos')}
                    multiple
                    {...to}
                  />
                )
              }
            </div>
          </Header>
          <Editor
            onInit={(evt, editor) => { editorRef.current = editor }}
            apiKey={process.env.REACT_TINYMCE}
            onEditorChange={(newText) => message.onChange(newText)}
            init={{
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | fontsizeselect formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
              content_style: `body { font-family: ${FontFamily},Helvetica,Arial,sans-serif; font-size:14px }`
            }}
          />

          <DropZone onChange={handleFile} />

          <Buttons>
            <Button onClick={onClose} bgColor={Colors.textGrey} type='button'>{t('Cancelar')}</Button>
            <Button onClick={handleOpenAlert} type='button'>{t('Enviar')}</Button>
          </Buttons>
        </Container>
      </Form>
      {/*  */}
      <Alert open={openAlert} onCancel={handleClose} onAccept={handleSend} disabled={loading} />
    </ModalEmail>
  )
}
