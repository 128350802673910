import { useContext, useRef, useState } from 'react'
import { Header } from './ui/header'
import { Sidebar } from './ui/sidebar'
import { getViewPort } from 'helpers/getViewport'
import { MainContainer, Container, Aside } from './styles'
import { Context } from 'Context'

export const Layout = ({ children, hideSidebar = false, hideSearchBar = false }) => {
  // Context
  const { userData } = useContext(Context)
  // References
  const sideBar = useRef()
  // States
  const [isDesktop] = useState(getViewPort().vw > 1024)

  const handleSidebar = () => {
    sideBar.current.toggleAside()
  }

  const isClient = () => {
    return userData?.user?.level === '4'
  }

  return (
    <MainContainer isClient={isClient()}>
      <Header
        onOpenMenu={handleSidebar}
        isDesktop={isDesktop}
        hideSearchBar={hideSearchBar}
        isClient={isClient()}
      />
      {
        !hideSidebar && (
          <Sidebar
            isDesktop={isDesktop}
            ref={sideBar}
          />
        )
      }
      <Container>
        <div>
          {
            children.length > 1 ? children[0] : children
          }
        </div>
        <Aside>
          {
            children.length > 1 && children[1]
          }
        </Aside>
      </Container>
    </MainContainer>
  )
}
