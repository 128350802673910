import { NoImage } from 'assets/icons/noImage'
import { AvatarRound, Thumbnail } from './styles'

export const Avatar = ({ size = 'medium', image = '', Icon = null }) => (
  <AvatarRound size={size}>
    {
      image || Icon
        ? image
            ? <Thumbnail src={image} />
            : Icon
        : <NoImage />
    }
  </AvatarRound>
)
