export const RequestsImage = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={152.691}
    height={163.598}
    viewBox='0 0 152.691 163.598'
    {...props}
  >
    <path
      d='M150.919 65.586L83.786 2.933a10.9 10.9 0 00-14.882 0L1.772 65.586v68.332h149.147z'
      fill='#8a6ec2'
    />
    <path
      d='M54.321 114.628l14.583 13.61a10.906 10.906 0 0014.883 0l41.637-38.859V16.362a5.454 5.454 0 00-5.453-5.454H32.719a5.454 5.454 0 00-5.453 5.454v73.017z'
      fill='#f7f4f2'
    />
    <path
      d='M119.971 10.906h-7.57a5.454 5.454 0 015.453 5.454v80.085l7.57-7.066V16.36a5.454 5.454 0 00-5.453-5.454z'
      fill='#f4e8df'
    />
    <path
      d='M1.772 65.586l52.549 49.042 14.583 13.61a10.906 10.906 0 0014.883 0l67.133-62.652a5.438 5.438 0 011.771 4.023v83.082a10.906 10.906 0 01-10.907 10.906H10.906A10.906 10.906 0 010 152.691V69.609a5.444 5.444 0 011.771-4.024z'
      fill='#f1cc6f'
    />
    <path
      d='M1.772 65.586l52.549 49.042 14.583 13.61a10.906 10.906 0 0014.883 0l67.133-62.652a5.438 5.438 0 011.771 4.023v83.082a10.906 10.906 0 01-10.907 10.906H10.906A10.906 10.906 0 010 152.691V69.609a5.444 5.444 0 011.771-4.024z'
      fill='#b385eb'
    />
    <path
      d='M150.919 65.586l-5.8 5.412v81.693a10.907 10.907 0 01-10.905 10.907h7.57a10.907 10.907 0 0010.906-10.907V69.609a5.438 5.438 0 00-1.771-4.023z'
      fill='#8a6ec2'
    />
    <path
      d='M136.332 152.692a5.454 5.454 0 005.453-5.454 5.453 5.453 0 00-5.453-5.453h-21.814a5.454 5.454 0 00-5.454 5.453 5.454 5.454 0 005.454 5.454z'
      fill='#c6a9ef'
    />
    <path
      d='M136.332 141.785h-7.572a5.454 5.454 0 015.452 5.454 5.454 5.454 0 01-5.454 5.453h7.572a5.453 5.453 0 005.453-5.453 5.454 5.454 0 00-5.451-5.454z'
      fill='#c6a9ef'
    />
    <g transform='translate(59.985 21.013)' fill='#87868a'>
      <circle
        cx={5.453}
        cy={5.453}
        transform='translate(10.886 54.524)'
        r={5.453}
      />
      <path d='M16.36 43.625a5.453 5.453 0 01-5.453-5.453v-5.456a12.158 12.158 0 017.522-11.313 5.462 5.462 0 003.37-5.445 5.518 5.518 0 00-5.041-5.041 5.443 5.443 0 00-4.118 1.451 5.4 5.4 0 00-1.733 3.988 5.452 5.452 0 01-5.453 5.453A5.453 5.453 0 010 16.356 16.42 16.42 0 015.2 4.395 16.428 16.428 0 0117.509.036 16.4 16.4 0 0132.684 15.21a16.358 16.358 0 01-10.107 16.286 1.306 1.306 0 00-.76 1.222v5.453a5.452 5.452 0 01-5.453 5.453z' />
    </g>
  </svg>
)
