import { forwardRef, useEffect, useRef } from 'react'
import { MainContainer, Background, Container } from './styles'

export const Modal = forwardRef(({ width = null, height = null, padding = null, top = null, className = '', children, onClose = () => {}, open = false }, ref) => {
  // References
  const isMountedRef = useRef()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }
    return () => {
      document.body.removeAttribute('style')
      isMountedRef.current = false
    }
  }, [open])

  return (
    open && (
      <MainContainer ref={ref} className={className}>
        <Background onClick={() => onClose(null)} />
        <Container
          top={top}
          width={width}
          height={height}
          padding={padding}
          className='modal__container'
        >
          {children}
        </Container>
      </MainContainer>
    )
  )
})
