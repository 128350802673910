export const MailIcon = ({ size = 31, color = '#7e35dc', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 31.288 19.177'
    {...props}
  >
    <g fill={color} data-name='Grupo 46'>
      <path
        data-name='Trazado 110'
        d='M30.783 0H.5a.5.5 0 00-.5.5v18.167a.5.5 0 00.5.5h30.279a.5.5 0 00.5-.5V.5a.5.5 0 00-.496-.5zM9.405 8.965l5.966 3.58a.5.5 0 00.519 0l5.979-3.587 7.829 9.21H1.583zm-8.4 8.334V3.919l7.532 4.519zm21.75-8.869l7.519-4.511v13.357zm7.519-7.421v1.733l-14.635 8.781L1.004 2.742V1.009z'
      />
      <path
        data-name='Trazado 111'
        d='M2.001 2.523h3.028a.5.5 0 00.5-.5.5.5 0 00-.5-.5H2.001a.5.5 0 00-.5.5.5.5 0 00.5.5z'
      />
      <path
        data-name='Trazado 112'
        d='M6.543 2.523h.5a.5.5 0 00.5-.5.5.5 0 00-.5-.5h-.5a.5.5 0 00-.5.5.5.5 0 00.5.5z'
      />
    </g>
  </svg>
)
