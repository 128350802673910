import { css } from 'styled-components'
import { FontWeight, FontFamily } from 'assets/styles/variables'

import MetropolisBlack from 'fonts/Metropolis-Black.ttf'
import MetropolisBlackItalic from 'fonts/Metropolis-BlackItalic.ttf'
import MetropolisBold from 'fonts/Metropolis-Bold.ttf'
import MetropolisBoldItalic from 'fonts/Metropolis-BoldItalic.ttf'
import MetropolisExtraBold from 'fonts/Metropolis-ExtraBold.ttf'
import MetropolisExtraBoldItalic from 'fonts/Metropolis-ExtraBoldItalic.ttf'
import MetropolisExtraLight from 'fonts/Metropolis-ExtraLight.ttf'
import MetropolisExtraLightItalic from 'fonts/Metropolis-ExtraLightItalic.ttf'
import MetropolisLight from 'fonts/Metropolis-Light.ttf'
import MetropolisLightItali from 'fonts/Metropolis-LightItalic.ttf'
import MetropolisMedium from 'fonts/Metropolis-Medium.ttf'
import MetropolisMediumItalic from 'fonts/Metropolis-MediumItalic.ttf'
import MetropolisRegular from 'fonts/Metropolis-Regular.ttf'
import MetropolisRegularItalic from 'fonts/Metropolis-RegularItalic.ttf'
import MetropolisThin from 'fonts/Metropolis-Thin.ttf'
import MetropolisThinItalic from 'fonts/Metropolis-ThinItalic.ttf'

export const fontFaces = css`
  /** Metropolis Thin*/
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisThin}') format('truetype');
    font-weight: ${FontWeight.fw100};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisThinItalic}') format('truetype');
    font-weight: ${FontWeight.fw100};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis Extra Light */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisExtraLight}') format('truetype');
    font-weight: ${FontWeight.fw200};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisExtraLightItalic}') format('truetype');
    font-weight: ${FontWeight.fw200};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis Light */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisLight}') format('truetype');
    font-weight: ${FontWeight.fw300};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisLightItali}') format('truetype');
    font-weight: ${FontWeight.fw300};
    font-style: italic;
    font-display: block;
  }

  /** Regular */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisRegular}') format('truetype');
    font-weight: ${FontWeight.fw400};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisRegularItalic}') format('truetype');
    font-weight: ${FontWeight.fw400};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis Medium */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisMedium}') format('truetype');
    font-weight: ${FontWeight.fw500};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisMediumItalic}') format('truetype');
    font-weight: ${FontWeight.fw500};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis Bold */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisBold}') format('truetype');
    font-weight: ${FontWeight.fw600};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisBoldItalic}') format('truetype');
    font-weight: ${FontWeight.fw600};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis ExtraBold */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisExtraBold}') format('truetype');
    font-weight: ${FontWeight.fw800};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisExtraBoldItalic}') format('truetype');
    font-weight: ${FontWeight.fw800};
    font-style: italic;
    font-display: block;
  }

  /** Metropolis-Black */
  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisBlack}') format('truetype');
    font-weight: ${FontWeight.fw900};
    font-style: normal;
    font-display: block;
  }

  @font-face {
    font-family: ${FontFamily};
    src: url('../${MetropolisBlackItalic}') format('truetype');
    font-weight: ${FontWeight.fw900};
    font-style: italic;
    font-display: block;
  }
`
