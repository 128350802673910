import React from 'react'

import { Field, ErrorMessage, SelectComponent, Label, Option } from 'formStyle'
import { ErrorIcon } from 'icons/errorIcon'

export const Select = ({ label = '', name = 'common', value = '', options = [], onChange = () => {}, valueDefault = '0', optionDefault = '', error = '', width = '100%', isRequired }) => {
  return (
    <Field width={width} className='field-form'>
      {label && (
        <Label required={isRequired}>
          {isRequired && <span className='input-required' />}
          {label}
        </Label>
      )}
      <SelectComponent name={name} value={value} onChange={onChange}>
        <Option value={valueDefault}>{optionDefault && optionDefault}</Option>
        {options.map((o, i) => (
          <Option key={i} value={o.id}>
            {o.label}
          </Option>
        ))}
      </SelectComponent>
      {error && <ErrorIcon size={20} className='error-icon select-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  )
}
