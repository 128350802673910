export const LinkIcon = ({ size = 20, color = '#294775', ...props }) => (
  <svg
    id='prefix__Capa_1'
    data-name='Capa 1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 512 512'
    width={size}
    height={size}
    {...props}
  >
    <path
      d='M389.48 0a121.72 121.72 0 00-86.62 35.89l-95.41 95.41c7.3-1 14.58-2.13 22-2.13a159.55 159.55 0 0159.2 11.4l59.43-59.43a58.51 58.51 0 1182.75 82.75l-107 107c-22.12 22.09-60.62 22.11-82.75 0l-45.22 45.29a122.51 122.51 0 00173.22-.05l107-107A122.51 122.51 0 00389.48 0z'
      fill={color}
    />
    <path
      d='M222.76 372l-58.87 58.87a58.51 58.51 0 11-82.75-82.75l107-107c22.13-22.09 60.63-22.11 82.75 0l45.22-45.29c-46.27-46.22-126.94-46.23-173.22 0l-107 107a122.51 122.51 0 10173.25 173.28L303.28 382c-6.87.92-13.71 2-20.77 2a154.07 154.07 0 01-59.75-12z'
      fill={color}
    />
  </svg>
)
