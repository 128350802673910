import { useContext } from 'react'

import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'

import { ClientsImage } from 'assets/images/clients'
import { SimpleCard } from 'common/cards/simple'
import { GridClient, RowButton, RowImage, RowText } from '../styles'
import { Colors } from 'assets/styles/variables'
import { Button } from 'common/buttons'
import { Context } from 'Context'

export const CardAddClient = () => {
  const { setOpenCreate } = useContext(Context)
  const { t } = useTranslation()
  const handleToCreateClient = () => {
    setOpenCreate(true)
    navigate('/agent/clients')
  }
  return (
    <SimpleCard>
      <GridClient>
        <RowImage>
          <ClientsImage />
        </RowImage>
        <RowText>
          <p>{t('¡Hola! ¿Tienes clientes por agregar?')}</p>
        </RowText>
        <RowButton>
          <Button
            onClick={handleToCreateClient}
            type='button'
            height='40px'
            bgColor={Colors.purple}
            color={Colors.white}
            width='72%'
          >
            {t('Nuevo Cliente')}
          </Button>
        </RowButton>
      </GridClient>
    </SimpleCard>
  )
}
