export const CheckIcon = ({ size = 16.838, color = '#fff', ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 16.838 11.891'
      {...props}
    >
      <g>
        <path
          d='M1.229 5.204L0 6.432l5.2 5.2a.868.868 0 001.226 0L16.835 1.225l-1.227-1.23-9.79 9.8z'
          fill={color}
        />
      </g>
    </svg>
  )
}
