import { useTranslation } from 'react-i18next'
import { useInputValue } from 'hooks/useInputValue'
import { Input } from 'forms/input'

import { SearchBarContainer } from '../styles'

export const SearchBar = ({ onChange }) => {
  // hooks
  const { t } = useTranslation()
  const search = useInputValue()

  return (
    <SearchBarContainer>
      <Input type='search' placeholder={`${t('¿Qué estas buscando?')}...`} {...search} />
    </SearchBarContainer>
  )
}
