import { Colors } from 'assets/styles/variables'
import { useTranslation } from 'react-i18next'

import { Button } from 'common/buttons'
import { SimpleCard } from 'common/cards/simple'
import { GridClient, RowButton, RowImage, RowText } from '../styles'
import { SendEmail } from 'assets/images/email'

export const CardSendEmail = ({ onClick = () => {} }) => {
  const { t } = useTranslation()
  return (
    <SimpleCard>
      <GridClient>
        <RowImage>
          <SendEmail width='140px' />
        </RowImage>
        <RowText>
          <p>{t('¿Deseas enviar un correo electrónico?')}</p>
        </RowText>
        <RowButton>
          <Button
            type='button'
            height='40px'
            bgColor={Colors.purple}
            color={Colors.white}
            width='72%'
          >
            {t('Enviar mensaje')}
          </Button>
        </RowButton>
      </GridClient>
    </SimpleCard>
  )
}
