import styled from 'styled-components'
import { Device, MinQueriesSize, Colors } from 'styles/variables'
import { Button } from 'common/buttons'

export const MainContainer = styled.div`
  position: fixed;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 100001;

  &.alert {
    .modal__container {
      width: 400px !important;
      height: max-content;
      padding: 2rem 1.5rem;
    } 
  }
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.3);
  width: 100%;
  height: 100%;
  z-index: 1000;
`

export const Container = styled.div`
  position: absolute;
  top: ${({ top }) => top ? `${top}%` : 0};
  left: 0;
  right: 0;
  bottom: ${({ top }) => top ? 'unset' : 0};
  margin: auto;
  background-color: white;
  padding: ${({ padding }) => padding || '1rem 2rem'};
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  width: 90%;
  /* max-height: 90vh; */
  z-index: 100001;
  border-radius: 20px;
  overflow-y: auto;
  overflow-x: hidden;

  @media ${MinQueriesSize(Device.tableM)} {
    width: ${({ width }) => width || '400px'};
    height: ${({ height }) => height || 'auto'};
    /* max-height: 90vh; */
  }
`

// Alert
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const CancelButton = styled(Button)`
  background-color: ${({ dark }) => dark ? Colors.textCellTable : Colors.textGrey};
  width: 180px;
`

export const OkButton = styled(Button)`
  width: 180px;
  margin-left: 1rem;
`

export const TitleModal = styled.h1`
  text-align: center;
  color: ${Colors.blueDark};
  font-size: 1.2rem;
  margin-bottom: 1rem;
`
