import styled, { css } from 'styled-components'
import { Colors, Device, FontFamily, FontWeight } from 'assets/styles/variables'
import { ErrorContainer as ErrorContainerBase, SuccessContainer as SuccessContainerBase } from 'formStyle'
import { Td as TdComponent } from 'assets/styles/general'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 0px;
  grid-row-gap: 32px;
  padding: 0 32px;
  width: 100%;
`

export const FirstRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1 / 2 / 2;

  svg {
    width: 196px;
    height: 190px;
  }
`

export const SecondRow = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`

export const Text = styled.p`
  color: ${Colors.textCellTable};
  font-style: ${FontFamily};
  font-weight: 400;
  font-size: 20px;
  margin-top: 16px;
  text-align: center;
`

// Grid Card Agent
export const GridAgent = styled.div`
  display: grid;
  grid-template-columns: .6fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  margin-bottom: 16px;
  padding: 0 16px;
  width: 100%;
`

export const FirstAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: flex-end;
`

export const SecondAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: flex-start;
`

export const ThirdtAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 2 / 1 / 3 / 3;
  justify-content: center;
`

export const Quantity = styled.h1`
  color: ${Colors.textCellTable};
  font-family: ${FontFamily};
  font-size: 60px;
  font-weight: ${FontWeight.fw900};
`

export const Label = styled.p`
  color: ${Colors.textHeadTable};
  font-family: ${FontFamily};
  font-size: 16px;
  font-style: italic;
  font-weight: ${FontWeight.fw400};
  text-align: center;
`

// Form

export const TitleForm = styled.h1`
  text-align: center;
  color: ${Colors.blueDark};
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

export const Form = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;

  @media (max-width: ${Device.tableM}px) {
    display: block;
  }
`

export const InputFileContainer = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ButtonContainer = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

// Alert

export const AlertContainer = styled.div`
  padding: 0 2rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap column;

  svg {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    text-align: center;

    span {
      font-weight: bold;
    }
  }
`

// View data
export const TitleView = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  color: ${Colors.blueDark};
`

export const ViewContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;
  grid-gap: 1rem;
  margin-top: 1rem;

  svg, image {
    margin-bottom: 1rem;
  }

  button {
    margin-top: .5rem;
  }
`

export const Item = styled.div`
  ${({ flex }) => flex
    ? css`
      display: flex;
    `
    : null
  }
  span {
    display: block;
    margin-bottom: 1rem;
    color: ${Colors.textGrey};
    margin-right: ${({ flex }) => flex ? '.5rem' : 0};
  }
`

export const ErrorContainer = styled(ErrorContainerBase)`
  grid-column: 1 / 3;
  text-align: center;
  margin-bottom: 1rem;
`

export const SuccessContainer = styled(SuccessContainerBase)`
  grid-column: 1 / 3;
  text-align: center;
  margin-bottom: 1rem;
`

export const Td = styled(TdComponent)`
  @media (max-width: ${Device.tableM}px) {
    &:nth-of-type(1):before { content: '${({ ariaLabel }) => ariaLabel}'; }
    &:nth-of-type(2):before { content: '${({ ariaLabel }) => ariaLabel}'; }
    &:nth-of-type(3):before { content: '${({ ariaLabel }) => ariaLabel}'; }
    &:nth-of-type(4):before { content: '${({ ariaLabel }) => ariaLabel}'; }
    &:nth-of-type(5):before { content: '${({ ariaLabel }) => ariaLabel}'; }
    &:nth-of-type(6):before { content: '${({ ariaLabel }) => ariaLabel}'; }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`

export const RoleContainer = styled.div`
  .radio-button {
    border: 2px solid ${Colors.textGrey};
    height: 35px;
    padding: 0 1.5rem;
    transition: ease boder-color .3s, ease color .3s;
    
    &:first-of-type {
      margin-right: 1rem;
    }

    .checkbox {
      display: none;
    }

    &.checked {
      border-color: ${Colors.purple};
      color: ${Colors.purple};
    }
  }
`

export const ListLicensesContainer = styled.div`
  grid-column: 1 / 3;
  border-top: 1px solid ${Colors.borderGrey};
  margin-top: 2rem;
  padding-top: 1rem;
`

export const LicenseContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 220px 1fr;
`
