import styled from 'styled-components'
import { Device, MinQueriesSize } from 'assets/styles/variables'
import { Modal } from '../../modal'
import { DropZone as DropZoneBase } from 'common/forms/dropZone'

// import { Textarea } from "common/forms/textarea";

export const ModalEmail = styled(Modal)`

  .modal__container {   
    width: 95% !important;
    height: 73%;
    padding: 2rem 1rem 0;
  }

  @media ${MinQueriesSize(Device.tableM)} {
    .modal__container {   
      width: 60% !important;
    }
  }

  @media ${MinQueriesSize(Device.tableL)} {
    .modal__container {   
      width: 70% !important;
    }
  }

  @media ${MinQueriesSize(Device.laptop)} {
    .modal__container {   
      width: 50% !important;
    }
  }
`

export const Form = styled.form`
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`

export const Header = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: auto;
  grid-row-gap: 1rem;
  justify-items: center;
  margin-bottom: 1rem;

  @media ${MinQueriesSize(Device.tableL)} {
    grid-template-columns: 120px 1fr;
  }

  .input-container {
    width: 100%;
  }

  .field-form {
    display: grid;
    grid-template-columns: 80px 1fr;
    flex-direction: unset;
    align-items: center;

    label {
      margin: 0;
      margin-right: .2rem;
    }

    input {
      height: 25px;
      border-radius: 3px;
    }

    span {
      grid-column: 2/3;
    }

    svg {
      top: 2px;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  height: auto;

  textarea {
    height: 160px;
  }
`

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  button {
    width: 120px;

    &:first-of-type {
      margin-right: 1rem;
    }
  }
`

export const TextAlert = styled.p`
  width: 100%;
  margin: 0 auto;
  font-size: 1.1rem;
  font-weight: 700;
  text-align: center;
`

export const DropZone = styled(DropZoneBase)`
  margin-top: 1rem;
  min-height: 170px;
  max-height: 170px;
`
