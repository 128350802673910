import { useState, useEffect, useRef } from 'react'
import { EditIcon } from 'icons/editIcon'
import { InputFileContainerMain, InputFileContainer, ButtonInputFile, ButtonEdit } from './styles'
import { ImageFallback } from 'common/imageFallback'

export const InputFile = ({ text, accept = 'image/*', multiple = false, onChange, preview: externalPreview }) => {
  // ref
  const inputRef = useRef(null)
  // State
  const [preview, setPreview] = useState()

  useEffect(() => {
    if (externalPreview && typeof externalPreview === 'object') {
      previewFile(externalPreview)
      return
    }
    setPreview(externalPreview)
  }, [externalPreview])

  const handleClick = () => {
    inputRef.current.click()
  }

  const previewFile = (file) => {
    const reader = new window.FileReader()

    reader.onloadend = () => {
      setPreview(reader.result)
    }
    reader.readAsDataURL(file)
  }

  const handleChange = (event) => {
    const files = event.target.files

    if (multiple) {
      onChange(files)
    } else {
      previewFile(files[0])
      onChange(files[0])
    }
  }

  return (
    <InputFileContainerMain>
      <InputFileContainer border={!!preview}>
        <ImageFallback src={preview} />
        <input ref={inputRef} type='file' accept={accept} multiple={multiple} onChange={handleChange} />
      </InputFileContainer>
      {text && !preview && <ButtonInputFile type='button' onClick={handleClick}>{text}</ButtonInputFile>}
      {preview && (
        <ButtonEdit onClick={handleClick}>
          <EditIcon color='white' size='19' />
        </ButtonEdit>
      )}
    </InputFileContainerMain>
  )
}
