/* eslint-disable react/jsx-handler-names */
import { useState, useEffect, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useInputValue } from 'hooks/useInputValue'
import { useRequest } from 'hooks/useRequest'

import { Input } from 'common/forms/input'
import { InputFile } from 'common/forms/inputFile'
import { DropZone } from 'common/forms/dropZone'
import { InputDatePicker } from 'common/forms/datePicker'
import { Button } from 'common/buttons'
import { RadioContainer, Radio } from 'common/forms/inputRadio'
import { LicenceAgent } from './licenceAgent'
import statesJson from 'common/statesJson/index.json'
import { TitleForm, Form, ButtonContainer, ErrorContainer, SuccessContainer, InputFileContainer, Divide, ButtonAddLincence, ButtonAndIconAdd, ContainerLicences, DropzoneContainer } from '../styles'
import { Context } from 'Context'
import { loadImageAgent, loadFilesAgent } from 'helpers/loadImageAgent'
// import { loadImageAgent } from 'helpers/loadImageAgent'
import { Colors } from 'styles/variables'
import { InputPhone } from 'common/forms/inputPhone'

export const FormAgent = ({ title = '', onClose = null }) => {
  const { userData, dispatchUser } = useContext(Context)
  const { t } = useTranslation()
  // ref
  const licencesRef = useRef({})
  const dropZoneRef = useRef(null)
  const licensesValuesRef = useRef([])
  // hooks inputs
  const firstname = useInputValue(t('Nombre'), '', 'required|min:2')
  const email = useInputValue(t('Correo Electronico'), '', 'required|email')
  const lastname = useInputValue(t('Apellido'), '', 'required|min:2')
  const dateOfBorn = useInputValue(t('Fecha de Nacimiento'), '', 'required')
  const gender = useInputValue(t('Gender'), '', 'required')
  const ssn = useInputValue(t('SSN'), '', '')
  const phone = useInputValue(t('Teléfono'), '', 'required')
  const npn = useInputValue(t('NPN'), '', 'required')
  const address = useInputValue(t('Dirección'), '', 'required')
  const errorOmission = useInputValue(t('Error y Omisión'), '0', 'required')
  const [photo, setPhoto] = useState(null)
  const [photoPreview, setPhotoPreview] = useState(null)
  const [files, setFiles] = useState([])
  const [filesSaved, setFilesSaved] = useState([])
  const [filesToDelete, setFilesToDelete] = useState([])

  const [licenses, setLicenses] = useState([1])

  //
  // state
  const [states, setStates] = useState([])
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  // Hooks
  const [updateAgent, { loading: loadingUpdateAgent }] = useRequest('agents/complete-info')
  const [uploadPhoto, { loading: loadingPhoto }] = useRequest('agents/complete-info/photo')
  const [addLicenses, { loading: loadingLicenses }] = useRequest('agents/add-licenses')
  const [addFiles, { loading: loadingFiles }] = useRequest('agents/complete-info/files')
  const [removeFiles, { loading: loadingDeleteFile }] = useRequest('agents/complete-info/delete-files')

  useEffect(() => {
    const agent = userData?.user?.agent

    if (agent) {
      firstname.onChange(agent?.person?.firstname)
      email.onChange(userData?.user.email)
      lastname.onChange(agent?.person?.lastname)
      dateOfBorn.onChange(agent?.person?.date_of_born ? new Date(agent?.person?.date_of_born) : '')
      gender.onChange(agent?.person?.gender)
      npn.onChange(agent?.npn || '')
      address.onChange(agent?.person?.address || '')
      errorOmission.onChange(agent?.errors_and_omissions !== null ? agent?.errors_and_omissions.toString() : '')
      ssn.onChange(agent?.person?.ssn || '')
      phone.onChange(agent?.person.phone || '')
      setPhotoPreview(agent?.image)

      if (Array.isArray(agent.files)) {
        const files = agent.files.map(file => {
          return {
            ...file,
            file: loadFilesAgent(file.file)
          }
        })
        setFilesSaved(files)
      }

      licensesValuesRef.current = []
      if (Array.isArray(agent?.licenses) && agent?.licenses.length > 0) {
        const itemsLicenses = agent?.licenses.map((item, index) => {
          licensesValuesRef.current.push({
            numberRef: index,
            ...item
          })
          return index
        })
        setLicenses([...itemsLicenses])
      }
    }
  }, [userData])

  useEffect(() => {
    const newState = []
    for (const key in statesJson) {
      newState.push({ id: key, label: statesJson[key] })
    }
    setStates(newState)
  }, [])

  const handleFile = file => {
    setPhoto(file)
  }

  const renderLicences = () => {
    return licenses.map((numberInArray, index) => (
      <LicenceAgent
        key={numberInArray}
        ref={ref => { licencesRef.current[numberInArray] = ref }}
        numberRef={numberInArray}
        number={index}
        items={licenses.length}
        states={states}
        values={verifyDataLicense(numberInArray)}
        onChange={handleProcessLicense}
        onRemove={() => handleOnRemove(index, numberInArray)}
      />
    ))
  }

  const handleProcessLicense = (value) => {
    const updateLincenseValues = [...licensesValuesRef.current]

    const index = updateLincenseValues.findIndex(license => license.numberRef === value.numberRef)
    if (index > -1) {
      updateLincenseValues.splice(index, 1)
    }

    updateLincenseValues.push(value)

    licensesValuesRef.current = updateLincenseValues
  }

  const handleAddLincence = () => {
    const newListLicence = [...licenses]
    const [lastItem] = newListLicence.slice(-1)

    const newLastItem = lastItem + 1

    newListLicence.push(newLastItem)
    setLicenses(newListLicence)
  }

  const verifyDataLicense = (numberRef) => {
    const value = licensesValuesRef.current.find(item => item.numberRef === numberRef) || {}
    return value
  }

  const handleOnRemove = (indexOld, numberInArray) => {
    const newListLicence = [...licenses]

    const index = newListLicence.findIndex(itemNumber => itemNumber === numberInArray)
    newListLicence.splice(index, 1)

    setLicenses(newListLicence)
  }

  const handleOnSave = async () => {
    setError()

    let urlFile = photoPreview

    if (
      firstname.validate() ||
      email.validate() ||
      lastname.validate() ||
      dateOfBorn.validate() ||
      npn.validate() ||
      gender.validate() ||
      address.validate() ||
      phone.validate() ||
      (userData.level === 3 && errorOmission.validate())
    ) {
      return
    }

    let withError = false
    for (const key in licencesRef.current) {
      if (licencesRef.current[key]) {
        if (!withError && licencesRef.current[key].validate()) {
          withError = true
        }
      }
    }

    const licenses = []
    for (const key in licencesRef.current) {
      if (licencesRef.current[key]) {
        licenses.push(licencesRef.current[key].values())
      }
    }

    if (withError) {
      return
    }

    if (!urlFile && !photo) {
      setError(t('Debes subir una imagen'))
      return
    }

    const data = {
      firstname: firstname.value,
      email: email.value,
      lastname: lastname.value,
      date_of_born: dateOfBorn.value,
      npn: npn.value,
      gender: gender.value,
      ssn: ssn.value,
      phone: phone.value,
      address: address.value,
      errors_and_omissions: errorOmission.value,
      agent_id: userData?.user?.agent?.id,
      person_id: userData?.user?.agent?.person?.id,
      image: urlFile,
      level: userData.level
    }

    // process files
    const formDataFiles = new window.FormData()
    files.map(file => formDataFiles.append('files', file))
    formDataFiles.append('agent_id', userData?.user?.agent?.id)

    try {
      const agentId = userData?.user?.agent?.id
      // upload photo
      if (photo) {
        const formData = new window.FormData()
        formData.append('photo', photo)
        formData.append('agent_id', agentId)

        const file = await uploadPhoto({ body: formData, isFormData: true })
        urlFile = file.image
      }

      // send agent data
      const agentData = await updateAgent({ body: data })

      // send license data
      const licensesSaved = await addLicenses({
        body: {
          licenses,
          agent_id: agentId
        }
      })

      // Delete files
      await removeFiles({
        body: {
          idFiles: filesToDelete,
          agent_id: agentId
        }
      })

      const filesSend = await addFiles({ body: formDataFiles, isFormData: true })

      if (agentData.result && licensesSaved.result) {
        setSuccess(t('Sus datos se han actualizado con éxito'))

        const newUserData = { ...userData }
        newUserData.user.agent = agentData.agent
        newUserData.user.agent.licenses = licensesSaved.licenses
        newUserData.user.agent.files = filesSend.files

        dropZoneRef.current.reset()

        setFiles([])
        setFilesToDelete([])

        setTimeout(() => {
          dispatchUser({ type: 'set', payload: newUserData })
        }, 2000)
      }
    // updateAgent
    } catch (err) {
      if (err?.message) {
        setError(err?.message)
      }
    }
  }

  const handleChangeDropzone = (files) => {
    setFiles(files)
  }

  const handleRemove = (file, index = null) => {
    const newFiles = [...filesSaved]
    const newFilesToDelete = [...filesToDelete]

    newFilesToDelete.push(file.id)

    setFilesToDelete(newFilesToDelete)
    setFilesSaved(newFiles.filter(f => f.id !== file.id))
  }

  return (
    <>
      <TitleForm>{title}</TitleForm>

      <Form>
        {error && <ErrorContainer>{error}</ErrorContainer>}
        {success && <SuccessContainer>{success}</SuccessContainer>}

        <InputFileContainer>
          <InputFile text={t('Subir foto del agente')} onChange={handleFile} preview={photoPreview} />
        </InputFileContainer>

        <Input label={t('Nombre')} {...firstname} />
        <Input label={t('Correo Electronico')} {...email} />
        <Input label={t('Apellido')} {...lastname} />
        <InputDatePicker label={t('Fecha de Nacimiento')} {...dateOfBorn} placeholder='0000-00-00' />
        <Input label={t('NPN')} {...npn} />
        <RadioContainer label={t('Gender')} {...gender}>
          <Radio value='F' name='gender' label={t('Femenino')} valueSelected={gender.value} onChange={gender.onChange} />
          <Radio value='M' name='gender' label={t('Masculino')} valueSelected={gender.value} onChange={gender.onChange} />
          <Radio value='O' name='gender' label={t('Otro')} valueSelected={gender.value} onChange={gender.onChange} />
        </RadioContainer>
        <Input label={t('SSN')} {...ssn} />
        <Input label={t('Dirección')} {...address} />
        {/* <Input label={t('Phone')} {...phone} /> */}
        <InputPhone label={t('Phone')} {...phone} />

        {
          userData.level === 3 && (
            <RadioContainer label={t('Error y omisión')} {...gender}>
              <Radio
                value='0'
                name='errorOmission'
                label={t('No')}
                valueSelected={errorOmission.value}
                onChange={errorOmission.onChange}
              />
              <Radio
                value='1'
                name='errorOmission'
                label={t('Si')}
                valueSelected={errorOmission.value}
                onChange={errorOmission.onChange}
              />
            </RadioContainer>
          )
        }

        <Divide>
          <span>{t('Licencia por Estado')}</span>
        </Divide>

        <ContainerLicences>
          {renderLicences()}
        </ContainerLicences>

        <ButtonContainer>
          <ButtonAddLincence onClick={handleAddLincence}>
            {t('¿Agregar otra licencia?')}
            <ButtonAndIconAdd type='button'>
              <span>+</span>
            </ButtonAndIconAdd>
          </ButtonAddLincence>
        </ButtonContainer>

        <DropzoneContainer>
          <DropZone
            ref={dropZoneRef}
            files={filesSaved}
            fileNameKey='file'
            onChange={handleChangeDropzone}
            onRemove={handleRemove}
          />
        </DropzoneContainer>

        <ButtonContainer>
          <Button
            type='button'
            width='240px'
            onClick={handleOnSave}
            disabled={loadingUpdateAgent || loadingPhoto || loadingLicenses || loadingDeleteFile}
          >
            {t('¡Completa tu perfil!')}
          </Button>
          {
            onClose && (
              <Button
                type='button'
                width='240px'
                onClick={onClose}
                disabled={loadingUpdateAgent || loadingPhoto || loadingLicenses || loadingFiles}
                bgColor={Colors.textGrey}
                style={{ marginLeft: '1rem' }}
              >
                {t('Cerrar')}
              </Button>
            )
          }
        </ButtonContainer>
      </Form>
    </>
  )
}
