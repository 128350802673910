import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend) // load translation using xhr -> see /public/locales
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      crossDomain: true
    },
    // lng: 'en', // Overrides language detection
    fallbackLng: 'es',
    debug: false,

    interpolation: {
      escapeValue: false
    },
    react: {
      wait: false
    },
    load: 'languageOnly'
  }).then(() => {
    window.sessionStorage.setItem('lang', i18n.language.split('-')[0])
  })

export default i18n
