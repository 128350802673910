import get from 'lodash/get'

export const getRole = userData => {
  const level = get(userData, 'user.level', '')
  switch (level) {
    case '1':
      return 'admin'
    case '2':
      return 'agency'
    case '3':
      return 'agent'
    case '4':
      return 'client'

    default:
      return ''
  }
}
