import { getRole } from 'helpers/getRole'
import { createContext, useState, useReducer } from 'react'

// Import reducers
import { KEY_AUTH_USER, authReducer, initAuthReducer } from './reducers/auth'
import { KEY_USER_DATA, userDataReducer, initUserReducer } from './reducers/user'

export const Context = createContext()

const KEY_SELECTED_AGENCY = 'APP_INSURANCE_KEY_SELECTED_AGENCY'
const KEY_SELECTED_TEMPLATE = 'APP_INSURANCE_SELECTED_TEMPLATE'
const KEY_REGISTERED_AGENTS = 'APP_INSURANCE_REGISTERED_AGENTS'

export const AGENCY_DISABLED_STATUS = ['0', '2']

const Provider = ({ children }) => {
  const [auth, dispatchAuth] = useReducer(authReducer, window.sessionStorage.getItem(KEY_AUTH_USER), initAuthReducer)
  const [userData, dispatchUser] = useReducer(userDataReducer, window.sessionStorage.getItem(KEY_USER_DATA), initUserReducer)
  // States
  const [firstRegisterPart, setFirstRegisterPart] = useState({})
  const [openCreate, setOpenCreate] = useState(false)
  const [incomes, setIncomes] = useState([])
  // Selected Agency
  const [selectedAgency, setSelectedAgency] = useState(() => {
    const data = window.sessionStorage.getItem(KEY_SELECTED_AGENCY)
    return data ? JSON.parse(data) : {}
  })
  // Selected template
  const [template, setTemplate] = useState(() => {
    const value = window.sessionStorage.getItem(KEY_SELECTED_TEMPLATE)
    return (!value || value === 'null') ? {} : JSON.parse(value)
  })

  const [isOpenModalAgent, setIsoOpenModalAgent] = useState(false)

  const [registeredAgents, setRegisteredAgents] = useState(() => {
    return window.sessionStorage.getItem(KEY_REGISTERED_AGENTS) || 0
  })

  const value = {
    auth,
    dispatchAuth,
    userData,
    roleUser: () => getRole(userData),
    dispatchUser,
    firstRegisterPart,
    setFirstRegisterPart,
    openCreate,
    setOpenCreate: bool => setOpenCreate(bool),
    selectedAgency,
    setSelectedAgency: agency => {
      setSelectedAgency(agency)
      window.sessionStorage.setItem(KEY_SELECTED_AGENCY, JSON.stringify(agency))
    },
    template,
    setTemplate: object => {
      setTemplate(object)
      window.sessionStorage.setItem(KEY_SELECTED_TEMPLATE, JSON.stringify(object))
    },
    isOpenModalAgent,
    setIsoOpenModalAgent: bool => setIsoOpenModalAgent(bool),
    registeredAgents,
    setRegisteredAgents: q => { setRegisteredAgents(q); window.sessionStorage.setItem(KEY_REGISTERED_AGENTS, q) },
    incomes,
    setIncomes
  }

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export default {
  Provider,
  Consumer: Context.Consumer
}
