export const KEY_AUTH_USER = 'APP_INSURANCE_KEY_AUTH_USER'

const defaultValue = false

export const initAuthReducer = (init) => {
  return init || defaultValue
}

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'login':
      window.sessionStorage.setItem(KEY_AUTH_USER, JSON.parse(action.payload.auth))
      return action.payload.auth
    case 'logout':
      window.sessionStorage.setItem(KEY_AUTH_USER, JSON.parse(defaultValue))
      return defaultValue
    default:
      throw new Error()
  }
}
