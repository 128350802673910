export const ClearIcon = ({ size = 24, color = '#fff', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 298.667 298.667'
    width={size}
    height={size}
    {...props}
  >
    <path d='M298.667 30.187L268.48 0 149.333 119.147 30.187 0 0 30.187l119.147 119.146L0 268.48l30.187 30.187L149.333 179.52 268.48 298.667l30.187-30.187L179.52 149.333z' fill={color} />
  </svg>
)
