export const ProfileIcon = ({ size = 61, color = '#b2b2b2', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 46.221 60.397'
    {...props}
  >
    <path
      d='M45.756 47.541l-.06-.134c-.034-.078-.071-.155-.108-.232a6.792 6.792 0 00-3.943-3.377l-7.025-2.555a1.21 1.21 0 00-.328-.922l-2.245-2.358v-3.959c.237-.2.471-.4.7-.609a14.147 14.147 0 004.456-10.283V19.95a13.945 13.945 0 001.289-5.859V1.205A1.206 1.206 0 0037.286 0H19.245A11.528 11.528 0 007.731 11.514v2.578A13.921 13.921 0 009.02 19.95v2.72a14.73 14.73 0 005.154 11.239v4.053l-2.245 2.358a1.2 1.2 0 00-.328.922l-7.416 2.7a6.28 6.28 0 00-1.489.781 7.926 7.926 0 00-1.761 1.814A6.38 6.38 0 000 49.918v9.276a1.205 1.205 0 001.206 1.205 1.2 1.2 0 001.2-1.205v-9.276a3.961 3.961 0 012.6-3.711l7.818-2.842 3.254 4.882a2.485 2.485 0 001.829 1.1c.083.008.166.013.249.013a2.483 2.483 0 001.76-.73l1.985-1.986v12.554a1.205 1.205 0 001.205 1.205 1.2 1.2 0 001.205-1.205V46.644l1.986 1.986a2.483 2.483 0 001.761.73 2.218 2.218 0 00.248-.013 2.486 2.486 0 001.83-1.1l3.254-4.882 7.817 2.842a3.964 3.964 0 012.6 3.712v9.275a1.2 1.2 0 001.2 1.205 1.205 1.205 0 001.206-1.205v-9.275a6.318 6.318 0 00-.457-2.378zM11.43 22.671V19.68a1.205 1.205 0 00-.12-.524 11.554 11.554 0 01-1.169-5.064v-2.577a9.114 9.114 0 019.1-9.1h16.836v11.681a11.562 11.562 0 01-1.169 5.065 1.212 1.212 0 00-.12.524v3.431a11.567 11.567 0 01-3.695 8.524 11.979 11.979 0 01-.924.783l-.021.015a11.58 11.58 0 01-7.82 2.333A11.936 11.936 0 0111.43 22.67zm6.786 24.249a.075.075 0 01-.068.024.078.078 0 01-.062-.036l-3.747-5.623 1.206-1.266 5.685 3.886zm4.9-4.651l-6.526-4.461v-2.25a13.559 13.559 0 005.59 1.614c.317.021.633.03.948.03a13.96 13.96 0 006.515-1.6v2.2zm5.023 4.638a.079.079 0 01-.061.038.078.078 0 01-.068-.024l-3.016-3.016 5.686-3.887 1.207 1.267z'
      fill={color}
    />
  </svg>
)
