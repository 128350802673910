import { Link } from '@reach/router'
import { useTranslation } from 'react-i18next'

import { ProfileIcon } from 'assets/icons/profileIcon'
import { TemplateIcon } from 'assets/icons/templateIcon'
import { Items, Item } from '../styles'

export const ClientMenu = ({ isMobile = true }) => {
  const { t } = useTranslation()

  const isActive = ({ isCurrent }) => {
    return { className: isCurrent ? 'active' : null }
  }

  const sizeIcon = isMobile ? 30 : 40

  return (
    <Items isMobile={isMobile}>
      <Item>
        <Link to='/client/profile' getProps={isActive}>
          <span>
            <ProfileIcon size={sizeIcon} />
          </span>
          {t('Perfil')}
        </Link>
      </Item>
      <Item>
        <Link to='/client/template' getProps={isActive}>
          <span>
            <TemplateIcon size={sizeIcon} />
          </span>
          {t('Planillas')}
        </Link>
      </Item>
    </Items>
  )
}
