import React from 'react'

import ReactImageFallback from 'react-image-fallback'

import { NoImage } from 'icons/noImage'

export const ImageFallback = ({ src = '', alt = '', className = '', fallbackImage, onClick = () => {}, sizeIcon = 90 }) => {
  return (
    <ReactImageFallback
      src={src}
      fallbackImage={fallbackImage || <NoImage size={sizeIcon} className={className} />}
      initialImage={fallbackImage || <NoImage size={sizeIcon} className={className} />}
      alt={alt}
      className={className}
      onClick={onClick}
    />
  )
}
