export const NoImage = ({ size = 512, ...props }) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox='0 0 510 510'
      {...props}
    >
      <linearGradient
        id='no-image__c'
        gradientTransform='rotate(6.28 255.57 -277.231)'
        gradientUnits='userSpaceOnUse'
        x1={172.48}
        x2={497.848}
        y1={110.639}
        y2={436.007}
      >
        <stop offset={0} stopColor='#ffa936' />
        <stop offset={0.411} stopColor='#ff8548' />
        <stop offset={0.778} stopColor='#ff6c54' />
        <stop offset={1} stopColor='#ff6359' />
      </linearGradient>
      <linearGradient
        id='no-image__d'
        gradientUnits='userSpaceOnUse'
        x1={490.487}
        x2={466.43}
        y1={159.015}
        y2={164.322}
      >
        <stop offset={0} stopColor='#f82814' stopOpacity={0} />
        <stop offset={1} stopColor='#c0272d' />
      </linearGradient>
      <linearGradient id='no-image__a'>
        <stop offset={0} stopColor='#cdec7a' />
        <stop offset={0.216} stopColor='#b0e995' />
        <stop offset={0.561} stopColor='#87e4bb' />
        <stop offset={0.835} stopColor='#6ee1d2' />
        <stop offset={1} stopColor='#65e0db' />
      </linearGradient>
      <linearGradient
        id='no-image__e'
        gradientTransform='rotate(-10.66 254.843 -276.812)'
        gradientUnits='userSpaceOnUse'
        x1={15.52}
        x2={340.888}
        xlinkHref='#no-image__a'
        y1={104.705}
        y2={430.073}
      />
      <linearGradient id='no-image__b'>
        <stop offset={0} stopColor='#cdec7a' stopOpacity={0} />
        <stop offset={0.235} stopColor='#9ad57d' stopOpacity={0.235} />
        <stop offset={0.604} stopColor='#51b482' stopOpacity={0.604} />
        <stop offset={0.868} stopColor='#239f85' stopOpacity={0.868} />
        <stop offset={1} stopColor='#119786' />
      </linearGradient>
      <linearGradient
        id='no-image__f'
        gradientUnits='userSpaceOnUse'
        x1={491.682}
        x2={450.637}
        xlinkHref='#no-image__b'
        y1={256.546}
        y2={256.546}
      />
      <linearGradient
        id='no-image__g'
        gradientUnits='userSpaceOnUse'
        x1={176.731}
        x2={176.731}
        xlinkHref='#no-image__b'
        y1={466.917}
        y2={442.601}
      />
      <linearGradient
        id='no-image__h'
        gradientUnits='userSpaceOnUse'
        x1={88.264}
        x2={413.632}
        y1={111.753}
        y2={437.121}
      >
        <stop offset={0} stopColor='#f8f6fb' />
        <stop offset={1} stopColor='#efdcfb' />
      </linearGradient>
      <linearGradient
        id='no-image__i'
        gradientUnits='userSpaceOnUse'
        x1={112.768}
        x2={430.112}
        y1={101.155}
        y2={514.021}
      >
        <stop offset={0} stopColor='#18cefb' />
        <stop offset={0.297} stopColor='#2bb9f9' />
        <stop offset={0.735} stopColor='#42a0f7' />
        <stop offset={1} stopColor='#4a97f6' />
      </linearGradient>
      <linearGradient
        id='no-image__j'
        gradientUnits='userSpaceOnUse'
        x1={75.588}
        x2={214.616}
        y1={316.53}
        y2={497.406}
      >
        <stop offset={0} stopColor='#cdec7a' />
        <stop offset={0.215} stopColor='#b0e995' stopOpacity={0.784} />
        <stop offset={0.56} stopColor='#87e4bb' stopOpacity={0.439} />
        <stop offset={0.833} stopColor='#6ee1d2' stopOpacity={0.165} />
        <stop offset={0.999} stopColor='#65e0db' stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id='no-image__k'
        gradientUnits='userSpaceOnUse'
        x1={198.822}
        x2={366.499}
        xlinkHref='#no-image__a'
        y1={288.474}
        y2={506.622}
      />
      <linearGradient
        id='no-image__l'
        gradientUnits='userSpaceOnUse'
        x1={117.242}
        x2={171.618}
        y1={131.922}
        y2={202.666}
      >
        <stop offset={0} stopColor='#ffd945' />
        <stop offset={0.304} stopColor='#ffcd3e' />
        <stop offset={0.856} stopColor='#ffad2b' />
        <stop offset={1} stopColor='#ffa325' />
      </linearGradient>
      <path
        d='M426.926 470.539L40.049 427.661C21.448 425.6 8.041 408.85 10.102 390.249L45.661 69.408c2.062-18.601 18.812-32.009 37.412-29.947L469.95 82.339c18.601 2.062 32.009 18.812 29.947 37.412l-35.559 320.841c-2.061 18.601-18.811 32.009-37.412 29.947z'
        fill='url(#no-image__c)'
      />
      <path
        d='M499.897 119.752l-14.02 126.534-31.162-165.634 15.241 1.688c18.595 2.058 32 18.806 29.941 37.412z'
        fill='url(#no-image__d)'
      />
      <path
        d='M482.373 410.94L99.837 482.904c-18.392 3.46-36.107-8.645-39.567-27.037L.59 138.626c-3.46-18.392 8.645-36.107 27.037-39.567l382.536-71.964c18.392-3.46 36.107 8.645 39.567 27.037l59.68 317.241c3.46 18.393-8.645 36.108-27.037 39.567z'
        fill='url(#no-image__e)'
      />
      <path
        d='M457.896 97.546v317.999l24.476-4.605c18.392-3.46 30.497-21.175 27.037-39.567z'
        fill='url(#no-image__f)'
      />
      <path
        d='M58.45 446.187l1.821 9.68c3.46 18.392 21.175 30.497 39.567 27.037l195.175-36.717z'
        fill='url(#no-image__g)'
      />
      <path
        d='M424.01 448.166H34.765C16.05 448.166.879 432.995.879 414.28V91.474c0-18.715 15.171-33.886 33.886-33.886H424.01c18.715 0 33.886 15.171 33.886 33.886V414.28c0 18.715-15.171 33.886-33.886 33.886z'
        fill='url(#no-image__h)'
      />
      <path
        d='M392.279 416.326H66.497c-15.663 0-28.361-12.698-28.361-28.361V117.79c0-15.663 12.698-28.361 28.361-28.361h325.782c15.663 0 28.361 12.698 28.361 28.361v270.175c0 15.663-12.698 28.361-28.361 28.361z'
        fill='url(#no-image__i)'
      />
      <path
        d='M252.069 416.326H66.502c-15.666 0-28.37-12.694-28.37-28.359v-44.29l47.082-57.228c15.538-18.903 44.46-18.903 60.009 0l29.315 35.64z'
        fill='url(#no-image__j)'
      />
      <path
        d='M420.643 316.75v71.217c0 15.666-12.704 28.359-28.37 28.359H97.005l77.532-94.237 95.246-115.783c15.538-18.892 44.471-18.892 60.009 0z'
        fill='url(#no-image__k)'
      />
      <circle cx={137.225} cy={157.919} fill='url(#no-image__l)' r={40.219} />
    </svg>
  )
}
