export const DeleteIcon = ({ size = 22, color = '#075592', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 17.3 21.924'
    height={size}
    width={size}
    {...props}
  >
    <path
      data-name='Trazado 153'
      d='M15.931 5.164H1.368V4.077c0-.086.038-.136.123-.136h14.317c.086 0 .123.05.123.136zm-1.282 15.278c0 .086-.037.123-.123.123H2.775c-.086 0-.123-.037-.123-.123V6.522h12zM5.783 2.582h5.735V1.359H5.783zm10.026 0h-2.923v-1.9a.68.68 0 00-.691-.679.1.1 0 00-.074.025.091.091 0 00-.049-.025H5.1a.669.669 0 00-.678.679v1.9H1.487A1.475 1.475 0 00-.004 4.077v2.445H1.29v13.92a1.455 1.455 0 001.48 1.483h11.751a1.465 1.465 0 001.492-1.483V6.522h1.282V4.077a1.475 1.475 0 00-1.486-1.496z'
      fill={color}
      fillRule='evenodd'
    />
  </svg>
)
