export const Reports = ({ size = 66.022, color = '#b2b2b2', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 66.022 67.287'
      {...props}
    >
      <path
        d='M65.79 4.579a.988.988 0 00-.674-.35L50.473 2.978 50.207.867A.993.993 0 0049.1.007L35.516 1.715a1.2 1.2 0 00-.165-.028L16.68.093a1 1 0 00-1.072.9l-.278 3.26L.867 6.071a1 1 0 00-.859 1.1L7.39 65.892a2.7 2.7 0 00.086.783 1 1 0 00.912.605h48.614a.991.991 0 00.991-.99v-1.291l1.918.164a1 1 0 001.073-.9l5.035-58.967a.983.983 0 00-.229-.717zm-58.393 45.5L2.097 7.92l5.3-.667zm10.1-47.923l8.672.741-8.83 1.109zm30.867-.052l.506 4.026H16.337zm10.728 61.013l-1.1-.094V34.07a.992.992 0 00-1.983 0v31.241H9.377v-57.2h46.632v21.335a.992.992 0 001.983 0V7.121a.991.991 0 00-.991-.991h-6.134l-.142-1.137L63.96 6.125z'
        fill={color}
      />
      <path
        d='M51.718 11.408h-5.284a.987.987 0 00-.823.442l-2.243 3.364a14.651 14.651 0 00-8.559-2.749.991.991 0 00-.992.991v13.739a.992.992 0 00.992.991h13.739a.991.991 0 00.991-.991 14.684 14.684 0 00-4.635-10.715l2.06-3.09h4.754a.991.991 0 100-1.982zm-4.2 14.8H35.799V14.489a12.677 12.677 0 016.467 2.38l-.884 1.326a.991.991 0 001.649 1.1l.757-1.134a12.707 12.707 0 013.73 8.042z'
        fill={color}
      />
      <path
        d='M51.717 44.169h-8.962l-1.579-2.526a14.685 14.685 0 004.134-10.221.99.99 0 00-.991-.991H31.572V17.68a.99.99 0 00-.991-.99 14.729 14.729 0 109.1 26.3l1.683 2.691a.991.991 0 00.839.465h9.512a.991.991 0 000-1.982zm-13.954-7.987a.991.991 0 10-1.681 1.05l2.544 4.071a12.746 12.746 0 11-9.036-22.59v12.71a.991.991 0 00.991.991h12.711a12.7 12.7 0 01-3.2 7.491zM22.128 49.448h-8.455a.992.992 0 000 1.983h8.455a.992.992 0 000-1.983zm12.681 0h-8.455a.992.992 0 000 1.983h8.455a.992.992 0 000-1.983zm-7.398 5.29H13.673a.99.99 0 100 1.981h13.738a.99.99 0 100-1.981zm7.398 0h-3.171a.99.99 0 100 1.981h3.171a.99.99 0 000-1.981zm-16.912 5.283H13.67a.991.991 0 100 1.982h4.227a.991.991 0 000-1.982zm16.912 0H22.127a.991.991 0 100 1.982h12.682a.991.991 0 000-1.982z'
        fill={color}
      />
    </svg>
  )
}
