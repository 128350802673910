import { useTranslation } from 'react-i18next'

import { FormAgent } from './form'
import { ContainerForm } from '../styles'

export const CompleteForm = () => {
  const { t } = useTranslation()

  return (
    <ContainerForm>
      <FormAgent title={t('Completa tu perfil')} />
    </ContainerForm>
  )
}
