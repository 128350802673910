export const KEY_USER_DATA = 'APP_INSURANCE_USER_DATA'

export const initUserReducer = (init) => {
  return JSON.parse(init) || {}
}

export const userDataReducer = (state, action) => {
  switch (action.type) {
    case 'set': {
      const payload = { ...action.payload }
      window.sessionStorage.setItem(KEY_USER_DATA, JSON.stringify(payload))
      return payload
    }
    case 'update': {
      const payload = { access_token: state.access_token, ...action.payload }
      window.sessionStorage.setItem(KEY_USER_DATA, JSON.stringify(payload))
      return payload
    }
    case 'remove':
      window.sessionStorage.removeItem(KEY_USER_DATA)
      return {}
    default:
      throw new Error()
  }
}
