import styled from 'styled-components'
import { RoundBotton } from 'common/buttons'
import { Colors, Device, FontWeight, MinQueriesSize } from 'styles/variables'
import { ErrorContainer as ErrorContainerBase, SuccessContainer as SuccessContainerBase } from 'formStyle'
import { ConfigureFont, FlexCenter } from 'assets/styles/general'

// Contenedor del formulario en el componente CompleteForm
export const ContainerForm = styled.div`
  margin: auto;
  width: 90%;
  margin: 0 auto;
  background-color: white;
  box-shadow: 0 0 2px rgba(0,0,0,.9);
  padding: 2rem;
  border-radius: 25px;

  @media ${MinQueriesSize(Device.laptop)} {
    width: 70%;
  }
`

export const ErrorContainer = styled(ErrorContainerBase)`
  grid-column: 1 / 3;
  text-align: center;
  margin-bottom: 1rem;
`

export const SuccessContainer = styled(SuccessContainerBase)`
  grid-column: 1 / 3;
  text-align: center;
  margin-bottom: 1rem;
`

// Stuctura del formulario
export const TitleForm = styled.h1`
  text-align: center;
  color: ${Colors.blueDark};
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

export const Form = styled.form`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-auto-rows: auto;

  @media (max-width: ${Device.tableM}px) {
    display: block;
  }
`

export const InputFileContainer = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const DropzoneContainer = styled.div`
  grid-column: 1 / 3;
  margin: 1rem 0;
`

export const ButtonContainer = styled.div`
  grid-column: 1 / 3;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`

export const Divide = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  grid-column: 1 / 3;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background-color: ${Colors.textGrey};
    margin: auto 0;
    z-index: 0; 
  }

  span {
    display: block;
    padding: .3rem 1rem;
    background-color: white;
    position: relative;
    z-index: 1;
  }
`

export const ButtonAddLincence = styled.div`
  color: ${Colors.buttonLightBlueLabel};
  display: flex;
  width: 300px;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
`

export const ButtonAndIconAdd = styled(RoundBotton)`
  margin-left: 1rem;
  background-color: ${({ color }) => color || Colors.buttonLightBlueLabel};
  color: ${Colors.white};
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  font-size: 1.8rem;

  span {
    position: absolute;
    right: 4px;
    bottom: -1px;
    font-weight: bold;
  }
`

export const ContainerLicences = styled.div`
  grid-column: 1 / 3;
`

export const ContainerFormLicence = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: .5rem;

  .number {
    display: block;
    width: 40px;
  }

  .field-form {
    margin-bottom: 0;
  }
`

// Card agency
export const CardContainer = styled.div`
  padding: 2rem 1rem;
  border-radius: 7px;
  background: ${Colors.white};
  box-shadow: 0 0 1px rgba(0,0,0,.3);
  width: 100%;
`

export const Verified = styled.div`
  text-align: center;
  grid-column: 1 / 3;
  color: ${Colors.purple};
  font-weight: 500;
  margin-top: 1rem;
`

export const ItemInfoAgency = styled.div`
  display: grid;
  grid-template-columns: 65px 1fr;
  min-height: 40px;
`

export const InfoAgency = styled.div`
   span {
    font-size: .8rem;
  }
`

export const SquareDivider = styled.div`
  border: 1px solid ${Colors.textCellTable};
  border-radius: 18px;
  padding: 24px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    ${ConfigureFont('16px', FontWeight.fw400)};
    color: ${Colors.textCellTable};
    font-style: italic;
    margin: 16px auto;
    text-align: center;
  }
`

export const ContainerButton = styled.div`
  margin: 2rem 0;
`

export const GridClient = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 0px;
  grid-row-gap: 16px;
  padding: 8px;
  width: 100%;
  height: 100%;
`

export const RowImage = styled.div`
  ${FlexCenter};
  grid-area: 1 / 1 / 2 / 2;
  position: relative;
`

export const RowText = styled.div`
  ${FlexCenter};
  grid-area: 2 / 1 / 3 / 2;

  p {
    ${ConfigureFont('24px', FontWeight.fw400)};
    text-align: center;
  }
`

export const RowButton = styled.div`
  ${FlexCenter};
  grid-area: 3 / 1 / 4 / 2;

  &.multiple {
    button {
      &:first-child {
        margin-right: 1rem;
      }
      &:last-child {
        margin-left: 1rem;
      }
    }
  }
`

export const GridTools = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 16px 0;
  width: 100%;

  @media (min-width: ${Device.tableL + 1}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
  }
`

export const GridMail = styled.div`
  display: flex;
  flex-direction: column;
`

export const ColumnIcon = styled.div`
  ${FlexCenter};
`

export const ColumnInfo = styled.div`
  ${FlexCenter};
  flex-direction: column;

  p {
    ${ConfigureFont('24px', FontWeight.fw400)};
    margin-bottom: 16px;
    text-align: center;
  }
`

export const ContainerClientes = styled.div`
  padding: 8px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

export const RowInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const RowLabel = styled.div`
  ${FlexCenter};
  padding: 16px 0;
`

/**
 * Requests
 */

export const BadgeRequest = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  ${FlexCenter};
  border: none;
  outline: none;
  background-color: #E91858;

  position: absolute;
  top: 0;
  left: 60%;
  z-index: 2;

  ${ConfigureFont('30px', FontWeight.fw600)};
  color: ${Colors.white};
`
