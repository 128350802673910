export const EditIcon = ({ size = 26, color = '#075592', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 23.76 25.606'
    {...props}
  >
    <g data-name='Grupo 110'>
      <path
        data-name='Trazado 141'
        d='M3.071 22.937a.773.773 0 01-.25-.73l1.265-6.638L16.646 1.12A1.484 1.484 0 0118.74.974l3.903 3.393a1.484 1.484 0 01.146 2.091l-12.56 14.45-6.4 2.178a.771.771 0 01-.757-.149zm.327-1.117l-.003.004zm.732.636l-.004.003zm1.196-6.282l-1.019 5.341 5.147-1.753L21.78 5.582a.148.148 0 00-.014-.21L17.863 1.98a.149.149 0 00-.21.015z'
        fill={color}
      />
    </g>
  </svg>
)
