import { Colors } from 'assets/styles/variables'
import { ContainerDots } from './styles'

export const DotsSpinner = ({ size = '40px', color = Colors.white }) => {
  return (
    <ContainerDots className='spinner' size={size} color={color}>
      <div className='dot1' />
      <div className='dot2' />
    </ContainerDots>
  )
}
