import styled from 'styled-components'

import { ConfigureFont, FlexCenter, resetButton, ScrollStyle } from 'assets/styles/general'
import { Colors } from 'assets/styles/variables'

export const ContainerCard = styled.div`
  display: grid;
  grid-template-columns: 18rem 1fr 3.25rem;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 16px;
  padding: 1rem 0;

  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      width: 90%;
      height: 1px;
      background-color: #d9d9d9;
      transform: translateX(-50%);
    }
  }


  .grid-request__cell--1 { grid-area: 1 / 1 / 3 / 2; }
  .grid-request__cell--2 { grid-area: 1 / 2 / 2 / 3; }
  .grid-request__cell--3 { grid-area: 2 / 2 / 3 / 3; }
  .grid-request__cell--4 { grid-area: 1 / 3 / 2 / 4; }
  .grid-request__cell--5 { grid-area: 2 / 3 / 3 / 4; }
  .grid-request__cell--6 { 
    grid-area: 1 / 3 / 3 / 4;
    ${FlexCenter}
  }
`

export const HeaderRequests = styled.div`
  ${FlexCenter};
  margin-bottom: 1.5rem;

  h2 {
    ${ConfigureFont('32px', 600)};
    color: #393939;
  }
`

export const ListRequests = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;

  overflow-x: hidden;
  overflow-y: auto;
  max-height: 20rem;
  flex: 1 0 auto;

  ${ScrollStyle(0, 0)}
`

export const ButtonAction = styled.button`
  ${resetButton};
  ${FlexCenter};
  height: 3.25rem;
  width: 3.25rem;
  border-radius: 3.25rem;

  &.button-action {
    &--approve {
      background-color: ${Colors.purple};
    }

    &--reject {
      background-color: ${Colors.danger};
    }

    &--link {
      background-color: ${Colors.buttonMediumBlue};
    }
  }
`

export const ItemContact = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 1rem;
  grid-row-gap: .5rem;

  .icon, .label, .value {
    display: flex;
    align-items: center;
  }

  .icon { grid-area: 1 / 1 / 3 / 2; }
  .label {
    grid-area: 1 / 2 / 2 / 3;

    p {
      ${ConfigureFont('18px', 400)};
      color: #777777;
    }
  }
  .value {
    grid-area: 2 / 2 / 3 / 3;

    p {
      ${ConfigureFont('18px', 400)};
      color: #393939;
    }
  }
`

export const Name = styled.p`
  ${ConfigureFont('32px', 600)};
  color: #393939;
  margin: .25rem 0;
`

export const IsVerified = styled.p`
  ${ConfigureFont('13px', 400)};
  color: ${Colors.purple};
  display: flex;
  align-items: center;
`
