export const ClientsImage = ({ size = 153, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 151.924 152.542'
    {...props}
  >
    <path
      d='M98.231 73.301h-13.4a3.7 3.7 0 01-3.684-3.686l-1.843-31.238S76.321 12.503 91.788 9.104c0 0 6.292-12.238 26.435-8.319a12.637 12.637 0 011.264.382c28.781 9.377 20.475 39.9 19.283 68.447a3.7 3.7 0 01-3.686 3.686h-14.249'
      fill='#7f7369'
    />
    <path
      d='M100.559 73.301v-4.383a22.225 22.225 0 01-3.287-1.766 23.71 23.71 0 01-1.532-1.078c-10.8-8.284-8.866-20.231-9.091-21.807v-9.55a2.086 2.086 0 01.011-.238c-.138-7.36 1-22.8 12.707-25.375 0 0 3.855-7.5 15.19-8.876-17.306-1.9-22.731 8.849-22.763 8.876-10.411 2.29-12.949 14.984-12.7 25.633l.012.433c.061 1.969.2 3.207.2 3.207l1.844 31.238a3.7 3.7 0 003.684 3.686z'
      fill='#7f7369'
    />
    <path
      d='M67.677 143.285a15.337 15.337 0 01.058-1.655c.3-2.68 4.071-36.4 4.3-38.474 1.052-7.358.447-11.7 3.926-15.922a15.916 15.916 0 00-3.591-3.115l-.11-.027c-3.73-2.235-6.872-2.328-13.8-4.235l-7.088-3.393v-8.469a23.228 23.228 0 01-18.488.049l-.028 8.453-7.058 3.359c-6.89 1.894-10.067 2-13.807 4.235l-.109.027c-4.337 2.645-6.039 6.264-6.62 11.023-.256 2.29-2 17.851-2.361 21.115-2.99 27.13-2.9 24.948-2.9 27.038-.047 3.444 2.065 8.642 8.484 8.642h67.478a8.164 8.164 0 01-8.286-8.651z'
      fill='#d38d54'
    />
    <g fill='#d38d54'>
      <path d='M7.647 143.285c-.031-1.79.009-.8 2.9-27.027 2.549-25.658 1.878-28.083 5.366-32.352a13.4 13.4 0 012.207-2.138 20.574 20.574 0 00-6.123 2.324l-.111.026c-4.328 2.642-6.038 6.256-6.62 11.025l-2.361 21.114c-2.9 26.329-2.93 25.243-2.9 27.027a8.167 8.167 0 008.484 8.653h7.641a8.171 8.171 0 01-8.483-8.652zM40.503 83.729V69.88a22.59 22.59 0 01-7.615-1.837l-.031 8.453z' />
    </g>
    <path
      d='M151.863 141.63c-5.4-47.8-5.092-46.452-5.575-48.787a13.258 13.258 0 00-6.246-8.724l-.112-.027c-3.926-2.353-6.783-2.256-20.036-5.787a2.008 2.008 0 01-.856-1.841v-7.507a23.239 23.239 0 01-18.484-.039v7.546a2.008 2.008 0 01-.856 1.841c-13.165 3.506-16.094 3.425-20.037 5.787l-.11.027c-4.357 2.66-6.04 6.266-6.621 11.023-5.45 49.147-5.291 46.333-5.26 48.142v.011c-.047 3.444 2.067 8.642 8.484 8.642h67.284c5.785.001 8.951-4.296 8.425-10.307z'
      fill='#eab883'
    />
    <path
      d='M75.318 143.296v-.011a16.346 16.346 0 01.058-1.655c5.389-50.462 5.056-49.022 5.577-51.539a13.215 13.215 0 016.115-8.641 24.831 24.831 0 00-7.4 2.642l-.11.027c-4.357 2.66-6.04 6.267-6.619 11.023-5.452 49.147-5.291 46.333-5.26 48.142v.011c-.049 3.444 2.064 8.642 8.482 8.642h7.644c-6.42 0-8.531-5.2-8.487-8.641z'
      fill='#d8a373'
    />
    <g fill='#d83d3d'>
      <path d='M149.031 116.25c-2.487-22.067-2.348-21.529-2.735-23.408a13.275 13.275 0 00-6.246-8.724 1.431 1.431 0 00-.112-.026c-3.926-2.353-6.783-2.257-20.036-5.788a2.008 2.008 0 01-.856-1.841V68.917a23.034 23.034 0 01-18.484 0V76.463a2.008 2.008 0 01-.856 1.841c-13.165 3.507-16.094 3.426-20.037 5.788-.032.006-.141.034-.11.026-4.359 2.66-6.051 6.366-6.621 11.025l-2.362 21.107 14.889 1.563a1.5 1.5 0 011.344 1.566l-1.637 32.558h49.356l-1.715-32.433a1.627 1.627 0 011.455-1.7z' />
      <path d='M85.463 117.813l-14.877-1.561c-3.048 27.2-2.933 25.536-2.909 27.031v.011c-.047 3.444 2.067 8.642 8.484 8.642h9.01l1.637-32.558a1.5 1.5 0 00-1.345-1.565z' />
    </g>
    <path
      d='M72.936 95.145l-2.364 21.107 7.4.779c2.632-24.391 2.473-24.5 2.979-26.938a13.217 13.217 0 016.114-8.641 25.707 25.707 0 00-7.4 2.643c-.032.006-.141.035-.11.026-4.333 2.644-6.037 6.257-6.619 11.024z'
      fill='#d83d3d'
    />
    <g fill='#70bbef'>
      <path d='M72.321 84.119c-.034-.008-.141-.035-.112-.026-3.739-2.242-6.928-2.346-13.755-4.224l.007-.012-7.088-3.393-.052.077v-.029l-9.247 8.705-9.218-8.72-7.057 3.359c-6.89 1.894-10.068 2-13.807 4.236l-.109.026c-4.357 2.66-6.038 6.266-6.62 11.025l-2.361 21.114 14.6 1.531a1.747 1.747 0 011.558 1.823l-1.626 32.326h49.358l-1.715-32.428a1.629 1.629 0 011.461-1.708l3.9-.411 1.595-14.234c1.059-7.418.449-11.664 3.9-15.886a16.091 16.091 0 00-3.612-3.151zM74.532 89.385l.068-.126-.068.126z' />
      <path d='M74.396 89.651c-1.737 3.532-1.5 7.467-2.356 13.5.854-5.969.617-9.955 2.356-13.5zM72.04 103.156l-.03.258c.014-.11.023-.2.03-.258zM67.676 143.285a15.371 15.371 0 01.058-1.655l2.663-23.816h-3.923a1.629 1.629 0 00-1.393 1.7l1.715 32.427h9.169a8.164 8.164 0 01-8.289-8.656z' />
    </g>
    <path
      d='M10.544 116.258c2.549-25.658 1.878-28.083 5.366-32.352a13.352 13.352 0 012.207-2.138 20.557 20.557 0 00-6.123 2.324l-.111.026c-8.058 4.921-6.072 10.739-8.981 32.139l7.553.791z'
      fill='#70bbef'
    />
    <path
      d='M109.747 89.474a19.112 19.112 0 0016.388-9.617l-6.235-1.552a2.007 2.007 0 01-.854-1.841V68a23.027 23.027 0 01-18.486 0v8.464a2.008 2.008 0 01-.856 1.841l-6.327 1.575a19.108 19.108 0 0016.37 9.594z'
      fill='#eab883'
    />
    <path
      d='M13.1 36.953c.094-6.9-2.361-27.008 18.626-35.037a28.963 28.963 0 0132.541 8.445c9.028 10.75 6.262 21.931 6.778 26.589'
      fill='#4c4846'
    />
    <path
      d='M13.1 36.953c.094-6.9-2.361-27.008 18.626-35.037a28.963 28.963 0 0132.541 8.445c9.028 10.75 6.262 21.931 6.778 26.589'
      fill='#4c4846'
    />
    <path
      d='M19.423 23.374A28.985 28.985 0 0144.961.158a28.887 28.887 0 00-18.676 4.533C12.612 13.56 13.254 25.499 13.1 36.956h5.779c.046-7.838-.217-9.722.544-13.582z'
      fill='#4c4846'
    />
    <g fill='#dd9a5e'>
      <path d='M72.491 39.545c0 3.34-3.233 6.045-7.221 6.045V33.499c3.99.001 7.221 2.708 7.221 6.046zM11.657 39.545c0 3.34 3.232 6.045 7.221 6.045V33.499c-3.989.001-7.221 2.708-7.221 6.046z' />
    </g>
    <g fill='#d38d54'>
      <path d='M65.27 40.155v5.435c5.421 0 8.843-4.788 6.45-8.761a7.466 7.466 0 01-6.45 3.326zM12.429 36.829a5.228 5.228 0 00-.772 2.715c0 3.34 3.232 6.046 7.221 6.046v-5.435a7.463 7.463 0 01-6.449-3.326z' />
    </g>
    <path
      d='M59.92 24.958a3.3 3.3 0 00-3.826-3.157l-.566.086a89.864 89.864 0 01-27.471-.086 3.3 3.3 0 00-3.825 3.157 11.907 11.907 0 01-.419 2.867 9 9 0 01-2.766 4.423 7.617 7.617 0 01-2.169 1.359V47.66a23.138 23.138 0 008.634 18.054 23.21 23.21 0 0037.135-12.688c.847-3.584.623-5.04.623-19.418-2.67-1.002-5.237-4.087-5.35-8.65z'
      fill='#edae6b'
    />
    <path
      d='M26.593 52.88c-.277-2.291-.183-2.743-.194-16.491a3.3 3.3 0 011.147-2.519 8.686 8.686 0 001.516-1.316 9.629 9.629 0 001.4-1.99c.025-.051.155-.306.228-.465 1.816-3.921.188-5.91 2.425-7.653a92.555 92.555 0 01-5.056-.644 3.306 3.306 0 00-3.826 3.158 11.814 11.814 0 01-.417 2.866 9.024 9.024 0 01-2.766 4.425 7.6 7.6 0 01-2.169 1.357v14.054a23.512 23.512 0 006.232 15.819 22.877 22.877 0 002.4 2.234 23.086 23.086 0 008.862 4.433c-.048-.037-8.975-10.568-9.782-17.268z'
      fill='#edae6b'
    />
    <path
      d='M51.372 76.464l-9.3 8.753 5.017 5.058a2.262 2.262 0 003.387-.2l7.983-10.22z'
      fill='#dadada'
    />
    <g fill='#dadada'>
      <path d='M54.941 78.171l-3.569-1.707-9.3 8.752 3.321 3.348zM47.776 90.745a2.265 2.265 0 001.326.16z' />
    </g>
    <path
      d='M32.857 76.497l9.218 8.72-5.018 5.058a2.261 2.261 0 01-3.386-.2l-7.984-10.22z'
      fill='#dadada'
    />
    <path
      d='M35.578 85.184l3.175 3.379 3.321-3.348-9.218-8.719-3.779 1.77z'
      fill='#dadada'
    />
    <path
      d='M108.24 81.134V69.878a22.883 22.883 0 01-7.682-1.876v8.463a2.008 2.008 0 01-.856 1.841l-6.327 1.575a20.134 20.134 0 006.215 6.535l6.57-2.414s2.08-1.034 2.08-2.868z'
      fill='#eab883'
    />
    <path
      d='M130.709 40.071a2.449 2.449 0 012.334 2.443v1.753a40.218 40.218 0 01-.342 7.384 23.221 23.221 0 01-35.43 15.5 24.21 24.21 0 01-1.532-1.078c-10.823-8.3-8.864-20.206-9.091-21.807V34.716a2.879 2.879 0 012.3-2.812 18.6 18.6 0 009.669-5.56 2.117 2.117 0 013.174.1c3.366 4.086 12.466 12.857 28.918 13.627z'
      fill='#f7cf9c'
    />
    <path
      d='M101.299 64.273c-.058-.057-.109-.115-.167-.168a24.097 24.097 0 01-.5-.51c-.019-.024-.042-.046-.062-.07l-.011-.007c-.014-.019-.03-.033-.042-.049a15.339 15.339 0 01-.419-.463c-7.877-9.029-5.3-17.5-5.8-24.242v-8.955a18.036 18.036 0 01-5.345 2.1 2.879 2.879 0 00-2.3 2.812v9.55c.211 1.477-1.959 14.225 10.068 22.515.349.24.8.529.852.56a23.154 23.154 0 0016.1 3.193c-.227-.04-9.067-3.04-12.374-6.266z'
      fill='#f7cf9c'
    />
    <g fill='#b72c2c'>
      <path d='M130.603 80.97l-4.47-1.113a19.112 19.112 0 01-16.388 9.617s20.952 20.954 20.858-8.504z' />
      <path d='M88.987 80.97l4.474-1.113a19.1 19.1 0 0016.384 9.617s-20.95 20.954-20.858-8.504z' />
    </g>
    <g fill='#b72c2c'>
      <path d='M99.881 86.542c-3.7 1.968-7.956 2.907-10.685-.743 2.171 22.157 20.649 3.675 20.649 3.675a18.408 18.408 0 01-9.964-2.932z' />
      <path d='M119.714 86.542c3.7 1.968 7.956 2.907 10.685-.743-2.171 22.157-20.651 3.675-20.651 3.675a18.416 18.416 0 009.966-2.932z' />
    </g>
    <path
      d='M59.698 128.636a12.342 12.342 0 00-3.531 1.406l-.071.018c-3.031 1.85-4 4.331-4.364 7.264l-1.664 14.614h4.584l1.666-16.753a8.842 8.842 0 013.38-6.549z'
      fill='#7ebc7e'
    />
    <path
      d='M62.638 81.625c-11.681-13.1-33.351-4.7-33.351 12.7v5.876h38.19c-.373-3.021 1.642-11.444-4.839-18.576z'
      fill='#996123'
    />
    <path
      d='M50.258 75.326A19.093 19.093 0 0029.3 93.665c-.024.691-.008.465-.013 6.54h3.756v-5.876a19.094 19.094 0 0117.215-19.003z'
      fill='#8e551c'
    />
    <g fill='#efd0a3'>
      <path d='M69.765 102.407c0 2.82-2.729 5.1-6.1 5.1V97.299c3.371.004 6.1 2.289 6.1 5.108zM27 102.407c0 2.82 2.729 5.1 6.1 5.1V97.299c-3.373.004-6.1 2.289-6.1 5.108z' />
    </g>
    <path
      d='M74.38 152.542c-1.7-14.664-1.61-14.67-1.911-16.129a8.746 8.746 0 00-4.117-5.75l-.072-.018c-2.584-1.548-4.447-1.481-13.2-3.814-.966-.437-.407-2.317-.563-7.108a15.312 15.312 0 01-12.186.033v5.86a1.318 1.318 0 01-.563 1.215c-8.726 2.325-10.615 2.265-13.2 3.814l-.072.018c-2.875 1.756-3.98 4.137-4.362 7.266l-1.666 14.613z'
      fill='#efd0a3'
    />
    <path
      d='M74.38 152.543c-1.7-14.665-1.61-14.67-1.911-16.129a8.807 8.807 0 00-4.117-5.75l-.072-.018c-2.49-1.491-4.43-1.5-10.345-3.1a11.583 11.583 0 01-19.035 0c-5.964 1.619-7.862 1.616-10.342 3.1l-.072.018c-2.973 1.816-4 4.252-4.362 7.266l-1.666 14.613z'
      fill='#7ebc7e'
    />
    <path
      d='M44.109 131.694l3.564-.888c.987-.447.385-2.275.563-9.8a15.183 15.183 0 01-5.91-1.248v5.86a1.318 1.318 0 01-.563 1.215l-2.862.713a11.64 11.64 0 005.208 4.148z'
      fill='#efd0a3'
    />
    <path
      d='M62.896 96.197c-.237-.056-.42-.117-.451-.125-1.443-.464-3.936-1.793-5.933-5.623a1.065 1.065 0 00-1.905.033c-1.834 3.857-9.209 9.207-20.492 6.121a.8.8 0 00-1.018.78v9.253a15.29 15.29 0 0010.171 14.408 15.3 15.3 0 0020.405-14.408v-9.253a1.22 1.22 0 00-.777-1.186z'
      fill='#fce3bb'
    />
    <path
      d='M40.867 108.622c-.219-1.691-.092-2.288-.129-11.1a24.641 24.641 0 01-6.624-.918.8.8 0 00-1.018.782v9.253a15.3 15.3 0 0019.108 14.8 15.293 15.293 0 01-11.337-12.817z'
      fill='#fce3bb'
    />
    <g>
      <path
        d='M113.155 81.625c-11.682-13.1-33.351-4.7-33.351 12.7v5.876h38.19c-.369-3.021 1.644-11.444-4.839-18.576z'
        fill='#996123'
      />
      <path
        d='M100.775 75.326a19.092 19.092 0 00-20.957 18.339c-.025.691-.009.465-.014 6.54h3.754v-5.876a19.1 19.1 0 0117.217-19.003z'
        fill='#8e551c'
      />
      <g fill='#efd0a3'>
        <path d='M120.281 102.407c0 2.82-2.728 5.1-6.1 5.1V97.299c3.371.004 6.1 2.289 6.1 5.108zM77.51 102.407c0 2.82 2.731 5.1 6.1 5.1V97.299c-3.365.004-6.1 2.289-6.1 5.108z' />
      </g>
      <path
        d='M124.895 152.542c-1.7-14.664-1.609-14.67-1.912-16.129a8.739 8.739 0 00-4.115-5.75l-.072-.018c-2.584-1.548-4.447-1.481-13.2-3.814-.967-.437-.406-2.317-.566-7.108a15.307 15.307 0 01-12.183.033v5.86a1.322 1.322 0 01-.563 1.215c-8.726 2.325-10.617 2.265-13.2 3.814l-.071.018c-2.878 1.756-3.981 4.137-4.363 7.266l-1.666 14.613z'
        fill='#efd0a3'
      />
      <path
        d='M124.895 152.543c-1.7-14.665-1.609-14.67-1.912-16.129a8.792 8.792 0 00-4.115-5.75l-.072-.018c-2.488-1.491-4.43-1.5-10.345-3.1a11.583 11.583 0 01-19.035 0c-5.964 1.619-7.862 1.616-10.342 3.1l-.071.018c-2.975 1.816-4 4.252-4.363 7.266l-1.666 14.613z'
        fill='#7ebc7e'
      />
      <path
        d='M94.622 131.694l3.567-.888c.987-.447.385-2.275.564-9.8a15.185 15.185 0 01-5.911-1.248v5.86a1.322 1.322 0 01-.563 1.215l-2.862.713a11.634 11.634 0 005.205 4.148z'
        fill='#efd0a3'
      />
      <path
        d='M113.409 96.197c-.237-.056-.418-.117-.449-.125-1.443-.464-3.936-1.793-5.933-5.623a1.065 1.065 0 00-1.905.033c-1.835 3.857-9.209 9.207-20.492 6.121a.8.8 0 00-1.02.78v9.253a15.289 15.289 0 0010.173 14.408 15.3 15.3 0 0020.4-14.408v-9.253a1.217 1.217 0 00-.774-1.186z'
        fill='#fce3bb'
      />
      <path
        d='M91.381 108.622c-.219-1.691-.089-2.288-.127-11.1a24.661 24.661 0 01-6.626-.918.8.8 0 00-1.018.782v9.253a15.3 15.3 0 0019.108 14.8 15.3 15.3 0 01-11.337-12.817z'
        fill='#fce3bb'
      />
    </g>
  </svg>
)
