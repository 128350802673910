import styled, { css } from 'styled-components'
import arrowDown from 'icons/arrow-down.png'

import { FontFamily, FontWeight, Colors } from 'styles/variables'
import { fadeInBlur } from 'styles/animations'
import { Button } from 'common/buttons'

const stylesInput = css`
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Black};
  border-radius: 1px;
  font-family: ${FontFamily};
  font-weight: ${FontWeight.fw400};
  height: 32px;
  outline: none;
  padding: 6px 16px;
  position: relative;
  width: ${({ width }) => (!width ? '100%' : width)};  

  & {
    ::-webkit-input-placeholder {
      /* Edge */
      color: ${Colors.textGrey};
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${Colors.textGrey};
    }

    ::placeholder {
      color: ${Colors.textGrey};
    }
  }
`

const FieldWidthAnd = css`
  width: ${({ width }) => (!width ? '100%' : `calc(${width} - .5rem)`)};
  margin-right: .5rem;
`

export const Field = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
  ${FieldWidthAnd};
  ${({ isTextarea }) => isTextarea ? '' : 'max-height: 76px;'};

  .error-icon {
    ${fadeInBlur};
    position: absolute;
  }

  .input-error-icon {
    bottom: 25px;
    right: 8px;
  }

  .textarea-error-icon {
    bottom: 72px;
    right: 8px;
  }

  .select-error-icon {
    bottom: 25px;
    right: 27px;
  }

  .DayPickerInput {
    width: 100%;
     input {
       ${stylesInput};
       width: 100%;
     }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .select-search {
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }

  .select-search *,
  .select-search *::after,
  .select-search *::before {
    box-sizing: inherit;
  }

  /**
  * Value wrapper
  */
  .select-search__value {
    position: relative;
    z-index: 1;
  }

  .select-search__value::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: calc(50% - 9px);
    right: 19px;
    width: 11px;
    height: 11px;
  }

  /**
  * Input
  */
  .select-search__input {
    display: block;
    height: 32px;
    width: 100%;
    padding: 0 16px;
    background-color: ${Colors.White};
    border-radius: 1px;
    border: 1px solid ${Colors.Black};
    outline: none;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    line-height: 36px;
    -webkit-appearance: none;
  }

  .select-search__input::-webkit-search-decoration,
  .select-search__input::-webkit-search-cancel-button,
  .select-search__input::-webkit-search-results-button,
  .select-search__input::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  .select-search__input:not([readonly]):focus {
    cursor: initial;
  }

  /**
  * Options wrapper
  */
  .select-search__select {
    background: #fff;
    box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
  }

  /**
  * Options
  */
  .select-search__options {
    list-style: none;
  }

  /**
  * Option row
  */
  .select-search__row:not(:first-child) {
    border-top: 1px solid #eee;
  }

  /**
  * Option
  */
  .select-search__option,
  .select-search__not-found {
    display: block;
    height: 36px;
    width: 100%;
    padding: 0 16px;
    background: #fff;
    border: none;
    outline: none;
    font-size: 14px;
    text-align: left;
    cursor: pointer;
  }

  .select-search--multiple .select-search__option {
    height: 48px;
  }

  .select-search__option.is-selected {
    background: ${Colors.purple};
    color: #fff;
  }

  .select-search__option.is-highlighted,
  .select-search__option:not(.is-selected):hover {
    background: rgba(126, 53, 220, 0.1);
  }

  .select-search__option.is-highlighted.is-selected,
  .select-search__option.is-selected:hover {
    background: ${Colors.purple};
    color: #fff;
  }

  /**
  * Group
  */
  .select-search__group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
  }

  /**
  * States
  */
  .select-search.is-disabled {
    opacity: 0.5;
  }

  .select-search.is-loading .select-search__value::after {
    background-image: url(${arrowDown});
    background-repeat: no-repeat;
    background-position-x: ${({ width }) => (!width ? 'calc(100% - 8px)' : 'calc(' + width + ' - 8px)')};
    background-position-y: 12px;
    background-size: 14px;
  }

  .select-search:not(.is-disabled) .select-search__input {
    cursor: pointer;
  }

  /**
  * Modifiers
  */
  .select-search--multiple {
    border-radius: 3px;
    overflow: hidden;
  }

  .select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
    transform: rotate(45deg);
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    pointer-events: none;
  }

  .select-search--multiple .select-search__input {
    cursor: initial;
  }

  .select-search--multiple .select-search__input {
    border-radius: 3px 3px 0 0;
  }

  .select-search--multiple:not(.select-search--search) .select-search__input {
    cursor: default;
  }

  .select-search:not(.select-search--multiple) .select-search__select {
    position: absolute;
    z-index: 2;
    top: 44px;
    right: 0;
    left: 0;
    border-radius: 3px;
    overflow: auto;
    max-height: 360px;
  }

  .select-search--multiple .select-search__select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
    border-radius: 0 0 3px 3px;
  }

  .select-search__not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
  }
`

export const Label = styled.label`
  color: ${Colors.textGrey};
  font-family: ${FontFamily};
  font-size: 14px;
  font-weight: ${FontWeight.fw400};
  margin-bottom: 8px;
  margin-left: 12px;
  padding-left: ${({ required }) => required ? '1rem' : '0'};

  & .input-required {
    position: relative;

    &:before {
      content: '*';
      font-size: 1rem;
      position: absolute;
      top: 3px;
      left: -.7rem;
      margin: auto;
      color: #d60000;
      font-weight: bold;
    }
  }
`

export const InputComponent = styled.input`
  ${stylesInput};
`

export const TextareaComponent = styled.textarea`
  background-color: ${Colors.White};
  border: 1px solid ${Colors.Black};
  border-radius: 1px;
  font-family: ${FontFamily};
  font-weight: ${FontWeight.fw400};
  min-height: 120px;
  outline: none;
  padding: 6px 16px;
  position: relative;
  resize: none;
  width: ${({ width }) => (!width ? '100%' : width)};

  & {
    ::-webkit-input-placeholder {
      /* Edge */
      color: ${Colors.Color9};
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${Colors.Color9};
    }

    ::placeholder {
      color: ${Colors.Color9};
    }
  }
`

export const SelectComponent = styled.select`
  background-color: ${Colors.White};
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-position-x: ${({ width }) => (!width ? 'calc(100% - 8px)' : 'calc(' + width + ' - 8px)')};
  background-position-y: 12px;
  background-size: 14px;
  border: none;
  border-radius: 1px;
  height: 32px;
  font-family: ${FontFamily};
  font-weight: ${FontWeight.fw400};
  outline: none;
  padding: 6px 16px;
  padding-right: 24px;
  position: relative;
  width: ${({ width }) => (!width ? '100%' : width)};
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid ${Colors.Black};
`

export const Option = styled.option`
  font-family: ${FontFamily};
  font-size: 14px;
  font-weight: ${FontWeight.fw400};
  height: 20px;
  padding: 8px 4px;
  transition: all ease 0.35s;

  &:hover {
    background-color: ${Colors.Color1};
  }
`

export const ErrorMessage = styled.span`
  ${fadeInBlur};
  color: #de0009;
  font-family: ${FontFamily};
  font-size: 12px;
  font-weight: ${FontWeight.fw400};
  margin-top: 4px;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
`

export const InfoMessage = styled.span`
  ${fadeInBlur};
  font-family: ${FontFamily};
  font-size: 12px;
  font-weight: ${FontWeight.fw400};
  margin-top: 4px;
  text-transform: lowercase;
  color: ${Colors.textGrey};
  /* margin-left: .5rem; */

  &:first-letter {
    text-transform: uppercase;
  }
`

export const ErrorContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #ffc7c7;
  margin-bottom: 1rem;
  border-radius: 5px;
  color: #d60000;
`

export const SuccessContainer = styled.div`
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: #d1e7dd;
  border-color: #badbcc;
  color: #0f5132;
  margin-bottom: 1rem;
  border-radius: 5px;
`

export const FieldPhone = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  position: relative;
  ${FieldWidthAnd};

  .error-icon {
    ${fadeInBlur};
    position: absolute;
  }

  .input-error-icon {
    bottom: 25px;
    right: 8px;
  }

  & .react-tel-input {
    display: flex;
    /* flex-direction: row-reverse; */

    & .form-control, & .flag-dropdown {
      border: 1px solid ${Colors.Black};
      border-radius: unset;
    }

    & .form-control {
      width: calc(100% - 3rem);
      height: 32px;
      padding-left: .5rem !important;
      margin-left: 2.5rem;
    }

    & .flag-dropdown {
      left: 0;
      width: 2.5rem;
    }
  }

  & li {
    text-align: left;
  }

  input[type="tel"] {
    padding-left: 1rem !important;
  }
`

export const CardNumberMainContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;

  & label {
    color: #000;
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`

export const CardNumberContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 150px 70px;
  height: 35px;
  width: 100%;
  border: 1px solid black;
  padding: 0 1rem;

  & input {
    height: 100%;
    border: none;
    outline: none;
  }

  .input-card-number__cvc {
    text-align: center;
  }
`

export const ExpiryContainer = styled.div`
  display: grid;
  grid-template-columns: 45% 10% 45%;
  align-items: center;
  padding: 0 1rem;

  span {
    text-align: center;
    display: block;
  }

  & input {
    text-align: center;
  }
`

// Input File
export const InputFileBarContainerMain = styled.div`
  background-color: ${Colors.buttonLightBlueLabel};
  color: ${Colors.white};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ width }) => width || '100%'};
  position: relative;
  padding: .5rem;
  height: 29px;
  border-radius: 5px;
  font-size: .9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & input {
    width: calc(100% + 200px);
    height: 100%;
    position: absolute;
    left: -200px;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  svg {
    margin-right: .5rem;
  }
`

export const InputFileContainerMain = styled.div`
  width: 220px;
  margin: 0 auto;
  margin-bottom: 1.7rem;
  position: relative;
`

export const InputFileContainer = styled.div`
  width: 130px;
  height: 130px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ border }) => border && css`
    border: 1px solid rgba(0,0,0,.4);
  `}

  & input {
    width: calc(100% + 200px);
    height: 100%;
    position: absolute;
    left: -200px;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

export const ButtonInputFile = styled(Button)`
  background: ${Colors.blueLightMedium};
  color: ${Colors.blueMedium};
  height: 25px;
  width: 184px;
  font-size: .8rem;
  position: absolute;
  bottom: -10px;
  left: 0;  
  right: 0;
  margin: auto;
`

export const ButtonEdit = styled.div`
  position: absolute;
  top: 8px;
  right: 47px;
  width: 30px;
  height: 30px;
  background: ${Colors.purple};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RadioContent = styled.div`
  display: flex;
`

export const RadioButton = styled.label`
  position: relative;
  padding: 0 1rem 0 1.3rem;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;

  & .checkbox {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    height: 15px;
    width: 15px;
  }

  & .checkbox:before, & .checkbox:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    transition: ease opacity .3s;
  }

  & .checkbox:before {
    box-shadow: 0 0 2px #000;
    opacity: 1;
  }

  & .checkbox:after {
    opacity: 0;
    box-shadow: 0 0 2px #000;
    background-color: ${Colors.purple};
  }

  & input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  input:checked  ~ .checkbox:before {
    opacity: 0;
  }

  input:checked  ~ .checkbox:after {
    opacity: 1;
  }
`

export const DropZoneContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  position: relative;
  min-height: calc(210px + .5rem);
  max-height: calc(210px + .5rem);
  border-radius: 10px;
  border: 1px solid ${Colors.borderGrey};
  overflow: hidden;
  cursor: pointer;

  input {
    position: absolute;
    top: 0;
    left: -200px;
    height: 100%;
    width: calc(100% + 200px);
    z-index: 10;
    outline: none;
    opacity: 0;
    cursor: pointer;
  }
`

export const DropZoneIcon = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 90px;
  height: 90px;
  opacity: .3;
`

export const FilesPreview = styled.ul`
  display: flex;
  width: max-content;
  padding: .5rem !important;
  position: relative;
  z-index: 1000;
`

export const FileItem = styled.li`
  width: 75px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid silver;
  margin-right: .5rem;
  background-color: white;
  cursor: pointer !important;
  position: relative;

  &:hover {
    & span {
      opacity: 1;
      transition: ease opacity .3s;
    }
  }

  & span {
    position: absolute;
    right: 4px;
    top: 4px;
    opacity: 0;
    transition: ease opacity .3s;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    background-color: rgba(0,0,0,.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
