export const PhoneIcon = ({ color = '#7e35dc', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={26.184}
    height={26.174}
    viewBox='0 0 26.184 26.174'
    {...props}
  >
    <path
      data-name='Trazado 113'
      d='M25.511 20.343l-4.338-4.338a2.3 2.3 0 00-3.254 0l-1.085 1.084a2.3 2.3 0 01-3.257 0l-4.542-4.482a2.3 2.3 0 010-3.253l1.085-1.085a2.3 2.3 0 000-3.254L5.782.677a2.3 2.3 0 00-3.254 0L1.443 1.762C.035 3.17-.379 6.273.363 9.862a21.044 21.044 0 005.626 10.485 20.386 20.386 0 0010.422 5.525c2.491.481 6.235.6 8.017-1.187l1.085-1.084a2.3 2.3 0 00-.002-3.258zM3.611 1.758a.767.767 0 011.085 0l4.339 4.338a.768.768 0 010 1.085l-.54.54-5.423-5.423zm13.089 22.6a18.827 18.827 0 01-9.628-5.1 19.493 19.493 0 01-5.209-9.711c-.58-2.807-.339-4.917.225-6.064l5.376 5.376a3.843 3.843 0 00.489 4.833l4.542 4.482a3.841 3.841 0 004.829.485l5.38 5.38c-1.638.833-4.188.673-6.003.323zm7.726-1.849l-.54.54-5.423-5.423.54-.54a.767.767 0 011.085 0l4.339 4.338a.768.768 0 010 1.089z'
      fill={color}
    />
  </svg>
)
