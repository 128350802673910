import { ErrorIcon } from 'icons/errorIcon'
import { Field, Label, InfoMessage, ErrorMessage, RadioContent, RadioButton } from './styles'

export const RadioContainer = ({ label = '', error = '', info = '', width = '100%', isRequired, children }) => {
  return (
    <Field className='field-form' width={width} key={label}>
      {label && (
        <Label>
          {/* {isRequired && <span className='input-required' />} */}
          {label}
        </Label>
      )}

      <RadioContent>{children}</RadioContent>

      {info && <InfoMessage>{info}</InfoMessage>}
      {error && <ErrorIcon size={20} className='error-icon input-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  )
}

export const Radio = ({ value = '', valueSelected = '', label = '', name = 'radio', onChange = () => {}, readOnly = false }) => {
  const handleChange = (event) => {
    onChange(event)
  }

  const checked = valueSelected === value

  const unic = Math.floor((Math.random() * 1000) + 1)

  return (
    <RadioButton className={`radio-button ${checked ? 'checked' : ''}`} key={label}>
      <input
        type='radio'
        name={`${name}-${unic}` || label}
        value={value}
        onChange={handleChange}
        readOnly={readOnly}
        checked={checked}
      />
      <span className='checkbox' />
      {label}
    </RadioButton>
  )
}
