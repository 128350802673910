import { useState, useContext } from 'react'
import get from 'lodash/get'
import { Context } from 'Context'

export const useRequest = (path, method = 'POST') => {
  const { userData } = useContext(Context)
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const OPTIONS = {
    headers: {
      Accept: 'application/json'
    }
    // mode: 'no-cors'
  }

  const init = () => {
    setResponse({})
  }

  const getQueryString = (params) => {
    const esc = encodeURIComponent
    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&')
  }

  const query = async ({ body = {}, isFormData = false, dynamicParams = '', fileResponse = false }) => {
    init()
    setLoading(true)
    let qs = ''
    const options = {
      ...OPTIONS,
      method
    }

    if (get(userData, 'access_token', null)) {
      options.headers.Authorization = `Bearer ${userData.access_token}`
    }

    if (method !== 'GET' && method !== 'DELETE' && !isFormData) {
      OPTIONS.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(body)
    }

    if ((method === 'GET' || method === 'DELETE') && !isFormData) {
      qs = `?${getQueryString(body)}`
    }

    if (isFormData) {
      options.body = body
    }

    const url = `${process.env.REACT_APP_URL_BACKEND_API}${path}${qs}${dynamicParams}`

    let data
    let status
    try {
      const request = await window.fetch(url, options)
      status = request.status
      if (fileResponse) {
        data = await request.blob()
      } else {
        data = await request.json()
      }
    } catch (error) {
      data = {}
    }
    return new Promise((resolve, reject) => {
      try {
        if (status === 200 || status === 201 || status === 204) {
          resolve(data)
          setResponse(data)
          setLoading(false)
        } else {
          const errorAndStatus = { ...data, status }
          reject(errorAndStatus)
          setError(data)
          setLoading(false)
        }
      } catch (error) {
        reject(error)
        setError(error)
        setLoading(false)
      }
    })
  }

  return [query, { response, error, loading, refetch: query }]
}
