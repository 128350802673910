import { useState, useEffect, useRef, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate } from '@reach/router'
import { MainContainer, Container, Title, Logo, ContainerCode } from './styles'
import { Button } from 'common/buttons'
import { Context } from 'Context'

import { useRequest } from 'hooks/useRequest'

export const VerificationCode = ({ code }) => {
  // hooks
  const { dispatchUser, dispatchAuth, userData } = useContext(Context)
  const { t } = useTranslation()
  // refs
  const firstLoad = useRef(true)
  // sattes
  const [loading, setLoading] = useState(true)
  const [verified, setVerified] = useState(false)
  const [invalid, setInvalid] = useState(false)

  const [verifyCode] = useRequest('verify')

  useEffect(() => {
    firstLoad.current = false

    verifyCode({
      body: {
        code
      }
    })
      .then(({ result, message, ...auth }) => {
        if (result) {
          setVerified(true)
          dispatchUser({ type: 'set', payload: auth })
          dispatchAuth({ type: 'login', payload: { auth: true } })

          setTimeout(() => {
            switch (auth.user.level) {
              case '2':
                navigate('/agency/dashboard')
                break
              case '3':
                navigate('/agent/dashboard')
                break
              case '4':
                navigate('/client/dashboard')
                break
              default:
                break
            }
          }, 5000)
        }
        if (message) {
          setInvalid(true)
        }

        setTimeout(() => {
          setLoading(loading => !loading)
        }, 1500)
      })
      .catch(err => {
        console.log('>>> Err', err)
      })
  }, [])

  const goTo = () => {
    navigate('/login')
  }

  const spliceCode = () => {
    if (typeof code === 'string') {
      return code.slice(-6)
    }
  }

  return (
    <MainContainer>
      <Logo />
      <Container>
        <Title>{t('Verificación de cuenta')}</Title>

        <ContainerCode>
          <span>{spliceCode()}</span>
        </ContainerCode>

        {
          loading || firstLoad.current
            ? <p>{t('Por favor, espera mientras verificamos tu código de seguridad.')}</p>
            : (
              <>
                {
                  verified && (
                    <p>{t('Su código se  ha verificado con exito.')}</p>
                  )
                }
                {
                  invalid && (
                    <p>{t('Lo sentimos el código es invalido o ya se ha activado tu cuenta.')}</p>
                  )
                }
              </>
              )

        }

        <Button
          disabled={loading || verified}
          width='200px'
          height='40px'
          onClick={goTo}
        >
          {
            verified
              ? `${t('Redirigiendo')}...`
              : loading
                ? t('Verificando')
                : t('Continuar')
          }
        </Button>
      </Container>
    </MainContainer>
  )
}
