export const Home = ({ size = 56.455, color = '#b2b2b2', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 56.456 56.455'
      {...props}
    >
      <g fill={color}>
        <path d='M42.754 8.009a1.1 1.1 0 001.1-1.1 1.1 1.1 0 00-1.1-1.1h-.006a1.1 1.1 0 00-1.1 1.1 1.106 1.106 0 001.106 1.1zm5.951 0a1.1 1.1 0 001.1-1.1 1.1 1.1 0 00-1.1-1.1h-.006a1.1 1.1 0 00-1.1 1.1 1.106 1.106 0 001.106 1.1zm-11.902 0a1.1 1.1 0 001.1-1.1 1.1 1.1 0 00-1.1-1.1h-.006a1.1 1.1 0 00-1.1 1.1 1.106 1.106 0 001.106 1.1zm-29.059 0h16.569a1.1 1.1 0 001.1-1.1 1.1 1.1 0 00-1.1-1.1H7.744a1.1 1.1 0 00-1.1 1.1 1.1 1.1 0 001.1 1.1z' />
        <path d='M52.918 0H3.537A3.541 3.541 0 000 3.537v40.829a3.542 3.542 0 003.537 3.537h8.556a19.729 19.729 0 002.357 2.843 19.35 19.35 0 0013.776 5.708 19.359 19.359 0 0013.778-5.707 19.511 19.511 0 002.35-2.843h8.563a3.542 3.542 0 003.538-3.537V3.538A3.541 3.541 0 0052.918.001zM3.537 2.205h49.381a1.334 1.334 0 011.332 1.332v8.074H2.207V3.537a1.334 1.334 0 011.33-1.332zm49.381 43.494h-7.27a19.523 19.523 0 00-3.642-22.506 19.3 19.3 0 00-11.259-5.544 19.613 19.613 0 00-12.085 2.344 1.1 1.1 0 00-.418 1.5 1.1 1.1 0 001.5.418 17.328 17.328 0 0120.7 2.838 17.3 17.3 0 010 24.436 17.167 17.167 0 01-12.219 5.061 17.16 17.16 0 01-12.217-5.061 17.33 17.33 0 01-2.847-20.689 1.1 1.1 0 00-.42-1.5 1.1 1.1 0 00-1.5.42 19.611 19.611 0 00-2.334 12.079 19.386 19.386 0 001.89 6.2h-7.26a1.334 1.334 0 01-1.332-1.332v-30.55H54.25v30.55a1.334 1.334 0 01-1.332 1.332z' />
        <path d='M15.524 37.829a1.1 1.1 0 001.559 0l.787-.787v9.972a1.1 1.1 0 001.1 1.1h18.513a1.1 1.1 0 001.1-1.1v-9.971l.787.787a1.1 1.1 0 00.78.323 1.1 1.1 0 00.78-.323 1.1 1.1 0 000-1.559L29.003 24.347a1.1 1.1 0 00-1.56 0L15.519 36.271a1.1 1.1 0 000 1.56zm14.367 8.082h-3.324v-4.655h3.324zm-1.664-19.226l8.155 8.153v11.073h-4.286v-5.758a1.1 1.1 0 00-1.1-1.1h-5.529a1.1 1.1 0 00-1.1 1.1v5.758h-4.286V34.837z' />
        <path d='M26.195 35.483h4.063a1.1 1.1 0 001.1-1.1 1.1 1.1 0 00-1.1-1.1h-4.063a1.1 1.1 0 00-1.1 1.1 1.1 1.1 0 001.1 1.1zM16.007 24.756a1.1 1.1 0 000-1.558 1.1 1.1 0 00-1.561 0 1.1 1.1 0 000 1.56 1.1 1.1 0 00.78.323 1.1 1.1 0 00.78-.323z' />
      </g>
    </svg>
  )
}
