import { useState } from 'react'
import { useTranslation } from 'react-i18next'

/**
 *
 * @param {string} name
 * @param {string} initValue
 * @param {string} validation
 * @param {void} callback
 * @returns
 */
export const useSelectValue = (name = '', initValue = '', validation = '', callback = () => {}) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(initValue)
  const [error, setError] = useState('')
  const [isRequired] = useState()
  // validation.includes('required')
  const onChange = (e) => {
    const v = (typeof e?.target !== 'undefined') ? e.target.value : e
    setValue(v)
    setError('')
    callback(v)
  }

  const onReset = () => {
    setValue('')
    setError('')
  }

  const validate = () => {
    if (validation && validation === 'required') {
      if (!value || value.length === 0) {
        setError(`${t('The')} ${name} ${t('field is required')}`)
        return true
      } else {
        setError('')
      }
    }
  }

  return { value, onChange, validate, error, onReset, isRequired }
}
