import { useTranslation } from 'react-i18next'
import { Modal } from './index'
import { ButtonContainer, CancelButton, OkButton } from './styles'

export const Alert = ({ cancelButtonText, okButtonText, cancelButton = null, okButton = null, children, padding, open = true, height = 'max-content', disabled }) => {
  const { t } = useTranslation()

  return (
    <Modal
      padding={padding}
      onClose={cancelButton}
      open={open}
      height={height}
      className='alert'
    >
      {children}
      <ButtonContainer>
        {cancelButton && (
          <CancelButton
            onClick={cancelButton}
            disabled={disabled}
          >
            {cancelButtonText || t('Cancelar')}
          </CancelButton>
        )}
        {okButton && (
          <OkButton
            onClick={okButton}
            disabled={disabled}
          >
            {okButtonText || t('Aceptar')}
          </OkButton>
        )}
      </ButtonContainer>
    </Modal>
  )
}
