export const ShieldIcon = ({ width = 58.516, height = 68.616, color = '#b2b2b2', ...props }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 58.516 68.616'
      {...props}
    >
      <g data-name='Grupo 514' fill={color}>
        <path
          data-name='Caminho 325'
          d='M57.454 11.849A114.156 114.156 0 0129.992.201a1.441 1.441 0 00-1.468 0A111.053 111.053 0 011.062 11.849 1.441 1.441 0 000 13.241v14.748a42.429 42.429 0 0012.883 31c6.328 6.2 13.7 9.629 16.375 9.629s10.047-3.429 16.374-9.629a42.426 42.426 0 0012.884-31V13.241a1.442 1.442 0 00-1.062-1.392zm-1.825 16.139a39.6 39.6 0 01-12.017 28.937c-6.222 6.1-12.875 8.8-14.354 8.8s-8.132-2.707-14.354-8.8A39.6 39.6 0 012.886 27.988V14.336a113.668 113.668 0 0026.372-11.22 117.315 117.315 0 0026.371 11.22z'
        />
        <path
          data-name='Caminho 326'
          d='M16.464 14.175a1.443 1.443 0 00-1.872-.814 117.056 117.056 0 01-7.866 2.779 1.444 1.444 0 00-1.011 1.378v5.573a1.443 1.443 0 102.886 0v-4.519c2.37-.766 4.736-1.614 7.049-2.525a1.442 1.442 0 00.814-1.872z'
        />
        <path
          data-name='Caminho 327'
          d='M19.206 14.449a1.434 1.434 0 00.579-.122l.026-.011a1.443 1.443 0 10-1.165-2.641l-.023.01a1.443 1.443 0 00.583 2.764z'
        />
        <path
          data-name='Caminho 328'
          d='M46.455 46.541a1.444 1.444 0 00-2 .419 35.141 35.141 0 01-4.148 5.181 36.817 36.817 0 01-4.14 3.683 1.445 1.445 0 101.75 2.3 39.705 39.705 0 004.465-3.973 38 38 0 004.49-5.608 1.444 1.444 0 00-.417-2.002z'
        />
        <path
          data-name='Caminho 329'
          d='M32.817 58.088l-.083.049a1.444 1.444 0 001.446 2.5l.1-.056a1.443 1.443 0 00-1.459-2.491z'
        />
        <path
          data-name='Caminho 330'
          d='M19.419 31.362A4.693 4.693 0 0012.782 38l8.523 8.523a4.693 4.693 0 006.637 0l17.792-17.792a4.693 4.693 0 00-6.638-6.637L24.623 36.567zm21.719-7.228a1.807 1.807 0 012.555 2.555L25.901 44.481a1.807 1.807 0 01-2.555 0l-8.523-8.522a1.806 1.806 0 011.278-3.084 1.789 1.789 0 011.277.529l6.225 6.224a1.443 1.443 0 002.041 0z'
        />
      </g>
    </svg>
  )
}
