import styled from 'styled-components'

import { Colors, Device, FontFamily, FontWeight } from 'assets/styles/variables'
import { ConfigureFont, FlexCenter } from 'assets/styles/general'

export const Main = styled.div`
  background-color: ${Colors.white};
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  height: ${({ max }) => max === 'true' ? 'max-content' : 'auto'};
  margin: 0;
  padding: 24px 16px;
  width: ${({ width }) => width || '100%'};
`

export const CardComponent = styled.div`
  background-color: ${Colors.white};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  height: ${({ max }) => max ? 'max-content' : 'auto'};
  margin: 0;
  padding: 24px 16px;
  width: ${({ width }) => width || '100%'};
`

/** Content card */

export const GridCard = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 8px;
  padding: 0 16px;
  width: 100%;
`

export const HeaderRow = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
`

export const BodyRow = styled.div`
  grid-area: 2 / 1 / 3 / 2;
`

export const TitleCard = styled.h2`
  font-family: ${FontFamily};
  font-size: 22px;
  font-weight: ${FontWeight.fw600};
`

export const SubtitleCard = styled.p`
  font-family: ${FontFamily};
  font-size: 14px;
  font-style: italic;
  font-weight: ${FontWeight.fw400};
`

/** Grid Card Agent */

export const GridAgent = styled.div`
  display: grid;
  grid-template-columns: .6fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  margin-bottom: 16px;
  padding: 0 16px;
  width: 100%;
`

export const GridAgencies = styled(GridAgent)`
  margin-bottom: 0;
`

export const FirstAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: ${({ flex }) => flex === 'center' ? 'center' : 'flex-end'};
`

export const SecondAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: flex-start;
`

export const ThirdtAgentRow = styled.div`
  align-items: center;
  display: flex;
  grid-area: 2 / 1 / 3 / 3;
  justify-content: center;
`

export const Quantity = styled.h1`
  color: ${Colors.textCellTable};
  font-family: ${FontFamily};
  font-size: 60px;
  font-weight: ${FontWeight.fw900};

  &.card-admin {
    width: 100%;
    text-align: center;
  }

  @media (min-width: ${Device.tableL}px) and (max-width: ${Device.desktop}px) {
    font-size: 32px;
  }
`

export const Label = styled.p`
  color: ${Colors.textHeadTable};
  font-family: ${FontFamily};
  font-size: 16px;
  font-style: italic;
  font-weight: ${FontWeight.fw400};
  text-align: center;
`

export const GridChange = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (min-width: ${Device.tableL}px) {
    grid-template-columns: .5fr 1fr;
    grid-template-rows: 1fr;
  }
`

export const ColumnIcon = styled.div`
  ${FlexCenter};

  svg {
    width: 80px;
    height: 80px;
  }
`

export const ColumnChange = styled.div`
  ${FlexCenter};
  flex-direction: column;

  p {
    ${ConfigureFont('20px', FontWeight.fw400)};
    margin-bottom: 16px;
    text-align: center;
  }
`

/**
 * Card Agncy
 */

export const CardContainer = styled.div`
  background-color: white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
`

export const SquareClients = styled.div`
  border: 1px solid #B2B2B2;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
`

export const SectionClients = styled.div`
  display: flex;
  padding: 1.5rem 2.75rem 1rem 2.75rem;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`

export const LabelQuantityClients = styled.p`
  ${ConfigureFont('18px', FontWeight.fw400, 'italic')};
  color: ${Colors.textHeadTable};
  margin: 0;
  padding: 0;
  text-align: center;
`

export const QuantityClients = styled.p`
  ${ConfigureFont('60px', FontWeight.fw900)};
  color: ${Colors.textCellTable};
  margin: 0;
  padding: 0;
  text-align: center;
`

export const ItemInfoAgency = styled.div`
  display: grid;
  grid-template-columns: 65px 1fr;
  min-height: 40px;
`

export const InfoAgency = styled.div`
   span {
    font-size: .8rem;
  }
`

export const Agency = styled.div`
  display: grid;
  grid-template-columns: 85px 1fr;
  align-items: center;
  margin-bottom: 1rem;
`

export const AgencyName = styled.div`
  span {
    font-size: .8rem;
    color: ${Colors.textGray};
  }

  p {
    font-size: 1.3rem;
    font-weight: bold;
  }
`

export const LogoContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0,0,0,.3);
  overflow: hidden;
  position: relative;

  svg, img {
    width: 100%;
    height: 100%;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
  }
`
