import styled from 'styled-components'
import { Colors, Device, MinQueriesSize } from 'styles/variables'

export const MainContainer = styled.div`
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, ${Colors.blueGradient} 100%);
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Logo = styled.span`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0,0,0,.6);

  @media ${MinQueriesSize(Device.tableM)} {
    right: unset;
    left: 1.5rem;
  }
`

export const Container = styled.div`
  width: 90%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  padding: 1rem 2rem;

  p {
    text-align: center;
  }

  button {
    margin-top: 1rem;
  }

  @media ${MinQueriesSize(Device.tableM)} {
    width: 400px;
  }
`

export const Title = styled.h1`
  text-align: center;
  font-size: 1.8rem;
  margin: .5rem 0;
  color: ${Colors.blueDark};
`

export const ContainerCode = styled.div`
  text-align: center;
  margin: 1rem 0;

  span {
    font-weight: bold;
    font-size: 1.3rem
  }
`
