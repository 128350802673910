import { ConfigureFont } from 'assets/styles/general'
import { Colors, FontWeight } from 'assets/styles/variables'
import styled from 'styled-components'

export const ContainerSnack = styled.div`
  position: fixed;
  bottom: 4rem;
  right: 2rem;
  background-color: ${Colors.blueMedium};
  padding: 8px 16px;
  min-height: 48px;
  min-width: 180px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all ease-in-out 0.25s;
  z-index: 999999999999;

  transform: translateX(100%) scale(0);

  &.active {
    transform: translateX(0) scale(1);
  }
`

export const TextSnack = styled.p`
  color: white;
  ${ConfigureFont('16px', FontWeight.fw500)};
`
