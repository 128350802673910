import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ImageFallback } from 'common/imageFallback'
import { MailIcon } from 'icons/mailIcon'
import { PhoneIcon } from 'icons/phoneIcon'
import { CardContainer, ItemInfoAgency, InfoAgency, SquareDivider, ErrorContainer, SuccessContainer, ContainerButton, Verified } from '../styles'
import { CancelButton } from 'common/modal/styles'
import { UnlinkIcon } from 'assets/icons/unlinkIcon'
import { Colors } from 'assets/styles/variables'
import { Alert } from 'common/modal/alert'
import { useRequest } from 'hooks/useRequest'
import { AlertContainer } from 'components/agentList/styles'
import { Button } from 'common/buttons'
import { SimpleSelect } from 'assets/styles/general'

import { Context } from 'Context'
import { NumberClients } from './numberClients'
import { Agency, AgencyName, LogoContainer } from 'common/cards/styles'

export const CardAgency = ({ agency, agent, handleEditProfile = () => { }, showEditProfileButton = false }) => {
  const { userData, dispatchUser } = useContext(Context)
  // States
  const [isOpenAlert, setIsOpenAlert] = useState(false)
  const [successAlert, setSuccessAlert] = useState('')
  const [errorAlert, setErrorAlert] = useState('')
  const [index, setIndex] = useState(0)
  const { t } = useTranslation()

  const [unlinkAgent, { loading: loadingUnlink }] = useRequest('agents/unlink')

  const handleCloseAlert = () => {
    setIsOpenAlert(false)
  }

  const handleActionAlert = () => {
    setErrorAlert('')
    setSuccessAlert('')
    unlinkAgent({
      body: {
        agency_id: agency[index]?.id,
        agent_id: agent.id
      }
    })
      .then(res => {
        setSuccessAlert(t('Te has desvinculado exitosamente'))
        const user = { ...userData }
        delete user.user?.agent?.agency
        dispatchUser({ type: 'update', payload: { user: user.user } })
        setTimeout(() => {
          handleCloseAlert()
        }, 2000)
      })
      .catch(err => {
        console.log('Err unlinkAgent >>>', err)
        setErrorAlert(t('Disulpe, ha ocurrido un error. Intentelo nuevamente'))
      })
  }

  const handleOpenUnlik = () => {
    setIsOpenAlert(true)
  }

  const getAgencyInfo = (key, defaultValue = '') => {
    if (Array.isArray(agency)) {
      return agency[index][key]
    }
    return defaultValue
  }

  const renderAgencyOption = () => {
    if (Array.isArray(agency)) {
      return agency.map((a, i) => (
        <option key={i} value={i}>{a.name}</option>
      ))
    }
  }

  return (
    <>
      <CardContainer>
        <Agency>
          <LogoContainer>
            <ImageFallback src={getAgencyInfo('image')} />
          </LogoContainer>
          <AgencyName>
            <span>{t('Agencia')}</span>
            {/* <p>{agency[index]?.name}</p> */}
            <SimpleSelect onChange={e => setIndex(e.target.value)}>
              {renderAgencyOption()}
            </SimpleSelect>
          </AgencyName>

          <Verified>{t('Agencia verificada')}</Verified>
        </Agency>

        <ItemInfoAgency>
          <PhoneIcon />
          <InfoAgency>
            <span>{t('Numero de Teléfono')}</span>
            <p>{getAgencyInfo('phone')}</p>
          </InfoAgency>
        </ItemInfoAgency>

        <ItemInfoAgency>
          <MailIcon />
          <InfoAgency>
            <span>{t('Correo Electrónico')}</span>
            <p>{getAgencyInfo('user', {}).email}</p>
          </InfoAgency>
        </ItemInfoAgency>

        {
          showEditProfileButton && (
            <ContainerButton>
              <Button
                type='button'
                height='40px'
                bgColor={Colors.purple}
                color={Colors.white}
                onClick={handleEditProfile}
              >
                {t('Editar Perfil')}
              </Button>
            </ContainerButton>
          )
        }

        {
          agency &&
            <SquareDivider>
              <UnlinkIcon color={Colors.textCellTable} size={38} />
              <p>{t('¿No quieres estar vinculado a esta agencia?')}</p>
              <CancelButton dark onClick={handleOpenUnlik}>{t('Desvincular')}</CancelButton>
            </SquareDivider>
        }

        <NumberClients />

      </CardContainer>

      <Alert
        open={isOpenAlert}
        cancelButton={handleCloseAlert}
        okButton={handleActionAlert}
        disabled={loadingUnlink}
        padding='3rem 1.5rem'
      >
        <AlertContainer>
          <UnlinkIcon color={Colors.purple} size={65} />
          <p>{t('¿Deseas desvincularte de esta agencia?')}</p>
        </AlertContainer>

        {
          errorAlert && (
            <ErrorContainer>{errorAlert}</ErrorContainer>
          )
        }
        {
          successAlert && (
            <SuccessContainer>{successAlert}</SuccessContainer>
          )
        }
      </Alert>
    </>
  )
}
