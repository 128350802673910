import styled from 'styled-components'

import { Colors, FontWeight } from 'styles/variables'
import { ConfigureFont, FlexCenter, resetButton } from 'assets/styles/general'

export const Button = styled.button`
  align-items: center;
  background-color: ${({ bgColor }) => (!bgColor ? Colors.purple : bgColor)};
  border: none;
  border-radius: ${({ square, radius }) => (square ? 0 : !radius ? '32px' : radius)};
  color: ${({ color }) => (!color ? Colors.white : color)};
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: ${({ light }) => (light ? FontWeight.fw400 : FontWeight.fw600)};
  height: ${({ height }) => (!height ? '32px' : height)};
  justify-content: center;
  outline: none;
  padding: ${({ padding }) => (!padding ? '4px 16px' : padding)};
  width: ${({ width }) => (!width ? '100%' : width)};
  margin: ${({ margin }) => (!margin ? 'unset' : margin)};

  &:disabled {
    pointer-events: none;
    background-color: ${Colors.textGrey};
  }
`

export const RoundBotton = styled.button`
  align-items: center;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  outline: none;
  overflow: hidden;
`

export const RoundButtonIcon = styled(RoundBotton)`
  background: transparent;
  display: inline-block;
  height: ${({ size }) => size || '40px'};
  width: ${({ size }) => size || '40px'};
`

export const ButtonAction = styled.button`
  ${resetButton};
  ${FlexCenter};
  ${ConfigureFont('12px', 400)};
  border-radius: 2px;
  border: .7px solid ${Colors.borderGrey};
  color: ${Colors.purple};
  height: 24px;
  align-self: center;
  padding: .25rem .5rem;
`
