export const TemplateIcon = ({ size = 63.343, color = '#b2b2b2', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={size}
    height={size}
    viewBox='0 0 48.621 63.343'
    {...props}
  >
    <g fill={color}>
      <path d='M47.693 56.297a.928.928 0 00.928-.928V11.444a.928.928 0 00-.928-.928h-2.784v-4.33a.928.928 0 00-.927-.928h-2.784V.928A.928.928 0 0040.27 0H.928A.928.928 0 000 .928v3.015a.928.928 0 00.928.928.928.928 0 00.928-.928V1.856h37.486v49.115H1.856V7.98a.927.927 0 00-.928-.928A.928.928 0 000 7.98v43.919a.928.928 0 00.928.928h2.784v4.33a.927.927 0 00.928.928h2.784v4.33a.928.928 0 00.928.928h39.342a.928.928 0 00.928-.928v-3.067a.928.928 0 00-.928-.928.928.928 0 00-.928.928v2.14H9.278v-3.4h4.23a.927.927 0 00.928-.928.928.928 0 00-.928-.928H5.566v-3.4h34.7a.928.928 0 00.928-.928V7.119h1.856v49.115H17.499a.928.928 0 00-.928.928.928.928 0 00.928.928h26.479a.927.927 0 00.927-.928V12.377h1.856v43a.928.928 0 00.928.928z' />
      <path d='M33.755 15.403V7.98a.928.928 0 00-.928-.928h-24.5a.928.928 0 00-.928.928v7.423a.928.928 0 00.928.928h24.5a.927.927 0 00.928-.928zm-1.856-.928H9.256V8.908h22.643v5.567zM6.907 38.971a.927.927 0 00.928.927h24.991a.928.928 0 00.928-.927.928.928 0 00-.928-.928H7.835a.928.928 0 00-.928.928zM7.835 43.857h24.991a.928.928 0 00.928-.928.928.928 0 00-.928-.928H7.835a.928.928 0 00-.928.928.927.927 0 00.928.928zM13.34 33.156a.928.928 0 00.928.928.928.928 0 00.928-.928 5.14 5.14 0 015.135-5.134 5.14 5.14 0 015.134 5.134.929.929 0 00.928.928.928.928 0 00.928-.928 7 7 0 00-3.936-6.286 4.631 4.631 0 001.585-3.488 4.645 4.645 0 00-4.639-4.639 4.644 4.644 0 00-4.64 4.639 4.631 4.631 0 001.585 3.488 7 7 0 00-3.936 6.286zm4.206-9.774a2.787 2.787 0 012.786-2.785 2.787 2.787 0 012.783 2.784 2.786 2.786 0 01-2.783 2.783 2.787 2.787 0 01-2.786-2.782z' />
    </g>
  </svg>
)
