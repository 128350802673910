import { useTranslation } from 'react-i18next'

import { Colors } from 'assets/styles/variables'
import { CheckIcon } from 'assets/icons/checkIcon'
import { ClearIcon } from 'assets/icons/clearIcon'
import { MailIcon } from 'assets/icons/mailIcon'
import { PhoneIcon } from 'assets/icons/phoneIcon'
import { Agency, AgencyName, LogoContainer } from 'common/cards/styles'
import { ImageFallback } from 'common/imageFallback'

import { ButtonAction, ContainerCard, IsVerified, ItemContact, Name } from '../styles/requests'
import { LinkIcon } from 'assets/icons/linkIcon'

export const RequestItem = ({ agency, id, handleAction = () => { }, isLink = false }) => {
  const { t } = useTranslation()
  return (
    <ContainerCard>
      <div className='grid-request__cell--1'>
        <Agency>
          <LogoContainer>
            <ImageFallback src={agency?.image} />
          </LogoContainer>
          <AgencyName>
            <span>{t('Agencia')}</span>
            <Name>{agency?.name}</Name>
            <IsVerified>
              <CheckIcon color={Colors.purple} size={14} />
              {t('Agencia verificada')}
            </IsVerified>
          </AgencyName>
        </Agency>
      </div>
      <div className='grid-request__cell--2'>
        <ItemContact>
          <div className='icon'>
            <PhoneIcon />
          </div>
          <div className='label'>
            <p>Número de Teléfono</p>
          </div>
          <div className='value'>
            <p>{agency?.phone}</p>
          </div>
        </ItemContact>
      </div>
      <div className='grid-request__cell--3'>
        <ItemContact>
          <div className='icon'>
            <MailIcon />
          </div>
          <div className='label'>
            <p>Correo Electrónico</p>
          </div>
          <div className='value'>
            <p>{agency?.user?.email}</p>
          </div>
        </ItemContact>
      </div>
      {
        isLink
          ? (
            <div className='grid-request__cell--6'>
              <ButtonAction className='button-action--link' onClick={() => handleAction(agency.id)}>
                <LinkIcon color='#fff' />
              </ButtonAction>
            </div>
            )
          : (
            <>
              <div className='grid-request__cell--4'>
                <ButtonAction className='button-action--approve' onClick={() => handleAction(id, '1')}>
                  <CheckIcon />
                </ButtonAction>
              </div>
              <div className='grid-request__cell--5'>
                <ButtonAction className='button-action--reject' onClick={() => handleAction(id, '3')}>
                  <ClearIcon />
                </ButtonAction>
              </div>
            </>
            )
      }
    </ContainerCard>
  )
}
