import { lazy, useContext } from 'react'
import { Router } from '@reach/router'

import get from 'lodash/get'

import AgentDashboardPage from 'pages/agentDashboard'
import VerificationCodePage from 'pages/verificationCode'

import { Context } from 'Context'

const AuthPage = lazy(() => import('pages/auth'))
const CompleteRegisterPage = lazy(() => import('pages/completeRegister'))
const DashboardPage = lazy(() => import('pages/dashboard'))

const AdminDashboard = lazy(() => import('pages/adminDashboard'))
const AgenciesList = lazy(() => import('pages/agenciesList'))
const CompaniesList = lazy(() => import('pages/companiesList'))
const Terms = lazy(() => import('pages/terms'))

const AgentList = lazy(() => import('pages/agents'))
const AgencyDashboard = lazy(() => import('pages/agencyDashboard'))
const NotFound = lazy(() => import('pages/notFoundPage'))

const ClientsPage = lazy(() => import('pages/clients'))
const TemplateAgentPage = lazy(() => import('pages/templateAgent'))
const TemplateReceivedPage = lazy(() => import('pages/templateReceived'))
const TemplateAgentAddPage = lazy(() => import('pages/templateAgentAdd'))
const TemplateAgentEditPage = lazy(() => import('pages/templateAgentEdit'))
const TemplateAgentViewPage = lazy(() => import('pages/templateAgentView'))
const TemplateAgentProcessPage = lazy(() => import('pages/templateAgentProcess'))

const ClientDashboardPage = lazy(() => import('pages/clientDashboard'))
const TemplateClientPage = lazy(() => import('pages/templateClient'))

const ReportsAgency = lazy(() => import('pages/reportsAgency'))
const ReportsAgent = lazy(() => import('pages/reportsAgent'))
const CommissionConfig = lazy(() => import('pages/commissionConfig'))

// const TemplateClientAddPage = lazy(() => import('pages/templateClientAdd'))

const ProfileClientPage = lazy(() => import('pages/profileClient'))

export const ProtectedRoutes = (props) => {
  const { auth, userData } = useContext(Context)
  // console.log(JSON.parse(auth))
  const renderRountes = (level = null) => {
    switch (level) {
      case '1':
        return (
          <>
            <AdminDashboard path='/admin/dashboard' />
            <AgenciesList path='/admin/agencies' />
            <CompaniesList path='/admin/companies' />
            <Terms path='/admin/terms' />
          </>
        )

      case '2':
        return (
          <>
            <AgencyDashboard path='/agency/dashboard' />
            <AgentList path='/agency/agents' />
            <ReportsAgency path='/agency/reports' />
            <CommissionConfig path='/agency/commissions' />
          </>
        )

      case '3':
        return (
          <>
            <AgentDashboardPage path='/agent/dashboard' />
            <ClientsPage path='/agent/clients' />
            <TemplateAgentPage path='/agent/templates' />
            <TemplateReceivedPage path='/agent/templates-by-clients' />
            <TemplateAgentAddPage path='/agent/template-add' />
            <TemplateAgentEditPage path='/agent/template-update' />
            <TemplateAgentViewPage path='/agent/template-view' />
            <TemplateAgentProcessPage path='/agent/template-process' />
            <ReportsAgent path='/agent/reports' />
          </>
        )

      case '4':
        return (
          <>
            <ClientDashboardPage path='/client/dashboard' />
            <TemplateClientPage path='/client/template' />
            <ProfileClientPage path='/client/profile' />
          </>
        )

      default:
        return (
          <NotFound default />
        )
    }
  }
  return (
    <>
      {
        // eslint-disable-next-line multiline-ternary
        JSON.parse(auth) ? (
          <Router>
            {renderRountes(get(userData, 'user.level', null))}
          </Router>
        ) : (
          <Router>
            <AuthPage path='/' />
            <AuthPage path='/login' />
            <AuthPage path='/register' />
            <CompleteRegisterPage path='/complete-register' />
            <DashboardPage path='/dashboard' />
            <VerificationCodePage path='/verify/:code' />
            <NotFound default />
          </Router>
        )
      }
    </>
  )
}
