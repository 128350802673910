export const SendEmail = ({ ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 197.159 190.899'
    {...props}
  >
    <g data-name='Grupo 73'>
      <g data-name='Grupo 48'>
        <path
          data-name='Trazado 114'
          d='M192.086 151.701L34.361 189.949a5.645 5.645 0 01-6.816-4.156L3.039 84.751a.3.3 0 01.219-.359l168.081-40.765a.3.3 0 01.359.219l24.514 101.08a5.607 5.607 0 01-4.126 6.775z'
          fill='#c386ef'
        />
        <path
          data-name='Trazado 115'
          d='M33.834 190.849a6.442 6.442 0 01-7.052-4.866L2.277 84.941a1.086 1.086 0 01.8-1.308l168.081-40.764a1.078 1.078 0 011.308.8l24.515 101.082a6.348 6.348 0 01-.754 4.833 6.356 6.356 0 01-3.951 2.885L34.55 190.722a6.472 6.472 0 01-.716.127zM3.918 85.049l24.391 100.569a4.852 4.852 0 005.867 3.578l157.725-38.253.185.764-.185-.764a4.793 4.793 0 002.98-2.175 4.785 4.785 0 00.568-3.646l-24.4-100.606z'
          fill='#243762'
        />
      </g>
      <path
        data-name='Trazado 116'
        d='M184.588 152.649l-142.4 36.316c-3.03.735 37.11-66.771 36.375-69.8L3.042 84.757a.3.3 0 01.219-.359l168.081-40.765a.3.3 0 01.359.219l-51.033 65.1c.728 3.009 66.929 42.966 63.92 43.697z'
        fill='#243762'
        opacity={0.29}
      />
      <g data-name='Grupo 49'>
        <path
          data-name='Trazado 117'
          d='M3.259 84.396l61.7-78.646a12.908 12.908 0 0115.528-3.791l90.389 41.457a.179.179 0 01-.033.337L2.974 84.465'
          fill='#c386ef'
        />
        <path
          data-name='Trazado 118'
          d='M3.156 85.23l-.275-1.131-.24-.188.148-.189v-.02l.024-.006L64.341 5.265a13.655 13.655 0 0116.472-4.02l90.39 41.458a.965.965 0 01.559.965.965.965 0 01-.729.847zM73.595 1.667a12.09 12.09 0 00-8.021 4.567l-60.3 76.864 163.681-39.7-88.8-40.729a12.115 12.115 0 00-6.558-1.002z'
          fill='#243762'
        />
      </g>
      <path
        data-name='Trazado 119'
        d='M3.259 84.397l61.7-78.646A12.908 12.908 0 0180.487 1.96l90.389 41.457a.179.179 0 01-.033.337L2.974 84.466'
        fill='#243762'
        opacity={0.29}
      />
      <g data-name='Grupo 50'>
        <path
          data-name='Trazado 120'
          d='M85.719 107.339l-43.681 80.445 142.62-34.59-75.681-51.5a16.137 16.137 0 00-23.258 5.645z'
          fill='#f6a8b3'
        />
        <path
          data-name='Trazado 121'
          d='M40.503 188.965l44.525-82 .69.375-.69-.375a16.952 16.952 0 0110.884-8.371 16.959 16.959 0 0113.508 2.454l77.144 52.493zm57.464-89.153a15.326 15.326 0 00-1.686.309 15.377 15.377 0 00-9.872 7.594l-42.837 78.888 139.181-33.755-74.218-50.5a15.378 15.378 0 00-10.567-2.536z'
          fill='#243762'
        />
      </g>
      <g data-name='Grupo 51'>
        <path
          data-name='Trazado 122'
          d='M5.482 32.791L115.339 6.148c7.2-1.746 14.267 1.905 15.783 8.155l15.2 62.669-27.39 32.4-40.369 9.79-43.649-20.187-14.725-60.716z'
          fill='#fff'
        />
        <path
          data-name='Trazado 123'
          d='M78.481 119.992L34.239 99.53 19.523 38.852 7.801 31.418 115.153 5.382a15.215 15.215 0 0110.57 1.145 11.63 11.63 0 016.163 7.59l15.292 63.055-27.814 32.9zM35.586 98.423l43.056 19.913 39.853-9.667 26.968-31.9-15.1-62.281a10.076 10.076 0 00-5.357-6.562 13.639 13.639 0 00-9.478-1.014L8.165 32.95l12.692 4.721z'
          fill='#243762'
        />
      </g>
      <path
        data-name='Trazado 124'
        d='M84.914 100.982l-42.877 86.807 142.62-34.589-76.485-57.859a16.137 16.137 0 00-23.258 5.641z'
        fill='#243762'
        opacity={0.29}
      />
      <g data-name='Grupo 52'>
        <path
          data-name='Trazado 125'
          d='M85.719 107.339l-43.681 80.445 142.62-34.59-75.681-51.5a16.137 16.137 0 00-23.258 5.645z'
          fill='#c386ef'
        />
        <path
          data-name='Trazado 126'
          d='M40.503 188.965l44.525-82 .69.375-.69-.375a16.952 16.952 0 0110.884-8.371 16.959 16.959 0 0113.508 2.454l77.144 52.493zm57.464-89.153a15.326 15.326 0 00-1.686.309 15.377 15.377 0 00-9.872 7.594l-42.837 78.888 139.181-33.755-74.218-50.5a15.378 15.378 0 00-10.567-2.536z'
          fill='#243762'
        />
      </g>
      <path
        data-name='Trazado 127'
        d='M92.918 123.013l-32.08 59.078 104.74-25.4-55.579-37.818a11.851 11.851 0 00-17.081 4.14z'
        fill='#ebf0f6'
        opacity={0.35}
      />
      <g data-name='Grupo 53'>
        <path
          data-name='L\xEDnea 1'
          fill='#f6a8b3'
          d='M3.34 84.528l75.222 34.636'
        />
        <path
          data-name='Rect\xE1ngulo 35'
          fill='#243762'
          d='M3.011 85.242l.658-1.429L78.89 118.45l-.658 1.428z'
        />
      </g>
      <g data-name='Grupo 54'>
        <path
          data-name='L\xEDnea 2'
          fill='#f6a8b3'
          d='M171.663 43.705l-50.997 65.248'
        />
        <path
          data-name='Rect\xE1ngulo 36'
          fill='#243762'
          d='M120.057 108.477l50.978-65.263 1.237.967-50.978 65.262z'
        />
      </g>
      <g data-name='Grupo 55'>
        <path
          data-name='Trazado 128'
          d='M26.862 66.257L7.69 70.907 1.056 43.553a9.416 9.416 0 016.931-11.37l.871-.211a9.416 9.416 0 0111.37 6.931z'
          fill='#ebf0f6'
        />
        <path
          data-name='Trazado 129'
          d='M7.11 71.855L.291 43.736a10.137 10.137 0 011.2-7.715 10.136 10.136 0 016.3-4.6l.873-.211a10.159 10.159 0 017.714 1.2 10.137 10.137 0 014.6 6.306l6.818 28.115zm2.9-39.3a9.011 9.011 0 00-.969.179l-.873.211a8.57 8.57 0 00-5.334 3.894 8.571 8.571 0 00-1.018 6.526l6.448 26.591 17.646-4.28-6.449-26.591a8.573 8.573 0 00-3.895-5.334 8.561 8.561 0 00-5.553-1.194z'
          fill='#243762'
        />
      </g>
      <path
        data-name='Trazado 130'
        d='M38.242 50.141a.787.787 0 01-.862-.595.786.786 0 01.578-.948l79.6-19.3a.781.781 0 01.949.579.786.786 0 01-.578.948l-79.6 19.3a.88.88 0 01-.087.016z'
        fill='#243762'
      />
      <path
        data-name='Trazado 131'
        d='M44.804 77.199a.786.786 0 01-.862-.595.786.786 0 01.578-.948l79.6-19.3a.785.785 0 01.949.579.786.786 0 01-.578.948l-79.6 19.3a.872.872 0 01-.087.016z'
        fill='#243762'
      />
      <path
        data-name='Trazado 132'
        d='M41.485 63.514a.786.786 0 01-.861-.595.786.786 0 01.578-.949l34.4-8.342a.792.792 0 01.949.58.786.786 0 01-.578.948l-34.4 8.343c-.03.006-.06.012-.088.015z'
        fill='#243762'
      />
      <path
        data-name='Trazado 133'
        d='M84.886 52.986a.786.786 0 01-.862-.595.786.786 0 01.578-.948l36.2-8.777a.79.79 0 01.949.579.786.786 0 01-.578.948L84.978 52.97a.714.714 0 01-.092.016z'
        fill='#243762'
      />
      <path
        data-name='Trazado 134'
        d='M48.525 92.544a.786.786 0 01-.861-.594.786.786 0 01.578-.948l34.4-8.342a.793.793 0 01.949.579.786.786 0 01-.579.948l-34.4 8.342a.8.8 0 01-.087.015z'
        fill='#243762'
      />
    </g>
  </svg>
)
