import { forwardRef, useImperativeHandle, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useInputValue } from 'hooks/useInputValue'
import { useSelectValue } from 'hooks/useSelectValue'
import { Select } from 'forms/select'
import { Input } from 'forms/input'
import { DeleteIcon } from 'icons/deleteIcon'
import { ContainerFormLicence, ButtonAndIconAdd } from '../styles'
import { Colors } from 'styles/variables'

export const LicenceAgent = forwardRef(({ number, numberRef, items = 0, states = [], values = {}, onRemove = () => {}, onChange = () => {} }, ref) => {
  // hook
  const { t } = useTranslation()
  // Input hook
  const state = useSelectValue(t('Estado'), '', 'required')
  const license = useInputValue(t('Licencia'), '', 'required')

  useEffect(() => {
    state.onChange(values?.state_code)
    license.onChange(values?.license)
  }, [values])

  useEffect(() => {
    onChange({
      numberRef,
      state_code: state.value,
      license: license.value,
      id: values.id || null
    })
  }, [state.value, license.value])

  useImperativeHandle(
    ref,
    () => ({
      validate: () => {
        const stateResult = state.validate()
        const licenseResult = license.validate()
        if (stateResult || licenseResult) {
          return true
        }
        return false
      },
      values: () => {
        return {
          state: state.value,
          license: license.value,
          id: values.id || null
        }
      }
    }),
    [state, license, values]
  )

  return (
    <ContainerFormLicence>
      <span className='number'>{number + 1} - </span>
      <Select options={states} optionDefault={t('Seleccione un estado')} {...state} width='30%' />
      <Input placeholder='000000000000' {...license} width='60%' />
      {
        items > 1
          ? (
            <ButtonAndIconAdd
              color={Colors.purple}
              onClick={onRemove}
              type='button'
            >
              <DeleteIcon color={Colors.white} size={15} />
            </ButtonAndIconAdd>
            )
          : null
      }
    </ContainerFormLicence>
  )
})
