import styled, { css } from 'styled-components'
import { Colors, Device, FontFamily, FontWeight } from './variables'

import arrowDown from 'icons/arrow-down.png'

// Reset Button

export const resetButton = css`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`
/**
 * Flex Align
 */
export const FlexCenter = css`
  align-items: center;
  display: flex;
  justify-content: center;
`

// Font
export const ConfigureFont = (size, fw, style = 'normal') => `
  font-family: ${FontFamily};
  font-size: ${size || '14px'} !important;
  font-style ${style || 'normal'};
  font-weight: ${fw || FontWeight.fw400} !important;
`

// Scrooll
export const ScrollStyle = (width = 0, height = 0) => `
  ::-webkit-scrollbar {
    width: ${width};
    height: ${height};
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ededed;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #747474;
  }
`

/**
 * Tables
 */

const mediaBlock = css`
  @media (max-width: ${Device.tableM}px) {
    display: block;
  }
`

export const Table = styled.table`
  position: relative;
  border-collapse: collapse;
  width: 100%;

  ${mediaBlock};
`

export const Thead = styled.thead`
  display: table;
  width: calc(100% - 17px);
  tr {
    border-bottom: 1px solid ${Colors.borderGrey};
  }

  ${mediaBlock};

  @media (max-width: ${Device.tableM}px) {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
`
export const Tbody = styled.tbody`
  ${ScrollStyle('8px')}
  display: block;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;

  ${mediaBlock};
`
export const Tfoot = styled.tfoot`
  ${mediaBlock};
`

export const Tr = styled.tr`
  display: table;
  width: 100%;

  ${mediaBlock};

  @media (max-width: ${Device.tableM}px) {
    border-bottom: 1px solid ${Colors.borderGrey};
    border-width: 90%;
  }
`

export const Th = styled.th`
  color: ${Colors.textHeadTable};
  font-size: 17px;
  font-weight: 400;
  padding: 16px 2px;
  position: sticky;
  top: 0;
  word-break: break-all;
  width: 10%;

  ${mediaBlock};
`

export const Td = styled.td`
  border-collapse: collapse;
  color: ${Colors.textCellTable};
  font-family: 'Metropolis';
  font-size: 16px;
  font-weight: 400;
  padding: 16px 2px;
  text-align: center;
  word-break: break-all;
  width: 10%;

  ${mediaBlock};

  @media (max-width: ${Device.tableM}px) {
    border: none;
    position: relative;
    padding-left: 50%;
    width: auto !important;

    &:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`

// Select card

export const SimpleSelect = styled.select`
  background-color: ${Colors.White};
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-position-x: ${({ width }) => (!width ? 'calc(100% - 8px)' : 'calc(' + width + ' - 8px)')};
  background-position-y: 50%;
  background-size: 14px;
  border: none;
  height: auto;
  outline: none;
  padding: 8px 0;
  padding-right: 24px;
  width: ${({ width }) => (!width ? '100%' : width)};
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 1.3rem;
  font-weight: bold;
  word-wrap: break-word;
`
