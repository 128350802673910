import styled, { css } from 'styled-components'
import Wallpaper from 'assets/images/background.jpg'
import { Colors, Device, FontFamily, FontWeight } from 'assets/styles/variables'

export const ContainerBanner = styled.div`
  align-items: center;
  background-image: url(${Wallpaper});
  background-size: cover;
  border-radius: 12px;
  display: flex;
  height: 20rem;
  justify-content: center;
  overflow: hidden;
  padding: 0 80px;
  position: relative;
  width: ${({ width }) => width || '100%'};

  .agents-image {
    height: 17rem;
    left: 4rem;
    position: absolute;
    bottom: 0;
    width: 340px;
  }

  @media (max-width: ${Device.tableS}px) {
    height: 8rem;
    padding: 0 16px;

    .agents-image {
      height: 6rem;
      left: 1rem;
      width: auto;
    }
  }

  @media (min-width: ${Device.tableS + 1}px) and (max-width: ${Device.tableM}px) {
    height: 10rem;
    padding: 0 16px;

    .agents-image {
      height: 8rem;
      left: 2rem;
      width: auto;
    }
  }

  @media (min-width: ${Device.tableM + 1}px) and (max-width: ${Device.tableL}px) {
    height: 10rem;
    padding: 0 16px;

    .agents-image {
      height: 8rem;
      left: 2rem;
      width: auto;
    }
  }

  @media (min-width: ${Device.tableL}px) and (max-width: ${Device.desktopS}px) {
    height: 15rem;

    .agents-image {
      height: 12rem;
      left: 2rem;
      width: auto;
    }
  }
`

export const ContainerText = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ isDisabled }) => isDisabled
    ? css`
      text-align: center;

      h2 {
        max-width: 800px;
      }
    `
    : css`
      transform: translateX(8rem);
    `}
  

  @media (max-width: ${Device.tableS}px) {
    transform: translateX(3rem);
  }

  @media (min-width: ${Device.tableM + 1}px) and (max-width: ${Device.tableL}px) {
    transform: translateX(2rem);
  }

  @media (min-width: ${Device.tableS + 1}px) and (max-width: ${Device.tableM}px) {
    transform: translateX(3rem);
  }
`

export const Title = styled.h1`
  color: ${Colors.bannerTitle};
  font-family: ${FontFamily};
  font-size: 72px;
  font-weight: ${FontWeight.fw900};
  text-transform: uppercase;

  @media (max-width: ${Device.tableS}px) {
    font-size: 24px;
  }

  @media (min-width: ${Device.tableS + 1}px) and (max-width: ${Device.tableM}px) {
    font-size: 28px;
  }

  @media (min-width: ${Device.tableM + 1}px) and (max-width: ${Device.tableL}px) {
    font-size: 32px;
  }

  @media (min-width: ${Device.tableL}px) and (max-width: ${Device.desktop}px) {
    font-size: 48px;
  }

  @media (min-width: ${Device.desktop}px) and (max-width: 1536px) {
    font-size: 50px;
  }
`

export const SubTitle = styled.h2`
  color: ${Colors.white};
  font-family: ${FontFamily};
  font-size: 28px;
  font-weight: ${FontWeight.fw400};
  transform: translateY(8px);

  @media (max-width: ${Device.tableS}px) {
    font-size: 16px;
  }

  @media (min-width: ${Device.tableS + 1}px) and (max-width: ${Device.tableM}px) {
    font-size: 18px;
  }

  @media (min-width: ${Device.tableM + 1}px) and (max-width: ${Device.tableL}px) {
    font-size: 18px;
  }

  @media (min-width: ${Device.tableL}px) and (max-width: ${Device.desktop}px) {
    font-size: 20px;
  }

  @media (min-width: ${Device.desktop}px) and (max-width: 1536px) {
    font-size: 22px;
  }
`
