import { useContext, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { SimpleCard } from 'common/cards/simple'
import { BadgeRequest, GridClient, RowButton, RowImage, RowText } from '../styles'
import { Colors } from 'assets/styles/variables'
import { RequestsImage } from 'assets/images/requests'
import { Button } from 'common/buttons'
import { useRequest } from 'hooks/useRequest'
import { Context } from 'Context'
import ModalRequests from './modalRequests'
import ModalSearchAgencies from './modalSearchAgencies'

export const CardRequests = () => {
  const { userData } = useContext(Context)

  // References
  const isMountedRef = useRef()
  const modalRequest = useRef()
  const modalSeacrh = useRef()

  // States
  const [requests, setRequests] = useState([])

  const { t } = useTranslation()

  const [getRequests, { loading: loadingRequests }] = useRequest(`linkeds/requests-agent/${userData?.user?.agent.id}`, 'GET')

  const handleGetRequest = () => {
    getRequests({ body: {} }).then(res => {
      setRequests(res)
    }).catch(err => {
      console.log('>>> err', err)
    })
  }

  const handleViewRequest = () => {
    modalRequest.current.handleOpen()
  }

  const handleSearch = () => {
    modalSeacrh.current.handleOpen()
  }

  useEffect(() => {
    handleGetRequest()

    return () => { isMountedRef.current = false }
  }, [])

  return (
    <>
      <SimpleCard>
        <GridClient>
          <RowImage>
            <RequestsImage width='140px' />
            {
            requests.length > 0 &&
              <BadgeRequest>
                {requests.length}
              </BadgeRequest>
          }
          </RowImage>
          <RowText>
            <p>{t('Solicitudes de Agencia')}</p>
          </RowText>
          <RowButton className='multiple'>
            <Button
              type='button'
              height='40px'
              bgColor={Colors.purple}
              color={Colors.white}
              disabled={loadingRequests}
              onClick={handleSearch}
            >
              {t('Buscar')}
            </Button>
            <Button
              type='button'
              height='40px'
              bgColor={Colors.buttonLightBlueLabel}
              color={Colors.white}
              disabled={loadingRequests || requests.length === 0}
              onClick={handleViewRequest}
            >
              {t('Ver')}
            </Button>
          </RowButton>
        </GridClient>
      </SimpleCard>
      <ModalRequests ref={modalRequest} requests={requests} handleGetRequest={handleGetRequest} />
      <ModalSearchAgencies ref={modalSeacrh} />
    </>
  )
}
