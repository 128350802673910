import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from '@reach/router'

import get from 'lodash/get'

import { SearchBar } from './searchBar'
import { Logout } from './logout'
import { Home } from 'icons/home'
import { Agents } from 'icons/agents'
import { Reports } from 'icons/reports'
import { Terms } from 'icons/terms'

import { Sidebar as Container, LogoContainer, Logo, Items, Item } from '../styles'
import { Context, AGENCY_DISABLED_STATUS } from 'Context'
import { ClientsIcon } from 'assets/icons/clientsIcon'
import { ProfileIcon } from 'assets/icons/profileIcon'
// import { PayrollIcon } from 'assets/icons/payrollIcon'
import { TemplateIcon } from 'assets/icons/templateIcon'
import { ShieldIcon } from 'assets/icons/shieldLikeIcon'
import { CommissionIcon } from 'assets/icons/commissionIcon'

export const Sidebar = forwardRef(({ isDesktop = false, open }, ref) => {
  // Valores y funciones de contexto
  const { userData } = useContext(Context)
  // References
  const aside = useRef(ref)
  const sizeIcon = isDesktop ? 40 : 30
  // States
  const [isOpen, setIsOpen] = useState(false)

  const { t } = useTranslation()

  const isActive = ({ isCurrent }) => {
    return { className: isCurrent ? 'active' : null }
  }

  const handleClick = (e) => {
    if (aside.current.contains(e.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick)
      aside.current.setAttribute('state', 'open')
    } else {
      aside.current.removeAttribute('state')
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [isOpen])

  useImperativeHandle(ref, () => ({
    toggleAside: () => setIsOpen(prev => !prev),
    isOpen: () => isOpen
  }))

  const verifyIsActive = () => {
    return !AGENCY_DISABLED_STATUS.includes(get(userData, 'user.agency.status', '0'))
  }

  return (
    <Container ref={aside} open={isOpen}>
      {
        !isDesktop && (
          <>
            <LogoContainer height='90px'>
              <Logo width='80px' height='80px' />
            </LogoContainer>
            <SearchBar />
          </>
        )
      }

      {
        get(userData, 'user.level', '') === '1' &&
          <Items>
            <Item>
              <Link to='/admin/dashboard' getProps={isActive}>
                <span>
                  <Home size={sizeIcon} />
                </span>
                {t('Inicio')}
              </Link>
            </Item>
            <Item>
              <Link to='/admin/companies' getProps={isActive}>
                <span>
                  <ShieldIcon width={sizeIcon} height={sizeIcon} />
                </span>
                {t('Compañia')}
              </Link>
            </Item>
            <Item>
              <Link to='/admin/agencies' getProps={isActive}>
                <span>
                  <Reports size={sizeIcon} />
                </span>
                {t('Agencias')}
              </Link>
            </Item>
            <Item>
              <Link to='/admin/terms' getProps={isActive}>
                <span>
                  <Terms width={sizeIcon} height={sizeIcon} />
                </span>
                {t('Términos y Condiciones')}
              </Link>
            </Item>
          </Items>
      }

      {
        get(userData, 'user.level', '') === '2' &&
          <Items>
            <Item>
              <Link to='/agency/dashboard' getProps={isActive}>
                <span>
                  <Home size={sizeIcon} />
                </span>
                {t('Inicio')}
              </Link>
            </Item>
            {
              verifyIsActive() && (
                <>
                  <Item>
                    <Link to='/agency/agents' getProps={isActive}>
                      <span>
                        <Agents width={sizeIcon} height={sizeIcon} />
                      </span>
                      {t('Agentes')}
                    </Link>
                  </Item>
                  <Item>
                    <Link to='/agency/reports' getProps={isActive}>
                      <span>
                        <Reports size={sizeIcon} />
                      </span>
                      {t('Reportes')}
                    </Link>
                  </Item>
                  <Item>
                    <Link to='/agency/commissions' getProps={isActive}>
                      <span>
                        <CommissionIcon size={sizeIcon} />
                      </span>
                      {t('Configuración de comisiones')}
                    </Link>
                  </Item>
                  {/* <Item>
                    <Link to='/terms' getProps={isActive}>
                      <span>
                        <Terms width={sizeIcon} height={sizeIcon} />
                      </span>
                      {t('Términos y Condiciones')}
                    </Link>
                  </Item> */}
                </>
              )
            }
          </Items>
      }

      {
        get(userData, 'user.level', '') === '3' &&
          <Items>
            <Item>
              <Link to='/agent/dashboard' getProps={isActive}>
                <span>
                  <Home size={sizeIcon} />
                </span>
                {t('Inicio')}
              </Link>
            </Item>
            <Item>
              <Link to='/agent/clients' getProps={isActive}>
                <span>
                  <ClientsIcon size={sizeIcon} color='#b2b2b2' />
                </span>
                {t('Clientes')}
              </Link>
            </Item>
            <Item>
              <Link to='/agent/templates-by-clients' getProps={isActive}>
                <span>
                  <TemplateIcon size={sizeIcon} />
                </span>
                {t('Planillas recibidas')}
              </Link>
            </Item>
            <Item>
              <Link to='/agent/templates' getProps={isActive}>
                <span>
                  <TemplateIcon size={sizeIcon} />
                </span>
                {t('Planillas')}
              </Link>
            </Item>
            <Item>
              <Link to='/agent/reports' getProps={isActive}>
                <span>
                  <Reports size={sizeIcon} />
                </span>
                {t('Reportes')}
              </Link>
            </Item>
          </Items>
      }

      {
        get(userData, 'user.level', '') === '4' &&
          <Items>
            <Item>
              <Link to='/client/profile' getProps={isActive}>
                <span>
                  <ProfileIcon size={sizeIcon} />
                </span>
                {t('Perfil')}
              </Link>
            </Item>
            <Item>
              <Link to='/client/template' getProps={isActive}>
                <span>
                  <TemplateIcon size={sizeIcon} />
                </span>
                {t('Planillas')}
              </Link>
            </Item>
          </Items>
      }

      {
        !isDesktop && (
          <Logout isSidebar />
        )
      }
    </Container>
  )
})
