import { keyframes, css } from 'styled-components'

const fadeInWithBlurKeyframes = keyframes`
  from {
    filter: blur(2px);
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5);
  }

  to {
    filter: blur(0);
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`

const fadeOutKeyframes = keyframes`
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  99% {
    opacity: 0;
    transform: translate3d(100px, -100px, -200px) scale3d(0.1, 0.1, 0.1);
  }
  100% {
    visibility: hidden;
  }
`

export const fadeInBlur = ({ time = '1s', type = 'ease' }) =>
  css`
    animation: ${time} ${fadeInWithBlurKeyframes} ${type};
  `

export const fadeOut = ({ time = '1s', type = 'ease' }) =>
  css`
    animation: ${time} ${fadeOutKeyframes} ${type};
  `
