import { useTranslation } from 'react-i18next'

import { ClientsIcon } from 'assets/icons/clientsIcon'
import { Label, Quantity } from 'common/cards/styles'
import { ContainerClientes, RowInfo, RowLabel } from '../styles'

export const NumberClients = ({ clients = 0 }) => {
  const { t } = useTranslation()
  return (
    <ContainerClientes>
      <RowInfo>
        <ClientsIcon />
        <Quantity>{clients}</Quantity>
      </RowInfo>
      <RowLabel>
        <Label>{t('Clientes registrados')}</Label>
      </RowLabel>
    </ContainerClientes>
  )
}
