import SelectSearchComponent, { fuzzySearch } from 'react-select-search'

import { Field, ErrorMessage, Label } from 'formStyle'
import { ErrorIcon } from 'icons/errorIcon'

export const SelectSearch = ({ label = '', name = 'common', value = '', options = [], onChange = () => {}, optionDefault = '', error = '', width = '100%', placeholder = '', isRequired, multiple = false, disabled = false }) => {
  return (
    <Field width={width} className='field-form'>
      {label && (
        <Label required={isRequired}>
          {isRequired && <span className='input-required' />}
          {label}
        </Label>
      )}
      <SelectSearchComponent
        options={options}
        value={value}
        placeholder={placeholder}
        search
        filterOptions={fuzzySearch}
        onChange={onChange}
        multiple={multiple}
        closeOnSelect={!multiple}
        printOptions='on-focus'
        disabled={disabled}
      />
      {error && <ErrorIcon size={20} className='error-icon select-error-icon' />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  )
}
