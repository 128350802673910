import { Header as Container, LogoContainer, Logo, Menu, SearchbarInNav } from '../styles'
import { SearchBar } from './searchBar'
import { Logout } from './logout'
import { MenuIcon } from 'assets/icons/menuIcon'
import { ClientMenu } from './clientMenu'

const DesktopNav = ({ hideSearchBar }) => {
  return (
    <nav>
      <LogoContainer align='flex-start'>
        {/* <Logo /> */}
      </LogoContainer>
      <SearchbarInNav>
        {
          hideSearchBar && (
            <SearchBar />
          )
        }
      </SearchbarInNav>
      <Logout />
    </nav>
  )
}

const MobileNav = ({ onOpenMenu, isClient = false }) => {
  return (
    <nav>
      {
        !isClient && (
          <Menu onClick={onOpenMenu}>
            <MenuIcon size={30} />
          </Menu>
        )
      }
      <LogoContainer align={isClient ? 'flex-start' : 'center'}>
        {/* <Logo /> */}
      </LogoContainer>
      <Logout />
    </nav>
  )
}

export const Header = ({ onOpenMenu, isDesktop = false, hideSearchBar = false, isClient = false }) => {
  return (
    <Container isClient={isClient}>
      {
        isDesktop
          ? <DesktopNav hideSearchBar={hideSearchBar} />
          : <MobileNav onOpenMenu={onOpenMenu} isClient={isClient} />
      }

      {
        !isDesktop && isClient && (
          <ClientMenu isMobile={!isDesktop} />
        )
      }
    </Container>
  )
}
