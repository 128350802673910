export const UnlinkIcon = ({ size = 20, color = '#075592', ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 19.688 19.688'
    height={size}
    width={size}
    {...props}
  >
    <g data-name='Grupo 109'>
      <g data-name='Grupo 87'>
        <g data-name='Grupo 86' fill={color}>
          <path
            data-name='Trazado 144'
            d='M4.437 13.144a.378.378 0 00-.272-.65H.379a.379.379 0 100 .757h3.786a.368.368 0 00.272-.107z'
          />
          <path
            data-name='Trazado 145'
            d='M12.872 4.543a.365.365 0 00.379-.379V.379a.379.379 0 10-.757 0v3.786a.364.364 0 00.378.379z'
          />
          <path
            data-name='Trazado 146'
            d='M4.922 14.388a.4.4 0 00-.272.106l-3.029 3.029a.4.4 0 000 .544.426.426 0 00.272.107.4.4 0 00.272-.107l3.029-3.029a.378.378 0 00-.272-.65z'
          />
          <path
            data-name='Trazado 147'
            d='M14.765 5.3a.426.426 0 00.272-.106l3.029-3.029a.385.385 0 00-.544-.544L14.493 4.65a.4.4 0 000 .544.426.426 0 00.272.106z'
          />
          <path
            data-name='Trazado 148'
            d='M14.529 7.998a3.438 3.438 0 00-.663-.5l-.213 2.828 3.242 3.253a1.1 1.1 0 01.331.8 1.082 1.082 0 01-.331.793l-1.74 1.728a1.07 1.07 0 01-.8.314 1.111 1.111 0 01-.8-.325l-3.23-3.242-2.828.213a3.462 3.462 0 00.5.663l3.952 3.963a3.414 3.414 0 004.815.024l1.74-1.727a3.229 3.229 0 001.006-2.4 3.338 3.338 0 00-.994-2.414z'
          />
          <path
            data-name='Trazado 149'
            d='M19.582 6.543a.369.369 0 00-.272-.106h-3.786a.379.379 0 100 .757h3.786a.385.385 0 00.272-.651z'
          />
          <path
            data-name='Trazado 150'
            d='M4.531 2.78a1.142 1.142 0 01.8-.319 1.1 1.1 0 01.8.331l3.23 3.242 2.828-.213a3.438 3.438 0 00-.5-.663L7.737 1.194a3.413 3.413 0 00-4.815-.024L1.183 2.897a3.229 3.229 0 00-1.006 2.4 3.337 3.337 0 00.994 2.414l3.975 3.976a3.448 3.448 0 00.662.5l.213-2.84-3.242-3.242a1.1 1.1 0 01-.331-.8 1.081 1.081 0 01.331-.793z'
          />
          <path
            data-name='Trazado 151'
            d='M6.816 15.144a.364.364 0 00-.379.379v3.786a.379.379 0 10.757 0v-3.786a.364.364 0 00-.379-.379z'
          />
        </g>
      </g>
    </g>
  </svg>
)
