import styled from 'styled-components'

import { FlexCenter } from 'assets/styles/general'
import { Colors } from 'assets/styles/variables'

const dimension = {
  mini: '34px',
  small: '40px',
  medium: '80px',
  big: '186px'
}

export const AvatarRound = styled.div`
  ${FlexCenter};
  background-color: ${Colors.white};
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  height: ${({ size = 'medium' }) => dimension[size]};
  width: ${({ size = 'medium' }) => dimension[size]};
  overflow: hidden;
  position: relative;

  svg {
    width: 70%;
    height: 70%;
  }
`

export const Thumbnail = styled.img`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
`
