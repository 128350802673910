import styled from 'styled-components'

import { Colors } from 'assets/styles/variables'

export const ContainerDots = styled.div`
  margin: auto auto;
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  position: relative;
  text-align: center;
  animation: sk-rotate 2.0s infinite linear;

  .dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: ${({ color }) => color || Colors.purple};
    border-radius: 100%;

    animation: sk-bounce 2.0s infinite ease-in-out;
  }

  .dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1.0s;
  }

  @keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
`
