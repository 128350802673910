import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Modal } from 'common/modal'
import { ButtonContainer, CancelButton } from 'common/modal/styles'
import { Snackbar } from 'common/snackbar'
import { HeaderRequests, ListRequests } from '../styles/requests'
import { RequestItem } from './requestItem'
import { useRequest } from 'hooks/useRequest'

const ModalRequests = forwardRef(({ requests = [], handleGetRequest = () => {} }, ref) => {
  const { t } = useTranslation()
  // References
  const snackbar = useRef()
  // States
  const [isOpen, setIsOpen] = useState(false)

  const [answerRequest, { loading: loadingAnswer }] = useRequest('linkeds/answer-request', 'POST')

  useImperativeHandle(ref, () => ({
    handleOpen: () => handleOnOpen()
  }))

  const handleOnOpen = () => {
    setIsOpen(true)
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  const handleAnswerRequest = (id, status) => {
    answerRequest({
      body: {
        status,
        id
      }
    })
      .then(res => {
        snackbar.current.show({
          message: t(res?.message),
          timeout: 5000
        })
        handleOnClose()
        handleGetRequest()
      }).catch(err => {
        snackbar.current.show({
          message: t('Disculpa ocurrió un error, intentalo mas tarde'),
          timeout: 5000
        })
        console.log('>>> err', err)
      })
  }

  return (
    <>
      <Modal
        onClose={handleOnClose}
        open={isOpen}
        ref={ref}
        padding='2rem'
        width='50rem'
        height='max-content'
      >
        <HeaderRequests>
          <h2>{t('Solicitudes')}</h2>
        </HeaderRequests>
        <ListRequests>
          {
          requests.map((request) => (
            <RequestItem key={request?.id} {...request} handleAction={handleAnswerRequest} />
          ))
        }
        </ListRequests>
        <ButtonContainer>
          <CancelButton
            onClick={handleOnClose}
            disabled={loadingAnswer}
          >
            {t('Cerrar')}
          </CancelButton>
        </ButtonContainer>
      </Modal>
      <Snackbar ref={snackbar} />
    </>
  )
})

export default ModalRequests
