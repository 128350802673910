export const Colors = {
  white: '#fff',
  black: '#333333',
  purple: '#7e35dc',
  blueBlack: '#01182a',
  blueDark: '#003257',
  blueMedium: '#075593',
  blueLightMedium: '#b9d0e2',
  blueLight: '#dbfffd',
  textGrey: '#888888',
  textHeadTable: '#777777',
  textCellTable: '#393939',
  borderGrey: '#B2B2B2',
  blueGradient: 'rgba(114,175,255,1)',
  buttonLightBlue: '#B4D8F5',
  buttonLightBlueLabel: '#399AE5',
  buttonMediumBlue: '#075592',
  bannerTitle: '#D29CF5',
  red: '#f00',
  danger: '#FF0000'
}

export const FontFamily = '\'Metropolis\''

export const FontWeight = {
  fw100: 100,
  fw200: 200,
  fw300: 300,
  fw400: 400,
  fw500: 500,
  fw600: 600,
  fw700: 700,
  fw800: 800,
  fw900: 900
}

export const Device = {
  mobileS: 320,
  mobileM: 411,
  tableS: 600,
  tableM: 768,
  tableL: 1024,
  laptop: 1198,
  desktopS: 1280,
  desktop: 1366
}

export const MinQueriesSize = (size) => {
  return `(min-width: ${size}px)`
}

export const MaxQueriesSize = (size) => {
  return `(max-width: ${size - 1}px)`
}

export const MinMaxQueriesSize = (min, max) => `(min-width: ${min}px) and (max-width: ${max - 1}px)`
