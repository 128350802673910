import { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { Layout } from 'common/layout'
import { Banner } from 'common/banner'
import { EmailForm } from 'common/email'
import { CompleteForm } from './ui/completeForm'
import { FormAgent } from './ui/form'
import { CardAgency } from './ui/cardAgency'
import { Modal } from 'common/modal'
import { Context } from 'Context'
import { GridTools } from './styles'
import { CardAddClient } from './ui/cardAddClient'
import { CardSendEmail } from './ui/cardSendEmail'
import { useGetAddressedClients } from 'hooks/useGetAddressedClients'
import { CardRequests } from './ui/cardRequests'

export const AgentDashboard = () => {
  const { userData } = useContext(Context)
  const { t } = useTranslation()
  const { addressee } = useGetAddressedClients()

  // state
  const [editProfile, setEditProfile] = useState(false)
  const [openEmail, setOpenEmail] = useState(false)

  const status = userData?.user?.agent?.status

  const toggleAgent = () => {
    setEditProfile(!editProfile)
  }

  const handleOpenEmail = () => {
    setOpenEmail(true)
  }

  const handleClose = () => {
    setOpenEmail(false)
  }

  console.log(addressee)

  return (
    <Layout
      hideSidebar={status === '2'}
    >
      <>
        {
          status === '2'
            ? <CompleteForm />
            : (
              <>
                <Banner title={t('App Insurance')} subtitle={t('Mantén el control de tus seguros')} />
                <GridTools>
                  <CardAddClient />
                  <CardSendEmail onClick={handleOpenEmail} />
                  <CardRequests />
                </GridTools>
                <Modal
                  open={editProfile}
                  width='50%'
                  onClose={toggleAgent}
                >
                  <FormAgent onClose={toggleAgent} />
                </Modal>
              </>
              )
        }

        <EmailForm addressee={addressee} open={openEmail} onClose={handleClose} />
      </>
      <CardAgency
        agency={userData.user?.agent?.agency}
        agent={userData.user?.agent}
        handleEditProfile={toggleAgent}
        showEditProfileButton={status !== '2'}
      />
    </Layout>
  )
}
