import { Suspense } from 'react'

import { GlobalStyle } from 'styles/GlobalStyles'
import Context from 'Context'
import { ProtectedRoutes } from './protected.routes'
import 'react-datepicker/dist/react-datepicker.css'

const App = () => {
  return (
    <Suspense fallback={<div>cargando</div>}>
      <GlobalStyle />
      <Context.Provider>
        <ProtectedRoutes path='/auth' />
      </Context.Provider>
    </Suspense>
  )
}

export default App
